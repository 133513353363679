import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Tabs } from 'src/modules/ui'
import PublicPageTitle from './PublicPageTitle'
import PublicResearch from './PublicResearch'
import PublicDocuments from './PublicDocuments'
import PublicPhotos from './PublicPhotos'
import PublicFeed from './PublicFeed'
import PublicPageList from './PublicPageList'
import PublicTree from './PublicTree'
import PublicMaps from './PublicMaps'
import {
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_FACTS,
} from 'src/modules/app/links'
import PublicPinnedArticle from './PublicPinnedArticle'
import { fetchPublicPageCounts } from './pageSlice'
import { useActionDispatcher } from '../../app'
import { PublicContext } from '../contexts'
import { titleCase } from 'title-case'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import {
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  PATH_SEGMENT_FANS,
  PATH_SEGMENT_PEOPLE,
} from '../../app/links'
// import { TEST_FACTS } from '../../page/Facts'
import PublicFacts from './PublicFacts'
import PublicPageIndividualList from './PublicPageIndividualList'
import { useViewConfigQueryParams } from '../../common/viewConfigUtils'

export const routeComponentMap = {
  [PATH_SEGMENT_FACTS]: PublicFacts,
  research: PublicResearch,
  documents: PublicDocuments,
  [PATH_SEGMENT_MEDIA]: PublicPhotos,
  feed: PublicFeed,
  [PATH_SEGMENT_EVENT]: PublicPageList,
  [PATH_SEGMENT_ARTEFACT]: PublicPageList,
  [PATH_SEGMENT_LOCATION]: PublicPageList,
  [PATH_SEGMENT_PEOPLE]: PublicPageIndividualList,
  [PATH_SEGMENT_FANS]: PublicPageIndividualList,
  tree: PublicTree,
  welcome: PublicPinnedArticle,
  map: PublicMaps,
}

const PublicPage = ({
  routes,
  linkedPageId,
  pageType,
  publicTreeRoute,
  ...props
}) => {
  const viewConfigQueryParams = useViewConfigQueryParams()
  const [pageRoutes, setPageRoutes] = useState(routes)
  const { treeSlug } = useContext(PublicContext)
  const history = useHistory()
  const location = useLocation()
  const { url } = useRouteMatch()

  const dispatchFetchPublicPageCounts = useActionDispatcher(
    fetchPublicPageCounts
  )

  const handleDisabledRoutes = useCallback(
    counts => {
      const mapsCount = counts.maps || 0
      const articlesCount = counts?.articles || 0
      const factsCount = counts?.facts || 0
      const documentsCount = counts?.documents || 0
      const locationsCount = counts?.locations || 0
      const eventsCount = counts?.events || 0
      const artefactsCount = counts?.artefacts || 0
      const mediaCount = counts?.media || 0
      const individualsCount = counts?.individuals || 0

      let publicPageRoutes = routes.map(route => {
        if (route.navLabel === 'Research' && articlesCount > 0) {
          return { ...route, disabled: false, makeDefault: true }
        } else if (route.navLabel === 'Maps' && mapsCount > 0) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase(PATH_SEGMENT_DOCUMENT) &&
          documentsCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase(PATH_SEGMENT_FACTS) &&
          factsCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase(PATH_SEGMENT_MEDIA) &&
          mediaCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase(PATH_SEGMENT_EVENT) &&
          eventsCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase('Artifacts') &&
          artefactsCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (
          route.navLabel === titleCase(PATH_SEGMENT_LOCATION) &&
          locationsCount > 0
        ) {
          return { ...route, disabled: false }
        } else if (route.navLabel === 'People' && individualsCount > 0) {
          return { ...route, disabled: false }
        } else if (route.navLabel === 'FANs' && individualsCount > 0) {
          return { ...route, disabled: false }
        } else return route
      })

      if (
        pageType !== INSTANCE_TYPE_FAMILY &&
        pageType !== INSTANCE_TYPE_INDIVIDUAL
      ) {
        if (
          !viewConfigQueryParams.hierarchical &&
          publicPageRoutes[0].disabled &&
          publicPageRoutes[1].disabled &&
          location.pathname.substring(location.pathname.length - 5) !==
            'media' &&
          location.pathname.substring(location.pathname.length - 6) !==
            'albums' &&
          mediaCount > 0 &&
          articlesCount === 0
        ) {
          // redirect to media tab if no description + no article
          const subroot =
            url.substring(url.length - 1) === '/' ? 'media' : '/media'
          history.replace(`${url}${subroot}`)
        }
      }

      setPageRoutes(publicPageRoutes)
    },
    [
      pageType,
      routes,
      history,
      location.pathname,
      url,
      viewConfigQueryParams.hierarchical,
    ]
  )

  useEffect(() => {
    dispatchFetchPublicPageCounts({ target: linkedPageId, treeSlug }).then(
      response => {
        handleDisabledRoutes(response?.payload)
      }
    )
  }, [
    dispatchFetchPublicPageCounts,
    handleDisabledRoutes,
    linkedPageId,
    treeSlug,
  ])

  return (
    <Tabs
      disableSearch
      routes={pageRoutes}
      fullWidthSubRootContainer={true}
      isPublic={true}
      title={
        <PublicPageTitle
          {...{
            ...props,
            pageType,
            treeSlug,
          }}
        />
      }
    />
  )
}

export default PublicPage
