import { useContext, useEffect } from 'react'
import { PublicContext } from 'src/modules/public/contexts'
import { usePublicPageUrlSegments } from 'src/modules/public/page/hooks'
import { generatePublicTreeRoot } from 'src/modules/app/links'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPublicPageState,
  fetchPublicPageItem,
  selectPublicPageItem,
} from 'src/modules/public/page/pageSlice'
import { useActionDispatcher } from 'src/modules/app/hooks'
import { INSTANCE_TYPE_EVENT } from 'src/modules/app/links'

export const useDynamicPublicPageLoadingHelpers = () => {
  const { linkedPageId, pathSegment } = usePublicPageUrlSegments()
  let { treeSlug } = useContext(PublicContext)
  const publicTreeSlug = generatePublicTreeRoot(treeSlug).substring(1)

  const dispatch = useDispatch()
  const fetchedLinkedPage = useSelector(selectPublicPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchPublicPageItem)

  const title = fetchedLinkedPage?.title
  const dynamicPagePath = pathSegment

  useEffect(() => {
    dispatch(resetPublicPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    dispatchFetchLinkedPageItem({
      treeSlug,
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
    })
  }, [dispatchFetchLinkedPageItem, linkedPageId, treeSlug])

  const isLoading = dispatchFetchLinkedPageItem.status === 'loading'

  return {
    title,
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    dynamicPagePath,
    publicTreeSlug,
  }
}
