import React from 'react'
import { styled } from '@mui/material'

import { useBackgroundImageSettings } from 'src/utils'
import { useAvailableFeatures } from '../auth/hooks'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useTheme } from '@mui/material'

const Root = styled('div')(({ showBanner, theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: theme.headerHeight,
  position: 'fixed',
  width: '100%',
  zIndex: theme.zIndex.appBar,
}))

const Background = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})(({ backgroundImage, theme }) => ({
  backgroundImage,
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  height: 'fit-content',
}))

const Toolbar = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: theme.headerHeight,
  maxWidth: theme.maxWidth,
  margin: '0 auto',
  position: 'relative',
  padding: theme.spacing(0, 2),
}))

const LeftSide = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  height: '100%',
}))

const RightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'flex-end',
  height: '100%',
}))

const Overlay = ({ overlayImage }) => {
  const theme = useTheme()
  return (
    <>
      {overlayImage && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            height: theme.headerHeight,
            overflow: 'hidden',
          }}
        >
          <img
            alt="overlay"
            style={{ maxWidth: '100%', height: 'auto' }}
            src={overlayImage}
          />
        </div>
      )}
    </>
  )
}

const AppBar = ({
  leftSide,
  rightSide,
  backgroundColor,
  shouldShowImage = true,
  backgroundImageOverride,
}) => {
  const treeCanEdit = useAvailableFeatures()?.tree?.editTree
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const pageOutOfTree = !window?.location?.pathname.includes(treeSlug)
  const { backgroundImage, overlayImage } = useBackgroundImageSettings()
  let backgroundImageToUse

  if (backgroundImageOverride) {
    backgroundImageToUse = backgroundImageOverride
  } else {
    backgroundImageToUse = backgroundImage
  }

  return (
    <Root
      className="mui-fixed hideVisibilityOnPrint"
      showBanner={!treeCanEdit && !pageOutOfTree}
      style={{ backgroundColor: backgroundColor ? backgroundColor : 'auto' }}
    >
      <Background backgroundImage={shouldShowImage ? backgroundImageToUse : ''}>
        <Overlay overlayImage={overlayImage} />
        <Toolbar>
          <LeftSide>{leftSide}</LeftSide>
          <RightSide>{rightSide}</RightSide>
        </Toolbar>
      </Background>
    </Root>
  )
}

export default AppBar
