import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActionDispatcher, useSetTree } from '../app'
import {
  fetchUser,
  selectAuthorisedTreeSlug,
  selectUser,
  updateProfile,
} from './authSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { UserDetailsForm } from './ProfileForm'
import { Button, Container, Form, Typography } from 'src/modules/ui'
import { useHistory } from 'react-router-dom'
import { generateTreeLink } from '../app/links'
import { styled } from '@mui/material'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

const Header = styled('div')(({ theme, embed }) => ({
  justifyContent: 'center',
  display: 'flex',
}))

export default function CompleteProfile({ nextLink }) {
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const dispatchUpdateProfile = useActionDispatcher(updateProfile)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const user = useSelector(selectUser)
  const history = useHistory()
  const [photo, setPhoto] = useState(user.photo)
  const setTree = useSetTree()

  const initialValues = {
    givenName: user.givenName,
    surname: user.surname,
    knownAs: user.knownAs || user.givenName.split(' ')[0],
  }
  const validationSchema = Yup.object().shape({
    givenName: Yup.string().required(),
    surname: Yup.string().required(),
    knownAs: Yup.string(),
  })

  const handleSubmit = async (
    { givenName, surname, knownAs },
    { setErrors }
  ) => {
    try {
      await dispatchUpdateProfile(
        {
          givenName,
          surname,
          knownAs,
          photo,
        },
        {
          successNotification: 'User profile completed',
        }
      ).unwrap()
      sendEvent(ga4Events.COMPLETE_PROFILE_SUBMITTED_OK)
      await dispatchFetchUser()
      setTree(treeSlug, true)
      history.push(generateTreeLink(treeSlug, nextLink || 'home'))
    } catch (error) {
      setErrors(error.data)
    }
  }

  return (
    <Container maxWidth="sm">
      <Header>
        <Typography variant="h3" color="textPrimary" sx={{ mb: 4, mt: 2 }}>
          Complete Your Profile
        </Typography>
      </Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formik => (
          <Form>
            <UserDetailsForm
              handleSelect={img => setPhoto(img)}
              photoUrl={photo?.fileMedium}
              allowSelectFromLibrary={false}
            />
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              color="primary"
              onClick={formik.handleSubmit}
              isLoading={dispatchUpdateProfile.status === 'loading'}
              size="large"
              type="submit"
              sx={{ mt: 1 }}
            >
              Complete Profile
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
