import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDynamicPageTypeDefLookups } from './dynamicPageHooks'
import IndividualsPage from 'src/modules/page/IndividualsPage'
import DynamicPage from './DynamicPage'
import { LoadingIndicator } from 'src/modules/ui'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { createRoutesFromDynamicPageTypeDef } from './dynamicPageHooks'
import { DynamicPageContext } from './dynamicPageContexts'

const dynamicTabPageComponentMap = {
  individual_page: IndividualsPage,
}

const DynamicTabPage = () => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { dynamicPagePath, linkedPageId } = useParams()
  const { findDynamicPageTypeDefByPath } = useDynamicPageTypeDefLookups()

  // Add state for the page type definitions
  const [dynamicPageTypeDef, setDynamicPageTypeDef] = useState(null)

  // Load the page type definitions
  useEffect(() => {
    const loadPageTypeDefs = async () => {
      const dynamicDef = await findDynamicPageTypeDefByPath(dynamicPagePath)
      setDynamicPageTypeDef(dynamicDef)
    }
    loadPageTypeDefs()
  }, [dynamicPagePath, findDynamicPageTypeDefByPath])

  if (!dynamicPageTypeDef) {
    return <LoadingIndicator />
  }

  const Component =
    dynamicTabPageComponentMap[dynamicPageTypeDef.pageComponentType]
  const linkedPagePathOverride = `${dynamicPageTypeDef.pageTypePath}/${linkedPageId}`
  if (Component) {
    const routes = createRoutesFromDynamicPageTypeDef({
      treeSlug,
      linkedPageId,
      dynamicPageTypeDef,
    })

    return (
      <DynamicPageContext.Provider
        value={{
          dynamicPageId: dynamicPageTypeDef?.id,
          dynamicPageTypeDef,
          linkedPageId,
        }}
      >
        <Component routeOverride={routes} />
      </DynamicPageContext.Provider>
    )
  } else {
    return (
      <>
        <DynamicPage linkedPagePathOverride={linkedPagePathOverride} />
      </>
    )
  }
}

export default DynamicTabPage
