import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { PageContext } from 'src/modules/page/pageContexts'
import {
  selectIndividualById,
  updateIndividual,
} from 'src/modules/viewer/viewerSlice'
import { resetPageState } from 'src/modules/page/pageSlice'

import { useActionDispatcher, useTreeSettings } from 'src/modules/app/hooks'

import Page, {
  getHistoryRoute,
  getDocumentsRoute,
  getPhotosRoute,
  getLocationsRoute,
  getArtefactsRoute,
  getEventsRoute,
  getTreeRoute,
  getIndividualProfileRoute,
  getFactsRoute,
  getFansRoute,
} from './Page'
import {
  formatIndividualDates,
  formatIndividualName,
  formatIndividualWithDates,
} from 'src/modules/ui/individualUtils'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'
import { UpdateIndividualRelationshipsDialog } from '../viewer/UpdateIndividualRelationships'
import InstanceVisibilityControl from 'src/modules/visibility/InstanceVisibilityControl'
import { STATE_DECEASED } from 'src/modules/viewer/CreateOrUpdateIndividualForm'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import {
  fetchIndividual,
  selectIndividualForPage,
  selectIsNodeDirectoryIsLoaded,
  fetchIndividualsForTarget,
} from '../viewer/viewerSlice'

import HtmlHeader from '../app/HtmlHeader'
import EditButton from '../ui/EditButton'
import { useSelectRelatedFamilies } from './hooks'
import { VISIBILITY_TREE } from '../visibility/visibilityUtils'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import LoadingGuard from '../viewer/LoadingGuard'
import { ACTION_EDIT } from '../app/appConstants'

const IndividualsPage = ({ routeOverride, linkedPageIdOverride }) => {
  const dispatch = useDispatch()
  let { linkedPageId } = useParams()
  linkedPageId = linkedPageIdOverride || linkedPageId
  const showFeature = useIsAlphaFeatureUser()?.documents
  const isNodeDirectoryLoaded = useSelector(selectIsNodeDirectoryIsLoaded)
  var individual = useSelector(selectIndividualById(linkedPageId))
  var individualForPage = useSelector(selectIndividualForPage)
  individual = individual || individualForPage || {}
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const dispatchFetchIndividualsForTarget = useActionDispatcher(
    fetchIndividualsForTarget
  )

  const { alias, knownAs, givenName, prefix, suffix, family, photo } =
    individual

  const dispatchUpdateIndividual = useActionDispatcher(updateIndividual)
  const { isOnePlaceStudyArchive } = useTreeSettings()

  useEffect(() => {
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  const handleUpdateIndividual = photo => {
    dispatchUpdateIndividual({
      individualId: individual.id,
      setPhoto: photo ? photo.id : null,
    })
  }

  useEffect(() => {
    if (!individualForPage) {
      // if the Individual from the loaded tree is not available, try loading the Individual from the server
      dispatch(fetchIndividual({ individualId: linkedPageId }))
      dispatchFetchIndividualsForTarget({
        treeSlug,
        target: linkedPageId,
      })
    }
  }, [
    dispatch,
    isNodeDirectoryLoaded,
    individualForPage,
    treeSlug,
    linkedPageId,
    dispatchFetchIndividualsForTarget,
  ])

  let routes = useMemo(
    () =>
      [
        getIndividualProfileRoute({
          pageType: INSTANCE_TYPE_INDIVIDUAL,
          linkedPageId,
          treeSlug,
          makeDefault: isOnePlaceStudyArchive ? true : false,
          localLabelOverride: 'Profile',
        }),
        getTreeRoute({
          pageType: INSTANCE_TYPE_INDIVIDUAL,
          linkedPageId,
          localLabelOverride: `Tree`,
          makeDefault: isOnePlaceStudyArchive ? false : true,
          treeSlug,
        }),
        getFactsRoute({
          pageType: INSTANCE_TYPE_INDIVIDUAL,
          linkedPageId,
          treeSlug,
        }),
        getHistoryRoute({
          pageType: INSTANCE_TYPE_INDIVIDUAL,
          linkedPageId,
          treeSlug,
        }),
        showFeature &&
          getDocumentsRoute({
            pageType: INSTANCE_TYPE_INDIVIDUAL,
            linkedPageId,
            treeSlug,
          }),
        getPhotosRoute({
          pageType: INSTANCE_TYPE_INDIVIDUAL,
          linkedPageId,
          treeSlug,
        }),
        individualForPage &&
          getEventsRoute({
            pageType: INSTANCE_TYPE_INDIVIDUAL,
            linkedPageId,
            treeSlug,
          }),
        individualForPage &&
          getArtefactsRoute({
            pageType: INSTANCE_TYPE_INDIVIDUAL,
            linkedPageId,
            treeSlug,
          }),
        individualForPage &&
          getLocationsRoute({
            pageType: INSTANCE_TYPE_INDIVIDUAL,
            linkedPageId,
            treeSlug,
          }),
        individualForPage &&
          getFansRoute({
            pageType: INSTANCE_TYPE_INDIVIDUAL,
            linkedPageId,
            localLabelOverride: `FANs`,
            treeSlug,
          }),
      ].filter(x => x),
    [
      linkedPageId,
      treeSlug,
      showFeature,
      individualForPage,
      isOnePlaceStudyArchive,
    ]
  )

  if (routeOverride) {
    routes = routeOverride
  }

  const families = useSelectRelatedFamilies(individual)

  let title = ''
  let subtitle = ''

  if (givenName || knownAs || alias) {
    title = formatIndividualName({ ...individual })
    const givenNameLength = givenName.split(' ').length

    if (
      (knownAs === givenName && !prefix && !suffix && !alias) ||
      (!knownAs && !alias && givenNameLength === 1)
    ) {
      subtitle = formatIndividualDates(individual)
    } else {
      subtitle = formatIndividualWithDates(individual)
    }
  }

  const headerProps = {
    title: title,
    type: 'profile',
    image: photo && photo.fileThumbnail,
    url: window.location.href,
    first_name: individual.knownAs ? individual.knownAs : individual.givenName,
    last_name: individual.surname,
  }

  return (
    <PageContext.Provider
      value={{
        pageType: INSTANCE_TYPE_INDIVIDUAL,
        pageObject: individual,
      }}
    >
      <LoadingGuard
        title={'loading individual .. '}
        isLoadingOveride={!individualForPage}
      >
        <HtmlHeader {...headerProps} />
        <Page
          target={individual}
          fullWidthSubRootContainer={true}
          headerComponent={null}
          routes={routes}
          showTags={false}
          editComponent={
            <>
              <InstanceVisibilityControl
                id={individual.id}
                author={individual?.author}
                type={INSTANCE_TYPE_INDIVIDUAL}
                visibility={individual.visibility}
                iconSize="medium"
                overrideDisabled={
                  individual.visibility === VISIBILITY_TREE &&
                  individual.state !== STATE_DECEASED
                }
              />
              {
                <Box sx={{ display: { xs: 'inline', md: 'block' } }}>
                  <UpdateIndividualRelationshipsDialog
                    individual={individual}
                    trigger={({ onClick, ...props }) => (
                      <EditButton
                        permissionAction={ACTION_EDIT}
                        permissionParams={{
                          instance: individual,
                          instanceType: INSTANCE_TYPE_INDIVIDUAL,
                        }}
                        white
                        onClick={onClick}
                        {...props}
                      />
                    )}
                  />
                </Box>
              }
            </>
          }
          title={title}
          subtitle={subtitle}
          photoUrl={photo && photo.fileThumbnail}
          targetId={linkedPageId}
          type={INSTANCE_TYPE_INDIVIDUAL}
          extraLinksForUploadedMedia={[family]}
          handleSelect={handleUpdateIndividual}
          families={families}
        />
      </LoadingGuard>
    </PageContext.Provider>
  )
}

export default IndividualsPage
