import { createSlice } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'

import {
  createComment,
  createCommentFulfilled,
  deleteComment,
  deleteCommentFulfilled,
} from 'src/modules/content/contentSlice'
import {
  cropExistingPhoto,
  rotatePhotoLeft,
  rotatePhotoRight,
  revertToOriginal,
} from 'src/modules/photo/photoSlice'
import { setVisibility } from 'src/modules/visibility/visibilitySlice'
import { DEFAULT_FEED_LENGTH } from '../app/appConstants'
import {
  API_PATH_SEGMENT_FOR_INSTANCE_TYPE,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from '../app/links'
import {
  createArticle,
  deleteArticle,
  publishNewRevision,
} from '../writeArticle/writeArticleSlice'
import {
  createIndividual,
  fetchFamily,
  fetchIndividual,
  updateIndividual,
} from '../viewer/viewerSlice'
import { sortTypes } from '../common/constants'
import {
  getViewConfig,
  updateStateWithViewConfigDefaults,
} from '../common/viewConfigUtils'

const SLICE_NAME = 'page'

const getViewConfigAccessor = (state, type) => {
  const stateKey = stateKeyMap[type]

  if (stateKey) {
    return state.page.currentPageState[stateKey]
  }
  return {}
}

export const updateCurrentPageStateAfterPhotoAltered = (
  state,
  alteredMedia
) => {
  state.currentPageState.media.results =
    state.currentPageState.media.results.map(photo => {
      return photo.id === alteredMedia.id ? alteredMedia : photo
    })
}

const fetchPageContentThunk = (
  { target, page, type, block_limit, block_type, limit = PAGE_SIZE },
  { getState }
) => {
  const offset = page * limit

  const existingState = getState()

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    getViewConfigAccessor(existingState, type)
  )

  const queryStringParameters = pickBy({
    target,
    type,
    offset,
    ordering: sort,
    limit,
    hierarchical,
    ancestral_only: ancestralOnly,
    block_type,
    block_limit,
  })

  return api.get(`/history/${getTreeSlugFromStore()}/content/`, {
    queryStringParameters,
  })
}

export const fetchPageContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPageContent`,
  fetchPageContentThunk
)

export const fetchPageContentDocuments = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPageDocuments`,
  fetchPageContentThunk
)

export const fetchPageContentAlbums = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPageContentAlbums`,
  fetchPageContentThunk
)

export const fetchLinkedPages = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPages`,
  ({ subject, target, page, type }, { getState }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[type]
    const limit = PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { sort, hierarchical, ancestralOnly } = getViewConfig(
      getViewConfigAccessor(existingState, type)
    )

    const queryStringParameters = pickBy({
      subject,
      target,
      offset,
      ordering: sort,
      limit,
      hierarchical,
      ancestral_only: ancestralOnly,
    })

    return api.get(`/history/${getTreeSlugFromStore()}/${pathSegment}/`, {
      queryStringParameters,
    })
  }
)

export const fetchLinkedPageThunk = (
  {
    subject,
    target,
    pageSize = PAGE_SIZE,
    page,
    type,
    viewConfigQueryParams,
    dynamicPageDefId,
  },
  { getState }
) => {
  const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[type]
  const offset = page * pageSize

  const existingState = getState()

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    getViewConfigAccessor(existingState, type),
    viewConfigQueryParams
  )

  const queryStringParameters = pickBy({
    subject,
    target,
    offset,
    ordering: sort,
    limit: pageSize,
    hierarchical,
    ancestral_only: ancestralOnly,
  })

  if (dynamicPageDefId) {
    queryStringParameters.dynamic_page_def_id = dynamicPageDefId
    return api.get(`/history/${getTreeSlugFromStore()}/dynamic_type/`, {
      queryStringParameters,
    })
  } else {
    return api.get(`/history/${getTreeSlugFromStore()}/${pathSegment}/`, {
      queryStringParameters,
    })
  }
}

export const fetchLinkedPageEvents = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPageEvents`,
  fetchLinkedPageThunk
)

export const fetchLinkedPageArtefacts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPageArtefacts`,
  fetchLinkedPageThunk
)

export const fetchLinkedPageLocations = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPageLocations`,
  fetchLinkedPageThunk
)

export const fetchLinkedIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedIndividuals`,
  ({ subject, target, page, viewConfigQueryParams }, { getState }) => {
    const type = INSTANCE_TYPE_INDIVIDUAL
    const limit = INDIVIDUALS_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { sort, hierarchical, ancestralOnly } = getViewConfig(
      getViewConfigAccessor(existingState, type),
      viewConfigQueryParams
    )

    const { searchTerm } = getViewConfigAccessor(existingState, type)

    const queryStringParameters = pickBy({
      search: searchTerm,
      subject,
      target,
      offset,
      limit,
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })
    return api.get(`/tree/${getTreeSlugFromStore()}/simple-individuals`, {
      queryStringParameters,
    })
  }
)

export const fetchLinkedFamilies = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedFamilies`,
  ({ subject, target, page, viewConfigQueryParams }, { getState }) => {
    const type = INSTANCE_TYPE_FAMILY
    const limit = FAMILIES_PAGE_SIZE
    const offset = page * limit

    const existingState = getState()

    const { sort, hierarchical, ancestralOnly } = getViewConfig(
      getViewConfigAccessor(existingState, type),
      viewConfigQueryParams
    )

    const { searchTerm } = getViewConfigAccessor(existingState, type)

    const queryStringParameters = pickBy({
      search: searchTerm,
      subject,
      target,
      offset,
      limit,
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })
    return api.get(`/history/${getTreeSlugFromStore()}/simple-families`, {
      queryStringParameters,
    })
  }
)

export const fetchLinkedPhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPhotos`,
  ({ target, page }, { getState }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit
    const existingState = getState()

    const { hierarchical, ancestralOnly, sort } = getViewConfig(
      getViewConfigAccessor(existingState, INSTANCE_TYPE_MEDIA)
    )

    const queryStringParameters = pickBy({
      target,
      offset,
      limit,
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })
    return api.get(`/history/${getTreeSlugFromStore()}/media/`, {
      queryStringParameters,
    })
  }
)

export const fetchLinkedFeed = createWrappedAsyncThunk(
  `${SLICE_NAME}fetchLinkedFeed`,
  ({ target, before }) => {
    const queryStringParameters = {}
    if (before) {
      queryStringParameters.before = before
    } else {
      queryStringParameters.before = new Date().toISOString()
    }
    return api.get(
      `/history/${getTreeSlugFromStore()}/feed/?target=${target}`,
      {
        queryStringParameters,
      }
    )
  }
)

export const fetchLinkedFacts = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedFacts`,
  ({ target, allFacts = false }) => {
    const allFactsParam = allFacts ? '&allFacts=true' : ''
    return api.get(
      `/history/${getTreeSlugFromStore()}/fact-timeline/?target=${target}${allFactsParam}`
    )
  }
)

export const updateLinkedFact = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateLinkedFact`,
  async ({ linkedPageId, id, pageIndividual, ...updates }, { dispatch }) => {
    const result = await api.patch(
      `/history/${getTreeSlugFromStore()}/facts/${id}/`,
      {
        body: {
          // name: id,
          ...updates,
        },
      }
    )

    //go get the individual again if we have update facts!
    if (pageIndividual) {
      dispatch(
        fetchIndividual({
          individualId: pageIndividual.id,
        })
      )
    }

    return result
  }
)

export const deleteLinkedFact = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteLinkedFact`,
  async ({ id, pageIndividual }, { dispatch }) => {
    const result = await api.del(
      `/history/${getTreeSlugFromStore()}/facts/${id}/`
    )

    if (pageIndividual) {
      dispatch(
        fetchIndividual({
          individualId: pageIndividual.id,
        })
      )
    }

    return result
  }
)

export const createLinkedFact = createWrappedAsyncThunk(
  `${SLICE_NAME}/createLinkedFact`,
  async ({ setTargets, pageIndividual, ...attrs }, { dispatch }) => {
    const result = await api.post(`/history/${getTreeSlugFromStore()}/facts/`, {
      body: {
        setTargets,
        ...attrs,
      },
    })

    if (pageIndividual) {
      dispatch(
        fetchIndividual({
          individualId: pageIndividual.id,
        })
      )
    }

    return result
  }
)

export const createSourceCitationLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/createSourceCitationLink`,
  async ({ setTargets, source, citationMedia }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/source-citation-link/`, {
      body: {
        setTargets,
        source,
        citationMedia,
      },
    })
  }
)

export const fetchSourceCitationLinkForSourceId = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchSourceCitationLinkForSourceId`,
  async ({ sourceId }) => {
    const queryStringParameters = {
      source_id: sourceId,
    }

    return api.get(`/tree/${getTreeSlugFromStore()}/source-citation-link/`, {
      queryStringParameters,
    })
  }
)

export const createSourceLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/createSourceLink`,
  async ({ mediaId, instanceId }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/source-link/`, {
      body: {
        mediaId,
        instanceId,
      },
    })
  }
)

export const createMediaOnSourceCitationLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/createMediaOnSourceCitation`,
  async ({ mediaId, instanceId }) => {
    return api.post(
      `/tree/${getTreeSlugFromStore()}/media-source-citation-link/`,
      {
        body: {
          mediaId,
          instanceId,
        },
      }
    )
  }
)

export const deleteMediaOnSourceCitationLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/createMediaOnSourceCitation`,
  async ({ mediaId, instanceId }) => {
    return api.del(
      `/tree/${getTreeSlugFromStore()}/media-source-citation-link/${instanceId}/`,
      {
        body: {
          mediaId,
          instanceId,
        },
      }
    )
  }
)

export const deleteSourceLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteSourceLink`,
  async ({ mediaId, instanceId }) => {
    return api.del(
      `/tree/${getTreeSlugFromStore()}/source-link/${instanceId}/`,
      {
        body: {
          mediaId,
          instanceId,
        },
      }
    )
  }
)

export const createFactSource = createWrappedAsyncThunk(
  `${SLICE_NAME}/createFactSource`,
  async ({ setTargets, gedcomLines, citationDetails }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/source/`, {
      body: {
        setTargets,
        gedcomLines,
        citationDetails,
      },
    })
  }
)

export const updateFactSource = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateFactSource`,
  ({ id, ...updates }) => {
    return api.patch(`/tree/${getTreeSlugFromStore()}/gedcom-line/${id}/`, {
      body: {
        ...updates,
      },
    })
  }
)

export const createGedcomLine = createWrappedAsyncThunk(
  `${SLICE_NAME}/createGedcomLine`,
  ({ sourceId, lineData }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/gedcom-line/`, {
      body: {
        sourceId,
        ...lineData,
      },
    })
  }
)

export const deleteFactSourceCitationLink = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteFactSourceCitationLink`,
  ({ id }) => {
    return api.del(
      `/tree/${getTreeSlugFromStore()}/source-citation-link/${id}/`
    )
  }
)

export const fetchAllSources = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAllSources`,
  ({ page }) => {
    const limit = 10 // the whole page is restricted to a max width which fits 7
    let offset = page * limit
    if (!page) {
      offset = 0
    }
    const queryStringParameters = pickBy({
      offset,
      limit,
    })
    return api.get(`/tree/${getTreeSlugFromStore()}/source/`, {
      queryStringParameters,
    })
  }
)

export const reorderPageContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/reorderPageContent`,
  ({ target, content, putBelowContent, type }) => {
    return api.put(
      `/history/${getTreeSlugFromStore()}/links/content/reorder/`,
      {
        body: {
          target,
          content,
          putBelowContent,
        },
      }
    )
  }
)

export const fetchLinkedPageItem = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchLinkedPageItem`,
  ({ pageType, linkedPageId }, { getState }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]

    const existingState = getState()

    const { sort, hierarchical, ancestralOnly } = getViewConfig(
      getViewConfigAccessor(existingState, pageType)
    )

    const queryStringParameters = pickBy({
      hierarchical,
      ordering: sort,
      ancestral_only: ancestralOnly,
    })

    return api.get(
      `/history/${getTreeSlugFromStore()}/${pathSegment}/${linkedPageId}/`,
      {
        queryStringParameters,
      }
    )
  }
)

export const updateLinkedPageItem = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateLinkedPageItem`,
  ({ pageType, linkedPageId, photo, title, ...attrs }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]
    return api.patch(
      `/history/${getTreeSlugFromStore()}/${pathSegment}/${linkedPageId}/`,
      {
        body: {
          ...{ setPhoto: photo ? photo.id : null },
          title,
          ...attrs,
        },
      }
    )
  }
)

export const deleteLinkedPageItem = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteLinkedPageItem`,
  ({ pageType, linkedPageId }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]
    return api.del(
      `/history/${getTreeSlugFromStore()}/${pathSegment}/${linkedPageId}/`
    )
  }
)

export const createLinkedPageItem = createWrappedAsyncThunk(
  `${SLICE_NAME}/createLinkedPageItem`,
  async ({ pageType, title, setTargets, ...attrs }) => {
    const pathSegment = API_PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]
    return api.post(`/history/${getTreeSlugFromStore()}/${pathSegment}/`, {
      body: {
        title,
        setTargets,
        ...attrs,
      },
    })
  }
)

const stateKeyMap = {}
stateKeyMap[INSTANCE_TYPE_DOCUMENT] = 'documents'
stateKeyMap[INSTANCE_TYPE_LOCATION] = 'linkedPageLocations'
stateKeyMap[INSTANCE_TYPE_ARTEFACT] = 'linkedPageArtefacts'
stateKeyMap[INSTANCE_TYPE_EVENT] = 'linkedPageEvents'
stateKeyMap[INSTANCE_TYPE_ARTICLE] = 'content'
stateKeyMap[INSTANCE_TYPE_MEDIA] = 'media'
stateKeyMap[INSTANCE_TYPE_PHOTO_ALBUM] = 'albums'
stateKeyMap[INSTANCE_TYPE_INDIVIDUAL] = 'individuals'

export const emptyPageState = {
  lastUpdated: undefined,
  content: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  documents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  linkedPagesWelcome: {
    loading: true,
    article: null,
    articleId: null,
  },
  albums: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  linkedPages: {
    page: 0,
    count: 0,
    results: [],
  },
  linkedPageEvents: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  linkedPageArtefacts: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  linkedPageLocations: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.PUBLISHED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
    showMap: true,
  },
  media: {
    page: 0,
    count: 0,
    results: [],
    sort: sortTypes.CREATED,
    hierarchical: false,
    ancestralOnly: false,
    userHasUpdatedViewConfig: false,
  },
  individuals: {
    page: 0,
    count: 0,
    results: [],
    searchTerm: undefined,
    sort: sortTypes.FULL_NAME_ASC,
    hierarchical: false,
    ancestralOnly: false,
  },
  families: {
    page: 0,
    count: 0,
    results: [],
    searchTerm: undefined,
    sort: sortTypes.FULL_NAME_ASC,
    hierarchical: false,
    ancestralOnly: false,
  },
  feed: {
    before: null,
    hasMore: true,
    results: [],
  },
  facts: undefined,
  linkedPageItem: {},
  allSources: {
    before: null,
    hasMore: true,
    results: [],
  },
}

export const setViewConfig = createWrappedAsyncThunk(
  `${SLICE_NAME}/setViewConfig`,
  ({ type, viewConfig }) => {
    const key = type
    const view_key = stateKeyMap[key]

    //save the defaults if the user has selected them
    if (viewConfig.defaultSelected) {
      const params = {
        target: viewConfig.target,
        view_key,
        hierarchical: viewConfig.hierarchical,
        sort: viewConfig.sort,
        ancestral_only: viewConfig.ancestralOnly,
        showMap: viewConfig.showMap,
      }

      api.post(`/history/${getTreeSlugFromStore()}/view_config_links/`, {
        body: params,
      })
    }

    return Promise.resolve({ type, viewConfig })
  }
)

const resetArticlesAndDocuments = (state, payload) => {
  const results = { page: 0, count: 0, results: [] }

  if (payload.type === INSTANCE_TYPE_DOCUMENT) {
    state.currentPageState.documents = {
      ...state.currentPageState.documents,
      ...results,
    }
  } else {
    state.currentPageState.content = {
      ...state.currentPageState.content,
      ...results,
    }
  }
}

const resetOnUpdateLinkedItem = (state, payload) => {
  state.currentPageState = {
    ...emptyPageState,
    linkedPageItem: payload,
    lastUpdated: Date.now(),
  }
}

const resetIndividuals = (state, payload) => {
  state.currentPageState.individuals = {
    ...state.currentPageState.individuals,
    ...emptyPageState.individuals,
    updated: Date.now(),
  }
  state.pageCache = {}
  state.lastUpdated = Date.now()
}

export const initialState = {
  currentPageId: undefined,
  currentPageState: emptyPageState,
  pageCache: { currentPageState: emptyPageState },
}

const PAGE_SIZE = 8
const PHOTO_PAGE_SIZE = 10
const INDIVIDUALS_PAGE_SIZE = 20
const FAMILIES_PAGE_SIZE = 50

export const pageSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageIndividualsSearchTerm: (state, action) => {
      state.currentPageState.individuals.searchTerm = action.payload
    },
    resetPageToInitialState: state => initialState,
    resetPageState: (state, action) => {
      if (state.currentPageId) {
        state.pageCache[state.currentPageId] = state.currentPageState
      }
      const newPage =
        state.pageCache[action.payload?.linkedPageId] || emptyPageState

      state.currentPageState = newPage

      state.currentPageId = action.payload?.linkedPageId
    },
    setLinkedPageArticle: (state, action) => {
      if (action) {
        state.currentPageState.linkedPagesWelcome.article = action.payload
        state.currentPageState.linkedPagesWelcome.articleId = action.payload?.id
        state.currentPageState.linkedPagesWelcome.loading = false
      }
    },
    resetPageAlbums: state => {
      state.currentPageState.albums = initialState.currentPageState.albums
    },
  },
  extraReducers: {
    ...handlePaginatedAction(
      fetchLinkedPageEvents,
      state => state.currentPageState.linkedPageEvents
    ),
    ...handlePaginatedAction(
      fetchLinkedPageArtefacts,
      state => state.currentPageState.linkedPageArtefacts
    ),
    ...handlePaginatedAction(
      fetchLinkedPageLocations,
      state => state.currentPageState.linkedPageLocations
    ),
    ...handlePaginatedAction(
      fetchLinkedIndividuals,
      state => state.currentPageState.individuals
    ),
    ...handlePaginatedAction(
      fetchLinkedFamilies,
      state => state.currentPageState.families
    ),
    ...handlePaginatedAction(
      fetchPageContent,
      state => state.currentPageState.content
    ),
    ...handlePaginatedAction(
      fetchPageContentDocuments,
      state => state.currentPageState.documents
    ),
    ...handlePaginatedAction(
      fetchPageContentAlbums,
      state => state.currentPageState.albums
    ),
    ...handlePaginatedAction(
      fetchLinkedPages,
      state => state.currentPageState.linkedPages
    ),
    ...handlePaginatedAction(
      fetchLinkedPhotos,
      state => state.currentPageState.media
    ),
    ...handlePaginatedAction(
      fetchAllSources,
      state => state.currentPageState.allSources
    ),
    [fetchLinkedPages.pending]: state => {
      state.currentPageState.linkedPages.results = []
    },
    [fetchLinkedFacts.fulfilled]: (state, { meta, payload }) => {
      state.currentPageState.facts = payload
    },
    [fetchLinkedFeed.pending]: (state, { meta, payload }) => {
      const before = meta.arg?.before
      if (!before) {
        state.currentPageState.feed.hasMore = true
      } else {
        state.currentPageState.feed.before = before
      }
    },
    [fetchLinkedFeed.fulfilled]: (state, { meta, payload }) => {
      const before = meta.arg?.before
      if (!before) {
        state.currentPageState.feed.results = payload
      } else {
        state.currentPageState.feed.results =
          state.currentPageState.feed.results.concat(payload)
      }
      if (payload.length < DEFAULT_FEED_LENGTH) {
        state.currentPageState.feed.hasMore = false
      }
    },
    [fetchLinkedPageItem.pending]: (state, { payload }) => {
      state.currentPageState = emptyPageState
    },
    [fetchLinkedPageItem.fulfilled]: (state, { payload }) => {
      state.currentPageState.linkedPageItem = payload
      state.currentPageState.linkedPagesWelcome.articleId =
        payload?.pinnedArticle?.id
      state.currentPageState.linkedPagesWelcome.loading = false
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks
      )
    },
    [fetchIndividual.fulfilled]: (state, { payload }) => {
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks
      )
    },
    [fetchFamily.fulfilled]: (state, { payload }) => {
      updateStateWithViewConfigDefaults(
        state.currentPageState,
        payload.viewConfigLinks
      )
    },
    [updateLinkedPageItem.fulfilled]: (state, { meta: { arg }, payload }) => {
      resetOnUpdateLinkedItem(state, payload)
    },
    [deleteLinkedPageItem.fulfilled]: (state, { meta: { arg }, payload }) => {
      resetOnUpdateLinkedItem(state, {})
    },
    [createComment.fulfilled]: createCommentFulfilled(
      state => state.currentPageState.content.results
    ),
    [deleteComment.fulfilled]: deleteCommentFulfilled(
      state => state.currentPageState.content.results
    ),
    [reorderPageContent.fulfilled]: (state, { meta, payload }) => {
      const stateKey = stateKeyMap[meta.arg.type]
      const content = [...state.currentPageState[stateKey].results]
      const { contentIndex, swapIndex } = meta.arg
      const contentToMove = content[contentIndex]
      const contentToSwap = content[swapIndex]

      content[swapIndex] = contentToMove
      content[contentIndex] = contentToSwap

      state.currentPageState[stateKey].results = content
    },
    [cropExistingPhoto.fulfilled]: (state, { payload }) => {
      updateCurrentPageStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoLeft.fulfilled]: (state, { payload }) => {
      updateCurrentPageStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoRight.fulfilled]: (state, { payload }) => {
      updateCurrentPageStateAfterPhotoAltered(state, payload)
    },
    [revertToOriginal.fulfilled]: (state, { payload }) => {
      updateCurrentPageStateAfterPhotoAltered(state, payload)
    },
    [createArticle.fulfilled]: (state, { payload }) => {
      resetArticlesAndDocuments(state, payload)
    },
    [publishNewRevision.fulfilled]: (state, { payload }) => {
      resetArticlesAndDocuments(state, payload)
    },
    [deleteArticle.fulfilled]: (state, { payload }) => {
      resetArticlesAndDocuments(state, payload)
    },
    [setVisibility.fulfilled]: (state, { meta }) => {
      const { instanceId: id, visibility } = meta.arg

      if (state?.linkedPageItem?.id === id) {
        state.linkedPageItem.visibility = visibility
      }
    },
    [setViewConfig.fulfilled]: (state, { payload }) => {
      const key = payload?.type
      const stateKey = stateKeyMap[key]

      if (stateKey) {
        state.currentPageState[stateKey].sort = payload?.viewConfig?.sort
        state.currentPageState[stateKey].hierarchical =
          payload?.viewConfig?.hierarchical
        state.currentPageState[stateKey].ancestralOnly =
          payload?.viewConfig?.ancestralOnly
        state.currentPageState[stateKey].showMap = payload?.viewConfig?.showMap
        state.currentPageState[stateKey].userHasUpdatedViewConfig = true
      }
    },
    [updateIndividual.fulfilled]: (state, { payload, meta }) => {
      resetIndividuals(state)
    },
    [createIndividual.fulfilled]: (state, { payload }) => {
      resetIndividuals(state)
    },
  },
})

export const {
  setPageIndividualsSearchTerm,
  resetPageState,
  resetPageToInitialState,
  setLinkedPageArticle,
  resetPageAlbums,
} = pageSlice.actions

export const selectPageContent = state => state.page.currentPageState.content
export const selectPageContentAlbums = state =>
  state.page.currentPageState.albums
export const selectPageContentDocuments = state =>
  state.page.currentPageState.documents
export const selectLinkedPages = state =>
  state.page.currentPageState.linkedPages
export const selectLinkedPageEvents = state =>
  state.page.currentPageState.linkedPageEvents
export const selectLinkedPageArtefacts = state =>
  state.page.currentPageState.linkedPageArtefacts

export const selectLinkedIndividuals = state =>
  state.page.currentPageState.individuals
export const selectLinkedFamilies = state =>
  state.page.currentPageState.families
export const selectLinkedPageLocations = state =>
  state.page.currentPageState.linkedPageLocations
export const selectLinkedPhotos = state => state.page.currentPageState.media
export const selectLinkedFeed = state => state.page.currentPageState.feed
export const selectLinkedPageItem = state =>
  state.page.currentPageState.linkedPageItem

export const selectLinkedPageLastUpdated = state =>
  state.page.currentPageState.lastUpdated
export const selectLinkedPageArticle = state =>
  state.page.currentPageState.linkedPagesWelcome
export const selectLinkedFacts = state => state.page.currentPageState.facts
export const selectLinkedFactSources = factId => state =>
  state.page.currentPageState?.facts?.find(fact => fact.id === factId)
    ?.sourcesCitationsList
export const selectAllSources = state => state.page.currentPageState.allSources

export const selectPageIndividualsSearchTerm = state =>
  state.page.currentPageState.individuals.searchTerm

export default pageSlice.reducer
