import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import { styled, CircularProgress, Divider } from '@mui/material'
import { isNull, isString } from 'lodash'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'

import { useActionDispatcher } from 'src/modules/app'
import { useNotification } from 'src/modules/app/hooks'
import ArticleHeaderMeta from 'src/modules/content/ArticleHeaderMeta'
import {
  Button,
  ConfirmDialog,
  Container,
  FormikInput,
  Typography,
} from 'src/modules/ui'

import SetThumbnail from './SetThumbnail'
import AutoFormSubmitter from './AutoFormSubmitter'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import {
  createArticle,
  createBlock,
  createLink,
  deleteArticle,
  discardDraft,
  fetchDraftArticle,
  publishNewRevision,
  selectArticle,
  selectArticleIsSaving,
  updateArticle,
  updateArticleTitle,
} from './writeArticleSlice'

import BlockToolbar from './BlockToolbar'
import PhotoColumn from './PhotoColumn'
import MediaRow from './MediaRow'
import TOCBlock from '../content/TOCBlock'
import EditableGalleryBlock from './EditableGalleryBlock'
import TagForm from './TagForm'
import SubTreeBlock from './SubTreeBlock'
import { Box } from '@mui/system'
import { AddContentBlockModal } from './AddContentBlockModal'
import { HorizontalAddElementControl } from './AddElementControl'
import ContentBlockTextEditor from './ContentBlockTextEditor'
import { EditableSourceDialog } from '../content/SourcesUI'
import { WriteArticleContext } from './contexts'

import {
  generateBlogPreviewLink,
  generateTreeLink,
  generateTreeOverrideQueryString,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_BLOG_POST,
  INSTANCE_TYPE_DOCUMENT,
} from '../app/links'
import { resetContent } from '../content/contentSlice'
import {
  subtitleStyles,
  titleStyles,
  blockquoteStyles,
} from '../content/Article'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import AiGeneratorButton from '../services/AiTextGenerator'
import { BLOG_TREE } from '../common/constants'
import {
  fetchUser,
  selectIsBlogTree,
  selectTreeBySlug,
} from '../auth/authSlice'
import { ButtonLink } from '../ui'
import { getQueryParams } from '../../utils'
import { clearPhotoOptionsResults } from '../photo/photoSlice'
import { AiBiographyGeneratorButton } from '../services/AiBiographyGenerator'
import {
  ACTION_ALL_ACCESS,
  ACTION_DELETE,
  ACTION_PUBLISH,
} from '../app/appConstants'
import { resetPublicContent } from '../public/content/contentSlice'
import MapBlock from './MapBlock'

export const CONTENT_BLOCK_TYPE_TEXT = 'TEXT'
export const CONTENT_BLOCK_TYPE_MEDIA_ROW = 'MEDIA_ROW'
export const CONTENT_BLOCK_TYPE_MAP = 'MAP'
export const CONTENT_BLOCK_TYPE_SUB_TREE = 'SUB_TREE'
export const CONTENT_BLOCK_TYPE_TOC = 'TOC'

export const CONTENT_BLOCK_LAYOUT_TEXT_BLOCK = 'TEXT_BLOCK'
export const CONTENT_BLOCK_LAYOUT_MEDIA_COLUMN = 'MEDIA_COLUMN'

const TEXT_BLOCK_LAYOUT_COMPONENTS = {
  [CONTENT_BLOCK_LAYOUT_TEXT_BLOCK]: ContentBlockTextEditor,
  [CONTENT_BLOCK_LAYOUT_MEDIA_COLUMN]: PhotoColumn,
}

export const ARTICLE_STATE_PUBLISHED = 'PUBLISHED'
export const ARTICLE_STATE_DRAFT = 'DRAFT'

const PUBLISH_SUCCESS_TEXT = {
  [ARTICLE_STATE_DRAFT]: 'Published article',
  [ARTICLE_STATE_PUBLISHED]: 'Published new revision',
}

const PUBLISH_ERROR_TEXT = {
  [ARTICLE_STATE_DRAFT]: 'Error saving Published article',
  [ARTICLE_STATE_PUBLISHED]: 'Error saving Published new revision',
}

const TITLE_STATUS = {
  [ARTICLE_STATE_DRAFT]: 'Editing latest draft',
  [ARTICLE_STATE_PUBLISHED]: 'Editing latest draft',
}
const DELETE_BUTTON_TEXT_ARTICLE = {
  [ARTICLE_STATE_DRAFT]: 'Delete draft',
  [ARTICLE_STATE_PUBLISHED]: 'Delete article',
}
const DELETE_BUTTON_TEXT_DOCUMENT = {
  [ARTICLE_STATE_DRAFT]: 'Delete draft document',
  [ARTICLE_STATE_PUBLISHED]: 'Delete document',
}
const DELETE_BUTTON_TEXT_BLOG = {
  [ARTICLE_STATE_DRAFT]: 'Delete Draft',
  [ARTICLE_STATE_PUBLISHED]: 'Delete Post',
}
const DELETE_BUTTON_TEXT = {
  [INSTANCE_TYPE_BLOG_POST]: DELETE_BUTTON_TEXT_BLOG,
  [INSTANCE_TYPE_DOCUMENT]: DELETE_BUTTON_TEXT_DOCUMENT,
  default: DELETE_BUTTON_TEXT_ARTICLE,
}
const CONFIRM_DELETE_TEXT = {
  [ARTICLE_STATE_DRAFT]: 'Discard this draft?',
  [ARTICLE_STATE_PUBLISHED]:
    'Delete this published article? This will delete all instances of this article in your blogs and trees and cannot be undone!',
}

const CONFIRM_DELETE_TEXT_DOCUMENT = {
  [ARTICLE_STATE_DRAFT]: 'Discard this draft?',
  [ARTICLE_STATE_PUBLISHED]:
    'Delete this published document? This will delete all instances of this document in your blogs and trees and cannot be undone!',
}

const CONFIRM_DELETE_TEXT_BLOG = {
  [ARTICLE_STATE_DRAFT]: 'Discard this draft?',
  [ARTICLE_STATE_PUBLISHED]:
    'Remove this post?  This will remove the blog post but leave the original article in the tree',
}

const PUBLISH_BUTTON_TEXT_BLOG = {
  [ARTICLE_STATE_DRAFT]: 'Publish',
  [ARTICLE_STATE_PUBLISHED]: 'Publish new draft',
}
const PUBLISH_BUTTON_TEXT_ARTICLE = {
  [ARTICLE_STATE_DRAFT]: 'Publish',
  [ARTICLE_STATE_PUBLISHED]: 'Publish',
}
const PUBLISH_BUTTON_TEXT = {
  [INSTANCE_TYPE_BLOG_POST]: PUBLISH_BUTTON_TEXT_BLOG,
  default: PUBLISH_BUTTON_TEXT_ARTICLE,
}
const DELETE_SUCCESS_TEXT = {
  [ARTICLE_STATE_DRAFT]: 'Discarded draft',
  [ARTICLE_STATE_PUBLISHED]: 'Deleted',
}
const UNPUBLISH_BUTTON_TEXT = 'Unpublish'
const CONFIRM_UNPUBLISH_TEXT = {
  [INSTANCE_TYPE_BLOG_POST]: 'Are you sure you want to unpublish this blog?',
  default: 'Are you sure you want to unpublish this article?',
}

const BackgroundContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  marginBottom: theme.spacing(6),
  position: 'fixed',
  left: 0,
  width: '100%',
  zIndex: 1001,
}))

const BackgroundContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  maxWidth: theme.maxWidth,
  margin: '0 auto',
  padding: theme.spacing(1.5, 1),
}))

const useStyles = makeStyles(theme => ({
  addBlockRow: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(-1, -1, 1, -1),
  },
  addBlockTitleTop: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
  },
  addBlockTitleBottom: {
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  toolbarButtons: {
    flex: 1,
    display: 'flex',
    gap: 1,
  },
  savingIndicatorContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  previewToggle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  setThumbnail: {
    marginBottom: theme.spacing(2),
  },
  tagForm: {
    margin: theme.spacing(2, 0),
  },
  tagDivider: {
    paddingTop: theme.spacing(6),
  },
  titleArea: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  title: {
    padding: 0,
    '& .MuiInput-input': {
      color: theme.palette.primary.main,
      ...theme.typography.h1Left,
    },
  },
  tagTitle: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(2),
  },
  titleEdit: {
    flexGrow: 1,
    marginBottom: 1,
  },
}))

const SaveButton = ({
  actionText,
  onClick,
  isLoading,
  permissionAction,
  permissionParams,
}) => {
  const classes = useStyles()
  const isSaving = useSelector(selectArticleIsSaving)
  return (
    <Button
      className={classes.button}
      color="primary"
      size="large"
      onClick={onClick}
      isLoading={isLoading || isSaving}
      permissionAction={permissionAction}
      permissionParams={permissionParams}
    >
      {actionText}
    </Button>
  )
}

export const UnpublishButton = ({ contentType, article, treeSlug }) => {
  const classes = useStyles()
  let { id } = useParams()
  const dispatchDeleteArticle = useActionDispatcher(deleteArticle)
  const dispatchPublishArticle = useActionDispatcher(updateArticle)
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const isBlogTree = useSelector(selectIsBlogTree)
  const history = useHistory()
  const location = useLocation()
  const { treeOverride } = getQueryParams(location)

  const unpublishContent = async () => {
    await dispatchPublishArticle({
      id,
      state: ARTICLE_STATE_DRAFT,
    })
    await dispatchFetchUser()
    if (isBlogTree || treeOverride) {
      if (treeOverride) {
        history.push(`/${treeOverride}/blog/unpublished`)
      } else {
        history.push(`/${treeSlug}/blog/unpublished`)
      }
    } else {
      history.goBack()
    }
  }

  return (
    <ConfirmDialog
      onConfirm={unpublishContent}
      trigger={props => (
        <Button
          permissionAction={ACTION_PUBLISH}
          permissionParams={{
            instance: article,
            instanceType: INSTANCE_TYPE_ARTICLE,
          }}
          className={classes.button}
          color="secondary"
          variant="outlined"
          {...props}
          isLoading={dispatchDeleteArticle.status === 'loading'}
        >
          {UNPUBLISH_BUTTON_TEXT}
        </Button>
      )}
    >
      <Typography style={{ alignSelf: 'center' }}>
        {CONFIRM_UNPUBLISH_TEXT[contentType] ??
          CONFIRM_UNPUBLISH_TEXT['default']}
      </Typography>
    </ConfirmDialog>
  )
}

export const WriteArticle = ({
  article,
  extId,
  onValidationChange,
  config = DEFAULT_WRITE_ARTICLE_CONFIG,
  contentType = INSTANCE_TYPE_ARTICLE,
  blocksEditable = true,
  isLoading,
}) => {
  const classes = useStyles()
  const dispatchCreateBlock = useActionDispatcher(createBlock)
  const dispatchUpdateArticle = useActionDispatcher(updateArticle)
  const dispatchUpdateArticleTitle = useActionDispatcher(updateArticleTitle)
  const { contentBlocks, id, titleDraft, links, dateOfOriginGed, titleHint } =
    article
  const initialValues = {
    title: titleDraft || '',
    dateOfOriginGed: dateOfOriginGed || '',
  }
  const [addBlockAtIndex, setAddBlockAtIndex] = useState(null)
  const [busy, setBusy] = useState(false)
  const [editSourceDialogVisible, setEditSourceDialogVisible] = useState(false)

  useEffect(() => {
    onValidationChange(true)
  }, [onValidationChange])

  const handleSubmitUpdateTitle = useCallback(
    async values => {
      dispatchUpdateArticleTitle({
        id,
        titleDraft: values.title,
        dateOfOriginGed: values.dateOfOriginGed,
      })
    },
    [dispatchUpdateArticleTitle, id]
  )

  const handleShowAddContentBlockModal = useCallback(
    idx => {
      setAddBlockAtIndex(idx)
    },
    [setAddBlockAtIndex]
  )

  const handleDismissAddContentBlockModal = useCallback(() => {
    setAddBlockAtIndex(null)
  }, [setAddBlockAtIndex])

  const [hoveredBlockId, setHoveredBlockId] = useState(null)

  const showControls = useCallback(
    id => setHoveredBlockId(id),
    [setHoveredBlockId]
  )
  const hideControls = useCallback(
    e => {
      // Deal with Safari browser bug where select triggers mouseleave.
      const className = e.target?.className
      if (isString(className) && className.includes('MuiSelect-select')) {
        return
      }
      setHoveredBlockId(null)
    },
    [setHoveredBlockId]
  )

  const handleTagsChanged = useCallback(
    tags => {
      dispatchUpdateArticle({
        id,
      })
    },
    [dispatchUpdateArticle, id]
  )
  const [focusedBlockId, setFocusedBlockId] = useState(null)

  const setFocus = useCallback(id => setFocusedBlockId(id), [setFocusedBlockId])
  const clearFocus = useCallback(
    () => setFocusedBlockId(null),
    [setFocusedBlockId]
  )

  const handleAddAIBlock = useCallback(
    order => {
      setAddBlockAtIndex(null)
      setBusy(false)
      setFocus(order)
    },
    [setAddBlockAtIndex, setBusy, setFocus]
  )

  const handleAddBlock = useCallback(
    async (type, layout = '', order, mediaRowAlignment) => {
      setBusy(true)
      await dispatchCreateBlock({
        id,
        layout: layout,
        order,
        text_content: '',
        type: type,
        mediaRowAlignment,
      }).unwrap()
      setAddBlockAtIndex(null)
      setBusy(false)
      setFocus(order)
    },
    [dispatchCreateBlock, id, setFocus]
  )
  const handleDeleteBlock = useCallback(
    deletedBlockId => {
      let hoverNext = false
      for (let block of contentBlocks) {
        if (hoverNext) {
          setHoveredBlockId(block.id)
          break
        }
        if (block.id === deletedBlockId) {
          hoverNext = true
        }
      }
    },
    [contentBlocks]
  )

  const preventTitleEnterPress = useCallback(e => {
    if (e.code === 'Enter') {
      e.preventDefault()
    }
    return e
  }, [])

  const showEmptyBlocksMessage = contentBlocks.length === 0

  const initialTargets = links.map(link => link.target)

  //const allOrphanedSources = contentBlocks.flatMap((block) => block.sources).filter(src => src && !src.linkedInText)

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {config.inLineEdit ? null : (
          <div
            className={
              config?.inLineEdit ? classes.hiddenTitleArea : classes.titleArea
            }
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmitUpdateTitle}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }) => (
                <>
                  <div className={classes.titleEdit}>
                    <FormikInput
                      className={classes.title}
                      fullWidth
                      label="Title"
                      multiline
                      name="title"
                      onKeyPress={preventTitleEnterPress}
                      placeholder={
                        contentType === INSTANCE_TYPE_DOCUMENT
                          ? 'Type document title...'
                          : titleHint ?? 'Type a title...'
                      }
                    />
                  </div>
                  {contentType === INSTANCE_TYPE_DOCUMENT && (
                    <Box sx={{ mb: 2 }}>
                      <FormikGedDatePicker
                        disabled={isSubmitting}
                        label="Date of Origin"
                        margin="normal"
                        name="dateOfOriginGed"
                        setFieldValue={setFieldValue}
                        fullWidth={false}
                      />
                    </Box>
                  )}
                  <AutoFormSubmitter />
                </>
              )}
            </Formik>
            <ArticleHeaderMeta
              {...article}
              commentCount={article.comments.length}
            />
          </div>
        )}
      </Box>

      {showEmptyBlocksMessage && (
        <>
          <Box sx={{ mt: 8, mb: 8, position: 'relative' }}>
            <HorizontalAddElementControl
              show={true}
              order={0}
              onClick={handleShowAddContentBlockModal}
            />
          </Box>
        </>
      )}
      {blocksEditable && (
        <AddContentBlockModal
          addBlockAtIndex={addBlockAtIndex}
          onAddContentBlock={handleAddBlock}
          onAddAIContentBlock={handleAddAIBlock}
          onCloseModal={handleDismissAddContentBlockModal}
          show={!isNull(addBlockAtIndex)}
          disable={busy}
        />
      )}

      {contentBlocks.map((block, idx) => {
        const { id, type: blockType, layout } = block
        let Block
        if (blockType === 'TEXT') {
          Block = TEXT_BLOCK_LAYOUT_COMPONENTS[layout]
        } else if (blockType === 'MEDIA_ROW') {
          Block = MediaRow
        } else if (blockType === 'SUB_TREE') {
          Block = SubTreeBlock
        } else if (blockType === 'GALLERY') {
          Block = EditableGalleryBlock
        } else if (blockType === 'MAP') {
          Block = MapBlock
        } else if (blockType === 'TOC') {
          Block = TOCBlock
        } else {
          return null
        }
        const isHovered = hoveredBlockId === block.id
        const isFocused =
          focusedBlockId === block.id && !editSourceDialogVisible

        const photoButtons = {} //default all buttons to on unless specified here as false

        if (contentType === INSTANCE_TYPE_DOCUMENT) {
          photoButtons.shadow = false
          photoButtons.borderRadius = false
        }

        //A <Dialog ...> is used to edit each source. If it is mounted inside the SourceLink component, which is itself
        //mounted inside the editor's RichTextField, the textfields inside the dialog keep losing focus to the RichTextField behind it.
        //So instead the Dialog is defined higher up, here, with the Block as a child so it can be passed the function to open the dialog as a prop ('editSource').
        return (
          <Box
            key={id}
            sx={{ position: 'relative', py: 2 }}
            onMouseEnter={() => showControls(block.id)}
            onMouseLeave={hideControls}
          >
            {blocksEditable && (
              <>
                <BlockToolbar
                  contentId={article.id}
                  id={block.id}
                  first={idx === 0}
                  last={idx === contentBlocks.length - 1}
                  show={isHovered || isFocused}
                  onDeleteBlock={handleDeleteBlock}
                  allowDelete={Block !== EditableGalleryBlock} // don't allow removal of the only gallery block on a document
                />
                <HorizontalAddElementControl
                  order={block.order}
                  onClick={handleShowAddContentBlockModal}
                  show={isHovered || isFocused}
                  above
                />
              </>
            )}
            <EditableSourceDialog
              key={id}
              contentBlockId={id}
              contentId={article.id}
              onDialogVisible={setEditSourceDialogVisible}
            >
              {({ editSource }) => (
                <Block
                  idx={idx}
                  isEditMode={true}
                  contentBlocks={contentBlocks}
                  onDialogVisible={setEditSourceDialogVisible}
                  isHovered={isHovered}
                  isFocused={isFocused}
                  onFocus={setFocus}
                  onBlur={clearFocus}
                  contentId={article.id}
                  {...block}
                  presetTargets={initialTargets}
                  editSource={editSource}
                  blocksEditable={blocksEditable}
                  photoButtons={photoButtons}
                  contentType={contentType}
                  addMediaHideTags={false}
                  //addMediaSetCategory={}
                  //mediaDetailEditDialogTitle={}
                  //mediaDetailShowTranscription={}
                  //mediaDetailShowTakenOnDate={}
                  //mediaDetailHideTags={}
                  //mediaDetailDefaultAllowNoTags={}
                />
              )}
            </EditableSourceDialog>
            {blocksEditable && (
              <HorizontalAddElementControl
                order={block.order + 1}
                onClick={handleShowAddContentBlockModal}
                show={isHovered || isFocused}
              />
            )}
          </Box>
        )
      })}

      {/* temp. disabled until 'insert link' can find orphaned sources
      { allOrphanedSources && allOrphanedSources.length > 0 && (
          <Sources title="Currently orphaned sources" sources={allOrphanedSources} contentBlockId={undefined} contentId={article.id} editMode={true} collapsible={true} initiallyExpanded={false} collatePhotos={true} />
      )}
      */}
      <Divider className={classes.tagDivider} />
      {config.showTags && (
        <div className={classes.tagForm}>
          <Typography className={classes.tagTitle}>Tags</Typography>
          <TagForm onChangeTags={handleTagsChanged} subject={article} />
        </div>
      )}
      {!config.hideThumbnail && (
        <div className={classes.setThumbnail}>
          <Typography className={classes.tagTitle}>
            {contentType === INSTANCE_TYPE_DOCUMENT ? 'Document' : 'Article'}{' '}
            Thumbnail
          </Typography>
          <SetThumbnail
            presetTargets={initialTargets}
            article={article}
            id={extId}
          />
        </div>
      )}
    </>
  )
}

export const DEFAULT_WRITE_ARTICLE_CONFIG = {
  inLineEdit: false,
  onInLineSave: undefined,
  showTags: true,
  onDeleteNavigationPageCount: article =>
    article.state === ARTICLE_STATE_DRAFT ? -1 : -2,
}

export const TREE_BLOG_WRITE_ARTICLE_CONFIG = {
  inLineEdit: false,
  onInLineSave: undefined,
  showTags: true,
  showBlogPreview: true,
  saveDraftButton: true,
  onDeleteNavigationPageCount: article =>
    article.state === ARTICLE_STATE_DRAFT ? -1 : -2,
}

export const WRITE_ABOUT_ME_CONFIG = {
  ...DEFAULT_WRITE_ARTICLE_CONFIG,
  inLineEdit: true,
  showTags: false,
  showInLineDelete: true,
  hideThumbnail: true,
}

export const PLAIN_BLOG_WRITE_ARTICLE_CONFIG = {
  inLineEdit: false,
  onInLineSave: undefined,
  showTags: false,
  showBlogPreview: true,
  saveDraftButton: true,
  onDeleteNavigationPageCount: article =>
    article.state === ARTICLE_STATE_DRAFT ? -1 : -2,
}

const getConfig = (
  config,
  treeType,
  alsoPublishToTreeSlug,
  treeOverride,
  pinnedTarget
) => {
  if (config) {
    return config
  } else {
    if (pinnedTarget && treeType === BLOG_TREE) {
      return WRITE_ABOUT_ME_CONFIG
    } else if (alsoPublishToTreeSlug || treeOverride) {
      return TREE_BLOG_WRITE_ARTICLE_CONFIG
    } else if (treeType === BLOG_TREE) {
      return PLAIN_BLOG_WRITE_ARTICLE_CONFIG
    } else {
      return DEFAULT_WRITE_ARTICLE_CONFIG
    }
  }
}

const WriteArticleContainer = ({
  articleId,
  config,
  newContentType = INSTANCE_TYPE_ARTICLE,
  blocksEditable = true,
  onArticleDelete,
}) => {
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()

  let { id, treeSlug } = useParams()

  const { treeTypeOverride, treeOverride } = getQueryParams(location)

  id = id ? id : articleId

  const tree = useSelector(selectTreeBySlug(treeSlug))

  const article = useSelector(selectArticle)
  const { type } = article

  const dispatchCreateArticle = useActionDispatcher(createArticle)
  const dispatchCreateLink = useActionDispatcher(createLink)
  const dispatchFetchArticle = useActionDispatcher(fetchDraftArticle)
  const [isValid, setIsValid] = useState(false)
  const noTagsDialogRef = useRef()
  const { showError } = useNotification()

  const { state, data } = location || {}
  const [presetTargets] = useState(state?.presetTargets || [])

  const [navPath] = useState(state?.navPath || undefined)

  const [pinnedTarget] = useState(state?.pinnedTarget)
  const [onArticleChanged] = useState(data?.onArticleChanged)
  const [visibility] = useState(state?.visibility)
  const [alsoPublishToTreeSlug] = useState(state?.alsoPublishToTreeSlug)

  const treeType = treeTypeOverride || tree?.treeType

  config = getConfig(
    config,
    treeType,
    alsoPublishToTreeSlug,
    treeOverride,
    pinnedTarget
  )

  // Remove first ? char
  const querystring = qs.parse(location.search.substr(1))
  const targetId = querystring.about

  useEffect(() => {
    const doCreateArticle = async () => {
      try {
        var articleTemplateId = null
        const querystring = qs.parse(location.search.substr(1))
        if (querystring) {
          articleTemplateId = querystring['templateId']
        }

        console.log('DEBUG ', state)

        const article = await dispatchCreateArticle(
          {
            type: newContentType,
            state: ARTICLE_STATE_DRAFT,
            title: state?.articleTitle ? state?.articleTitle : '',
            also_publish_to_tree_slug: alsoPublishToTreeSlug,
            force_visibility: visibility,
            targetTreeSlug: treeSlug,
            articleTemplateId: articleTemplateId,
            dynamicPageId: state?.dynamicPageId,
          },
          {
            errorNotification: false,
          }
        ).unwrap()
        await Promise.all(
          presetTargets.map(targetId => {
            if (targetId) {
              return dispatchCreateLink({
                targetTreeSlug: treeSlug,
                subject: article.id,
                target: targetId,
              })
            } else {
              console.error(
                `WriteArticleContainer.doCreateArticle(): presetTargets contains falsy item, not calling dispatchCreateLink(). presetTargets:`,
                presetTargets
              )
              return null
            }
          })
        )

        const path =
          newContentType === INSTANCE_TYPE_DOCUMENT
            ? 'edit-document'
            : 'write-article'

        /*tag the alsoPublishToTreeSlug as a tree override so that it uses the
          menu from this tree in the nav bar as well as other stuff
        */
        const queryString = alsoPublishToTreeSlug
          ? generateTreeOverrideQueryString(alsoPublishToTreeSlug, BLOG_TREE)
          : ''

        history.replace({
          pathname: generateTreeLink(treeSlug, `${path}/${article.id}/`),
          search: queryString,
        })
      } catch (err) {}
    }

    if (!id) {
      doCreateArticle()
    } else {
      if (id) {
        dispatchFetchArticle(
          { id, treeSlug },
          {
            errorNotification: false,
          }
        )
      }
    }
  }, [
    dispatchCreateArticle,
    dispatchCreateLink,
    dispatchFetchArticle,
    history,
    id,
    state,
    targetId,
    presetTargets,
    treeSlug,
    state?.articleTitle,
    newContentType,
    alsoPublishToTreeSlug,
    visibility,
    location.search,
  ])

  const isBlogTree = useSelector(selectIsBlogTree)
  const title = TITLE_STATUS[article.state] || 'Write Article'
  const dispatchDeleteArticle = useActionDispatcher(deleteArticle)
  const dispatchDiscardDraft = useActionDispatcher(discardDraft)
  const dispatchPublishArticle = useActionDispatcher(updateArticle)
  const dispatchPublishNewRevision = useActionDispatcher(publishNewRevision)
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const dispatch = useDispatch()

  const handlePublish = useCallback(
    async (config, allowNoTags = false) => {
      if (!isValid) {
        showError('Form invalid')
        return
      }

      if (config.showTags) {
        if ((article?.links || []).length < 1 && !allowNoTags) {
          noTagsDialogRef.current.showDialog(async () => {
            //call this function again but with allowNoTags=true
            handlePublish(config, (allowNoTags = true))
          })
          return
        }
      }
      let aboutTarget
      let targetToPinTo = pinnedTarget
      if (treeType === BLOG_TREE && pinnedTarget) {
        targetToPinTo = ''
        aboutTarget = pinnedTarget
      }

      const response = await dispatchPublishNewRevision(
        {
          id,
          state: ARTICLE_STATE_PUBLISHED,
          title: window.titleDraft || article.titleDraft,
          type,
          pinned_target: targetToPinTo,
          about_target: aboutTarget,
          thumbnail: article.thumbnailDraft,
        },
        {
          successNotification: article => PUBLISH_SUCCESS_TEXT[article.state],
          errorNotification: article => PUBLISH_ERROR_TEXT[article.state],
        }
      )

      if (response?.meta?.requestStatus === 'fulfilled') {
        await dispatch(resetContent())
        await dispatch(resetPublicContent())
        if (onArticleChanged) {
          await dispatch(onArticleChanged)
        }

        if (config?.inLineEdit && config?.onInLineSave) {
          config.onInLineSave()
        } else {
          if (navPath) {
            history.push(navPath)
          } else {
            if (isBlogTree || treeOverride) {
              if (treeOverride) {
                history.push(`/${treeOverride}/blog/`)
              } else {
                history.push(`/${treeSlug}/blog/`)
              }
            } else {
              history.goBack()
            }
          }
        }
      }
    },
    [
      article.titleDraft,
      article.links,
      article.thumbnailDraft,
      dispatchPublishNewRevision,
      history,
      dispatch,
      id,
      isValid,
      showError,
      type,
      pinnedTarget,
      onArticleChanged,
      navPath,
      treeType,
      isBlogTree,
      treeSlug,
      treeOverride,
    ]
  )

  const handleDelete = useCallback(async () => {
    try {
      const pageCount = config.onDeleteNavigationPageCount(article)
      if (treeOverride && treeOverride !== treeSlug) {
        await dispatchPublishArticle({
          id,
          alsoPublishedIn: article?.alsoPublishedIn?.filter(
            slug => slug !== treeOverride
          ),
        })
      } else {
        await dispatchDeleteArticle(
          { id, treeOverride, treeTypeOverride },
          {
            successNotification: DELETE_SUCCESS_TEXT[article.state],
          }
        ).unwrap()
      }
      if (onArticleChanged) {
        await dispatch(onArticleChanged)
      }
      await dispatchFetchUser()

      if (onArticleDelete) {
        onArticleDelete()
      }
      if (pageCount !== 0) {
        history.go(pageCount)
      }
    } catch (err) {
      console.log(err)
    }
  }, [
    dispatchDeleteArticle,
    history,
    id,
    dispatch,
    onArticleChanged,
    config,
    article,
    onArticleDelete,
    dispatchFetchUser,
    treeOverride,
    treeTypeOverride,
    dispatchPublishArticle,
    treeSlug,
  ])

  const handleDiscardDraft = useCallback(
    async config => {
      try {
        await dispatchDiscardDraft(id, {
          successNotification: 'Draft discarded',
        }).unwrap()
        if (config?.inLineEdit && config.onInLineSave) {
          config.onInLineSave()
        } else {
          history.goBack()
        }
      } catch (err) {}
    },
    [dispatchDiscardDraft, history, id]
  )

  useEffect(() => {
    return () => {
      if (treeOverride) {
        dispatch(clearPhotoOptionsResults(undefined))
      }
    }
  }, [dispatch, treeOverride])

  /*
  hack to add draft title to the window object and load on refresh.  Used in autoformsubmitter to track drafttitle ... uggg.  RGS
   */
  useEffect(() => {
    if (article && window.titleDraft === undefined) {
      window.titleDraft = article.titleDraft
    }
    return () => {
      window.titleDraft = undefined
    }
  }, [article])

  const isSaving = useSelector(selectArticleIsSaving)
  const realContentType =
    treeType === BLOG_TREE ? INSTANCE_TYPE_BLOG_POST : newContentType

  const inlineStyles = {
    position: 'sticky',
    top: theme.headerHeight,
    marginTop: theme.spacing(-4),
    width: '100vw',
    marginLeft: `-${(102 - (theme.maxWidth / window.innerWidth) * 100) / 2}vw`,
  }
  return (
    <>
      {article?.id && article.id === id && (
        <BackgroundContainer style={config.inLineEdit ? inlineStyles : {}}>
          <BackgroundContainerInner>
            <div className={classes.toolbarButtons}>
              <SaveButton
                onClick={() => handlePublish(config)}
                actionText={
                  (PUBLISH_BUTTON_TEXT[realContentType] ??
                    PUBLISH_BUTTON_TEXT['default'])[article.state]
                }
                isLoading={dispatchPublishArticle.status === 'loading'}
                permissionAction={ACTION_PUBLISH}
                permissionParams={{
                  instance: article,
                  instanceType: INSTANCE_TYPE_ARTICLE,
                }}
              ></SaveButton>
              <NoTagsConfirmationDialog
                ref={noTagsDialogRef}
                proceedWithoutTagsText="Publish without tags"
              />
              {!config.inLineEdit && article?.state === 'PUBLISHED' && (
                <UnpublishButton
                  article={article}
                  treeSlug={treeSlug}
                  contentType={realContentType}
                />
              )}
              {(!config.inLineEdit || config?.showInLineDelete) && (
                <ConfirmDialog
                  onConfirm={handleDelete}
                  trigger={props => (
                    <Button
                      permissionAction={ACTION_DELETE}
                      permissionParams={{
                        instance: article,
                        instanceType: INSTANCE_TYPE_ARTICLE,
                      }}
                      className={classes.button}
                      color="secondary"
                      variant="outlined"
                      {...props}
                      isLoading={dispatchDeleteArticle.status === 'loading'}
                    >
                      {
                        (DELETE_BUTTON_TEXT[realContentType] ??
                          DELETE_BUTTON_TEXT['default'])[article.state]
                      }
                    </Button>
                  )}
                >
                  <Typography style={{ alignSelf: 'center' }}>
                    {treeOverride
                      ? CONFIRM_DELETE_TEXT_BLOG[article.state]
                      : realContentType === INSTANCE_TYPE_DOCUMENT
                      ? CONFIRM_DELETE_TEXT_DOCUMENT[article.state]
                      : CONFIRM_DELETE_TEXT[article.state]}
                  </Typography>
                </ConfirmDialog>
              )}
            </div>
            <div>
              <Typography variant="h5" color="error" fontSize={24}>
                {title}
              </Typography>
            </div>
            <div
              className={classes.savingIndicatorContainer}
              style={{ alignItems: 'center', verticalAlign: 'middle' }}
            >
              {isSaving && (
                <>
                  <Typography variant="h5">Saving...&nbsp;</Typography>
                  <CircularProgress size={23} sx={{ mr: 1 }} />
                </>
              )}

              {config.showBlogPreview && (
                <ButtonLink
                  permissionAction={ACTION_ALL_ACCESS}
                  className={classes.button}
                  color="secondary"
                  variant="outlined"
                  to={{
                    pathname: generateBlogPreviewLink(
                      treeSlug,
                      alsoPublishToTreeSlug,
                      id,
                      treeOverride || treeSlug
                    ),
                    state: { isDraft: true },
                  }}
                  isLoading={dispatchDiscardDraft.status === 'loading'}
                >
                  Preview Draft
                </ButtonLink>
              )}
              {config.saveDraftButton && (
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={history.goBack}
                  className={classes.button}
                  color="secondary"
                  variant="outlined"
                  isLoading={dispatchPublishArticle.status === 'loading'}
                >
                  Save Draft
                </Button>
              )}
              {article.state === ARTICLE_STATE_PUBLISHED && (
                <ConfirmDialog
                  onConfirm={() => handleDiscardDraft(config)}
                  trigger={props => (
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      className={classes.button}
                      color="secondary"
                      variant="outlined"
                      {...props}
                      isLoading={dispatchDiscardDraft.status === 'loading'}
                    >
                      Discard Draft
                    </Button>
                  )}
                >
                  <Typography>
                    Discard your changes and revert to the published article?
                  </Typography>
                </ConfirmDialog>
              )}
            </div>
            <div>
              <AiBiographyGeneratorButton actionText="AI Biography Creator" />
            </div>
            <div>
              <AiGeneratorButton
                actionText="AI Ghostwriter"
                isLoading={dispatchDiscardDraft.status === 'loading'}
              ></AiGeneratorButton>
            </div>
          </BackgroundContainerInner>
        </BackgroundContainer>
      )}
      <Container showBackGround={!config.inLineEdit}>
        <Box>
          {dispatchCreateArticle.status === 'error' ? (
            <Typography>Error creating article</Typography>
          ) : dispatchFetchArticle.status === 'error' ? (
            <Typography>Error fetching article</Typography>
          ) : !id ? (
            <Typography>Creating article...</Typography>
          ) : dispatchFetchArticle.status !== 'fulfilled' ? (
            <Typography>Loading article...</Typography>
          ) : article.id === id ? (
            <Box
              sx={{
                pt: config.inLineEdit ? 0 : '80px',
                '& h1, h2, h3, blockquote': {
                  fontFamily: 'IBM Plex Sans',
                },
                '& h4': titleStyles,
                '& h5': subtitleStyles,
                '& blockquote': { ...blockquoteStyles, paddingLeft: 'auto' },
              }}
            >
              <WriteArticleContext.Provider
                value={{ tree, config, treeOverride, treeTypeOverride }}
              >
                <WriteArticle
                  article={article}
                  extId={id}
                  config={config}
                  onValidationChange={setIsValid}
                  contentType={realContentType}
                  blocksEditable={blocksEditable}
                  isLoading={dispatchPublishArticle.status === 'loading'}
                />
              </WriteArticleContext.Provider>
            </Box>
          ) : (
            <Typography>
              <b>
                Error: Article IDs don't match {article.id} != {id}
              </b>
            </Typography>
          )}
        </Box>
      </Container>
    </>
  )
}

export default WriteArticleContainer
