import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { isArray, isUndefined } from 'lodash'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'

import TagField from './TagField'
import {
  searchArtefacts,
  searchEvents,
  searchFamilies,
  searchLocations,
  selectArtefacts,
  selectEvents,
  selectLocations,
  selectFamilies,
  searchIndividuals,
  selectIndividuals,
} from './writeArticleSlice'
import { selectUserIndividualOnTree } from 'src/modules/auth/authSlice'
import {
  selectFamilies as selectAllFamilies,
  selectNodeDirectory,
} from 'src/modules/viewer/viewerSlice'
import { familiesDescendedFrom } from 'src/modules/viewer/api/nodeDirectory'
import {
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_LOCATION,
} from 'src/modules/app/links'
import FamilyTreeTooltip from '../ui/FamilyIdentifierTree'
import { useMediaQuery } from '@mui/material'
import { selectNodes } from '../viewer/viewerSlice'
import { createIndividualsSearchOptions } from '../ui/individualUtils'
import { createFamilySearchOptions } from '../ui/familyUtils'

const useStyles = makeStyles(theme => ({
  tag: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tagWidth: {
    width: `calc(50% - ${theme.spacing(2)})`,
  },
  tagRow: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  tagWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const SUBJECT_TYPE_ANY = 'ANY'

/**
 * If instantiated with an subject, it will create/delete the links to selected
 * objects as they are selected.
 *
 * For other purposes, you can instantiate without an subject, and respond to
 * onChangeTags
 *
 * @param {*} param0
 * @returns
 */
export const TagForm = ({
  className,
  inputVariant,
  onChangeTags = () => {},
  presetTargets = [],
  subject,
  subjectType,
  links = [],
  tagClassName,
  alignTreePreview = 'right',
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const classes = useStyles()
  const artefacts = useSelector(selectArtefacts)
  const events = useSelector(selectEvents)
  const families = useSelector(selectFamilies)
  const locations = useSelector(selectLocations)
  const individuals = useSelector(selectIndividuals)
  const individualNodes = useSelector(selectNodes)
  const nodeDirectory = useSelector(selectNodeDirectory)
  const allFamilies = useSelector(selectAllFamilies)
  const userIndividualOnTree = useSelector(selectUserIndividualOnTree)
  const dispatchSearchFamilies = useActionDispatcher(searchFamilies)
  const dispatchSearchIndividuals = useActionDispatcher(searchIndividuals)
  const dispatchSearchArtefacts = useActionDispatcher(searchArtefacts)
  const dispatchSearchLocations = useActionDispatcher(searchLocations)
  const dispatchSearchEvents = useActionDispatcher(searchEvents)
  const [fireChangeEvent, setFireChangeEvent] = useState(false)
  const [previewFamily, setPreviewFamily] = useState({})
  const [popoverPos, setPopoverPos] = useState({ x: 0, y: 0 })

  if (isUndefined(subjectType)) {
    subjectType = SUBJECT_TYPE_ANY
  }

  if (presetTargets && !isArray(presetTargets)) {
    presetTargets = [presetTargets]
  }

  function selectedTargetsReducer(state, action) {
    if (action.action === 'add') {
      state.add(action.target)
      return new Set(state)
    }
    if (action.action === 'remove') {
      state.delete(action.target)
      return new Set(state)
    }
    return state
  }
  const initialTargets = new Set([
    ...(subject?.links || []).map(link => link.target),
    ...presetTargets,
  ])
  const [selectedTargets, dispatchSelectTarget] = useReducer(
    selectedTargetsReducer,
    initialTargets
  )

  const handleSearchIndividuals = searchValue =>
    dispatchSearchIndividuals(searchValue)
  const handleSearchFamilies = searchValue =>
    dispatchSearchFamilies(searchValue)
  const handleSearchArtefacts = searchValue =>
    dispatchSearchArtefacts(searchValue)
  const handleSearchLocations = searchValue =>
    dispatchSearchLocations(searchValue)
  const handleSearchEvents = searchValue => dispatchSearchEvents(searchValue)

  let individualOptions = individuals?.map(i => {
    return { id: i.id, display: `${i.display} (${i.description})` }
  })

  let allIndividualOptions = createIndividualsSearchOptions(individualNodes)
  let allFamiliyOptions = createFamilySearchOptions(allFamilies)

  let descendedFamilies = []
  if (userIndividualOnTree) {
    descendedFamilies = familiesDescendedFrom(
      nodeDirectory,
      userIndividualOnTree,
      allFamilies
    )
  }

  const tagClassNameString = clsx({
    [classes.tag]: true,
    [classes.tagWidth]: !tagClassName,
    tagClassName,
  })

  const handleSelectTag = useCallback(
    target => {
      dispatchSelectTarget({ action: 'add', target })
      setFireChangeEvent(true)
    },
    [dispatchSelectTarget, setFireChangeEvent]
  )
  const handleDeselectTag = useCallback(
    target => {
      dispatchSelectTarget({ action: 'remove', target })
      setFireChangeEvent(true)
    },
    [dispatchSelectTarget, setFireChangeEvent]
  )

  const descendedFromFamilyIds = new Set(
    descendedFamilies.map(({ family }) => family)
  )

  useEffect(() => {
    if (fireChangeEvent) {
      onChangeTags([...selectedTargets])
      setFireChangeEvent(false)
    }
  }, [selectedTargets, setFireChangeEvent, onChangeTags, fireChangeEvent])

  return (
    <div
      className={clsx({
        [classes.tagRow]: true,
        [classes.tagWrap]: !className,
        className,
      })}
    >
      {!mobileBreakpoint && (
        <FamilyTreeTooltip
          option={previewFamily}
          setPreviewFamily={setPreviewFamily}
          popoverPos={popoverPos}
          alignTreePreview={alignTreePreview}
        />
      )}
      {![INSTANCE_TYPE_INDIVIDUAL].includes(subjectType) && (
        <TagField
          subject={subject}
          className={tagClassNameString}
          label="Families"
          secondaryLabel="(hover to see preview)"
          linkType={INSTANCE_TYPE_FAMILY}
          presetTargets={presetTargets}
          onSearch={handleSearchFamilies}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          options={families}
          allOptions={allFamiliyOptions}
          links={links}
          highlightedOptionIDs={descendedFromFamilyIds}
          variant={inputVariant}
          setPreviewFamily={setPreviewFamily}
          setPopoverPos={setPopoverPos}
          alignTreePreview={alignTreePreview}
        />
      )}
      {![INSTANCE_TYPE_INDIVIDUAL].includes(subjectType) && (
        <TagField
          subject={subject}
          className={tagClassNameString}
          enableFuzzyFilter={true}
          label="Individuals"
          linkType={INSTANCE_TYPE_INDIVIDUAL}
          presetTargets={presetTargets}
          allOptions={allIndividualOptions}
          onSearch={handleSearchIndividuals}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          links={links}
          options={individualOptions}
          variant={inputVariant}
        />
      )}
      {[INSTANCE_TYPE_INDIVIDUAL].includes(subjectType) && (
        <TagField
          subject={subject}
          className={tagClassNameString}
          enableFuzzyFilter={true}
          label="FANs (Friends & Neighbors)"
          linkType={INSTANCE_TYPE_INDIVIDUAL}
          presetTargets={presetTargets}
          allOptions={allIndividualOptions}
          onSearch={handleSearchIndividuals}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          links={links}
          options={individualOptions}
          variant={inputVariant}
        />
      )}
      {[
        SUBJECT_TYPE_ANY,
        INSTANCE_TYPE_EVENT,
        INSTANCE_TYPE_LOCATION,
        INSTANCE_TYPE_INDIVIDUAL,
      ].includes(subjectType) && (
        <TagField
          subject={subject}
          className={tagClassNameString}
          createInPlace={true}
          label="Artefacts"
          linkType={INSTANCE_TYPE_ARTEFACT}
          links={links}
          presetTargets={presetTargets}
          onSearch={handleSearchArtefacts}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          options={artefacts}
          allOptions={artefacts}
          variant={inputVariant}
        />
      )}
      {[
        SUBJECT_TYPE_ANY,
        INSTANCE_TYPE_EVENT,
        INSTANCE_TYPE_ARTEFACT,
        INSTANCE_TYPE_LOCATION,
        INSTANCE_TYPE_INDIVIDUAL,
      ].includes(subjectType) && (
        <TagField
          subject={subject}
          className={tagClassNameString}
          createInPlace={true}
          label="Other"
          linkType={INSTANCE_TYPE_EVENT}
          presetTargets={presetTargets}
          links={links}
          onSearch={handleSearchEvents}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          options={events}
          allOptions={events}
          variant={inputVariant}
        />
      )}
      {[
        SUBJECT_TYPE_ANY,
        INSTANCE_TYPE_ARTEFACT,
        INSTANCE_TYPE_LOCATION,
        INSTANCE_TYPE_INDIVIDUAL,
      ].includes(subjectType) && ( // events/occasions get their own special 'Places' field added below tags in CreateOrUpdateLinkedPageDialog (?)
        <TagField
          subject={subject}
          className={tagClassNameString}
          createInPlace={true}
          label="Places"
          linkType={INSTANCE_TYPE_LOCATION}
          presetTargets={presetTargets}
          onSearch={handleSearchLocations}
          links={links}
          onSelectTag={handleSelectTag}
          onDeselectTag={handleDeselectTag}
          options={locations}
          allOptions={locations}
          variant={inputVariant}
        />
      )}
    </div>
  )
}

export default TagForm
