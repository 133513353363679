import { EditTreeName } from './EditTreeName'
import React from 'react'

import { Button, Typography } from '../ui'
import { Formik } from 'formik'
import { Box, Card, CardActions } from '@mui/material'
import {
  ACTION_CREATE,
  FOCUS_SPECIAL_INTEREST_ARCHIVE,
} from '../app/appConstants'
import { INSTANCE_TYPE_TREE } from '../app/links'
import * as Yup from 'yup'
import { createTreeWithFocus } from './treesSlice'
import { useActionDispatcher, useSetTree } from '../app'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { useHistory } from 'react-router-dom'
import { fetchUser } from '../auth/authSlice'
import TreeVisibilityFormControl from './TreeVisibilityFormControl'
import {
  CreateArchiveContainer,
  CreateArchiveCardContent,
  CreateArchiveCardElement,
} from './CreateOnePlaceStudy'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Name is required'),
})

const CreateSpecialInterestArchive = ({ focusHome }) => {
  const dispatchCreateTreeWithFocus = useActionDispatcher(createTreeWithFocus)
  const history = useHistory()
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const setTree = useSetTree()

  const initialValues = {
    title: undefined,
    address: undefined,
    visibility: VISIBILITY_PUBLIC,
  }

  const handleSubmit = async values => {
    const response = await dispatchCreateTreeWithFocus({
      archiveFocus: FOCUS_SPECIAL_INTEREST_ARCHIVE,
      archiveFocusHome: focusHome,
      title: values.title,
      visibility: values.visibility,
      address: values.address,
    })
    await dispatchFetchUser()
    await setTree(response?.payload?.slug)

    const url = `/${response?.payload?.slug}/complete-special-interest-profile`

    history.push(url)
  }

  return (
    <CreateArchiveContainer>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h1" color="primary">
          {focusHome} Archive
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ touched, errors, handleSubmit, isSubmitting, values }) => (
          <Card>
            <CreateArchiveCardContent>
              <CreateArchiveCardElement>
                <Typography color="textSecondary" variant="subtitle1">
                  1. NAME YOUR {focusHome.toUpperCase()} ARCHIVE
                </Typography>
                <EditTreeName
                  stopOnTreeNameChangedIfCollision={true}
                  onTreeNameChanged={name => (values.title = name)}
                />
                {errors.title && touched.title && (
                  <div style={{ color: 'red' }}>{errors.title}</div>
                )}
              </CreateArchiveCardElement>
              <CreateArchiveCardElement>
                <Typography
                  color="textSecondary"
                  variant="subtitle1"
                  sx={{ marginBottom: '1rem' }}
                >
                  3. VISIBILITY
                </Typography>
                <TreeVisibilityFormControl
                  defaultVisibility={VISIBILITY_PUBLIC}
                  onChange={visibility => (values.visibility = visibility)}
                />
              </CreateArchiveCardElement>
            </CreateArchiveCardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={isSubmitting}
                permissionAction={ACTION_CREATE}
                permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                onClick={handleSubmit}
                color="primary"
              >
                Next
              </Button>
            </CardActions>
          </Card>
        )}
      </Formik>
    </CreateArchiveContainer>
  )
}

export default CreateSpecialInterestArchive
