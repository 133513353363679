import React, { useEffect } from 'react'

import { useRouteMatch } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import { NavLink } from 'src/modules/ui'

import { useTreeLink } from 'src/modules/app/links'

import { Individuals } from './index'
import PageFamilies from './PageFamilies'
import { useDynamicPageTabHelpers } from 'src/modules/dynamicPage/dynamicPageHooks'
import { DynamicPageTabContext } from 'src/modules/dynamicPage/dynamicPageContexts'

const INDIVIDUAL_TAB_NUMBER = 0
const FAMILY_TAB_NUMBER = 1

const TabPanel = ({ children, tabIndex, tabNumber }) => {
  if (tabIndex === tabNumber) {
    return <div>{children}</div>
  } else {
    return null
  }
}

const PagePeople = ({ dynamicPageTypeTabIndex }) => {
  const {
    params: { pageType, linkedPageId, peoplePathName },
  } = useRouteMatch('/:slug/:pageType/:linkedPageId/:peoplePathName')
  const params = useDynamicPageTabHelpers({ dynamicPageTypeTabIndex })
  const INDIVIDUAL_PATH = `${peoplePathName}/all`
  const FAMILY_PATH = `${peoplePathName}/families`
  const PEOPLE_ROOT_PATH = peoplePathName

  const peopleRootRoute = useTreeLink(
    `${pageType}/${linkedPageId}/${PEOPLE_ROOT_PATH}`
  )
  const individualRoute = useTreeLink(
    `${pageType}/${linkedPageId}/${INDIVIDUAL_PATH}`
  )
  const familyRoute = useTreeLink(`${pageType}/${linkedPageId}/${FAMILY_PATH}`)

  const matchPeopleRootRoute = useRouteMatch(peopleRootRoute)
  const matchIndividualRoute = useRouteMatch(individualRoute)
  const matchFamilyRoute = useRouteMatch(familyRoute)

  const [tabIndex, setTabIndex] = React.useState(INDIVIDUAL_TAB_NUMBER)

  useEffect(() => {
    if (matchPeopleRootRoute?.isExact) {
      setTabIndex(INDIVIDUAL_TAB_NUMBER)
    } else if (matchIndividualRoute?.isExact) {
      setTabIndex(INDIVIDUAL_TAB_NUMBER)
    } else if (matchFamilyRoute?.isExact) {
      setTabIndex(FAMILY_TAB_NUMBER)
    } else {
      console.log('DEBUG nomatch')
    }
  }, [
    setTabIndex,
    matchPeopleRootRoute,
    matchIndividualRoute,
    matchFamilyRoute,
  ])

  return (
    <DynamicPageTabContext.Provider value={params}>
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Box>
            <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
              <NavLink
                isActive={() => tabIndex === INDIVIDUAL_TAB_NUMBER}
                to={individualRoute}
              >
                Individuals
              </NavLink>
              <NavLink
                isActive={() => tabIndex === FAMILY_TAB_NUMBER}
                to={familyRoute}
              >
                Families
              </NavLink>
            </Stack>
          </Box>
        </Stack>

        <TabPanel tabIndex={tabIndex} tabNumber={INDIVIDUAL_TAB_NUMBER}>
          <Individuals />
        </TabPanel>
        <TabPanel tabIndex={tabIndex} tabNumber={FAMILY_TAB_NUMBER}>
          <PageFamilies />
        </TabPanel>
      </div>
    </DynamicPageTabContext.Provider>
  )
}

export default PagePeople
