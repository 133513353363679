import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link as MuiLink } from '@mui/material'
import {
  Box,
  Badge,
  IconButton as MuiIconButton,
  Tooltip,
  Menu,
  MenuItem,
  useMediaQuery,
  styled,
} from '@mui/material'

import NotificationsIcon from '@mui/icons-material/Notifications'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import { makeStyles, useTheme } from '@mui/styles'

import Auth from '@aws-amplify/auth'

import { selectUser } from 'src/modules/auth/authSlice'
import ProfilePicture from 'src/modules/content/ProfilePicture'
import { AppBar } from 'src/modules/ui'

import Notifications from './Notifications'
import Bookmarks from './Bookmarks'
import NavigatorViewerWithPyramid from 'src/modules/viewer/NavigatorViewerWithPyramid'
import AncestralFamiliesContainer from 'src/modules/home/AncestralFamiliesContainer'
import {
  generateBlogHomeLink,
  generateTreeHomeLink,
  generateTreeLink,
} from './links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import MobileNavBar from './MobileNavBar'
import { HelpPage } from 'src/modules/help/HelpPage'
import { Assistant } from 'src/modules/goals'
import { useHistory } from 'react-router-dom'
import {
  notificationTimerStop,
  selectSideBarVisible,
  setLastLocation,
  setSideBarVisible,
} from './appSlice'
import AddContentDialog from './AddContentDialog'
import { Button } from '../ui'
import ButtonLink from '../ui/ButtonLink'
import { selectIsBlogTree, selectNonDemoTrees } from '../auth/authSlice'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { BLOG_TREE, getTreeType, USER_TREE } from '../common/constants'
import { NavBarContext } from './contexts'
import {
  ACTION_ALL_ACCESS,
  ACTION_INVITE_USERS,
  STOP_SHOWING_SIDEBAR_KEY,
} from './appConstants'
import { usePermissions } from '../auth/authHooks'
import PsychologyIcon from '@mui/icons-material/Psychology'
import { useLocalStorage } from './useLocalStorageHook'
import { useTreeSettings } from './hooks'
import { FOCUS_SPECIAL_INTEREST_ARCHIVE } from 'src/modules/app/appConstants'
export const Logo = styled('img')({
  display: 'block',
  width: 240,
})

export const useStyles = makeStyles(theme => ({
  navBarLinkStyle: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(3),
    cursor: 'pointer',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navBarButtonStyle: {
    marginLeft: theme.spacing(4),
  },
  menuLinkStyle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  specialNavButton: {
    backgroundImage:
      'linear-gradient(135deg,rgb(243,64,121) 40%,rgb(252,137,77))',
  },
}))

const CreateYourTreeButton = () => {
  const classes = useStyles()
  const nonDemoTrees = useSelector(selectNonDemoTrees)

  return (
    <>
      {nonDemoTrees.length === 0 && (
        <Box
          className={classes.navBarButtonStyle}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <ButtonLink
            permissionAction={ACTION_ALL_ACCESS}
            className={classes.specialNavButton}
            color="primary"
            size="large"
            sx={{ whiteSpace: 'nowrap' }}
            to={'/create-tree'}
          >
            Create your own archive
          </ButtonLink>
        </Box>
      )}
    </>
  )
}

const MenuLogo = () => {
  const { authorisedTreeSlug } = useContext(NavBarContext)

  return (
    <>
      <Link to={generateTreeHomeLink(authorisedTreeSlug)}>
        <Logo alt="weare.xyz" src="/logo.png" />
      </Link>
    </>
  )
}

const MenuMobileNavBar = () => {
  const { user, authorisedTreeSlug } = useContext(NavBarContext)

  return (
    <>
      {user?.currentTree ? (
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <MobileNavBar
            homeUrl={generateTreeHomeLink(authorisedTreeSlug)}
            trigger={navBarTrigger => (
              <MuiIconButton
                onClick={navBarTrigger.onClick}
                color="inherit"
                sx={{ mr: 1 }}
              >
                <MenuIcon />
              </MuiIconButton>
            )}
          />
        </Box>
      ) : null}
    </>
  )
}

const MenuHomeLink = ({ forTreeType }) => {
  const { treeType } = useContext(NavBarContext)
  const classes = useStyles()
  const { authorisedTreeSlug } = useContext(NavBarContext)
  return (
    <>
      {treeType === forTreeType && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Link
            to={generateTreeHomeLink(authorisedTreeSlug)}
            className={classes.navBarLinkStyle}
          >
            Home
          </Link>
        </Box>
      )}
    </>
  )
}

const MenuBlogHomeLink = ({ forTreeType }) => {
  const { treeType } = useContext(NavBarContext)
  const classes = useStyles()
  const { authorisedTreeSlug } = useContext(NavBarContext)
  const to = generateBlogHomeLink(authorisedTreeSlug)
  return (
    <>
      {treeType === forTreeType && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Link to={to} className={classes.navBarLinkStyle}>
            Blog-Home
          </Link>
        </Box>
      )}
    </>
  )
}

const MenuTreesLink = () => {
  const classes = useStyles()
  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Link to="/tree-admin" className={classes.navBarLinkStyle}>
        Archives
      </Link>
    </Box>
  )
}

const MenuBlogLink = () => {
  const classes = useStyles()
  const showFeature = useIsAlphaFeatureUser()?.blog

  return (
    <>
      {showFeature && (
        <Box sx={{ display: { xs: 'none', md: 'block' }, mr: 1 }}>
          <Link to="/blog-admin" className={classes.navBarLinkStyle}>
            Blogs
          </Link>
        </Box>
      )}
    </>
  )
}

const MenuFamiliesLink = ({ forTreeType }) => {
  const { treeType, user } = useContext(NavBarContext)
  const classes = useStyles()
  const { showFamilyPyramid } = useTreeSettings()

  const showFeature = !useIsAlphaFeatureUser()?.exportMode
  if (!showFeature || !showFamilyPyramid) {
    return <></>
  }

  return (
    <>
      {treeType === forTreeType && user?.currentTree && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <AncestralFamiliesContainer
            trigger={familiesTrigger => (
              <MuiLink
                onClick={familiesTrigger.onClick}
                className={classes.navBarLinkStyle}
              >
                <span className={'joyride_first_time_user_families'}>
                  Families
                </span>
              </MuiLink>
            )}
          />
        </Box>
      )}
    </>
  )
}

const MenuNavigatorLink = ({ forTreeType }) => {
  const [openExplore, setOpenExplore] = useState(false)
  const { treeType, user } = useContext(NavBarContext)
  const { showNavigator } = useTreeSettings()

  const classes = useStyles()

  const showFeature = !useIsAlphaFeatureUser()?.exportMode
  if (!showFeature || !showNavigator) {
    return <></>
  }
  return (
    <>
      {treeType === forTreeType && user?.currentTree && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <MuiLink
            onClick={() => setOpenExplore(true)}
            className={classes.navBarLinkStyle}
          >
            <span className={'joyride_first_time_user_navigator'}>
              Navigator
            </span>
          </MuiLink>
          <NavigatorViewerWithPyramid
            openExplore={openExplore}
            setOpenExplore={setOpenExplore}
          />
        </Box>
      )}
    </>
  )
}

const MenuHelpLink = () => {
  const classes = useStyles()

  const showFeature = !useIsAlphaFeatureUser()?.exportMode
  if (!showFeature) {
    return <></>
  }

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <HelpPage
        trigger={helpTrigger => (
          <MuiLink
            onClick={helpTrigger.onClick}
            className={classes.navBarLinkStyle}
          >
            <span className={'joyride_first_time_user_help'}>Help</span>
          </MuiLink>
        )}
      />
    </Box>
  )
}

const MenuCreateContentLink = ({ forTreeType }) => {
  const { treeType, user } = useContext(NavBarContext)
  const classes = useStyles()

  const { archiveFocus } = useTreeSettings()

  const showFeature =
    !useIsAlphaFeatureUser()?.exportMode &&
    archiveFocus !== FOCUS_SPECIAL_INTEREST_ARCHIVE

  if (!showFeature) {
    return <></>
  }

  return (
    <>
      {treeType === forTreeType && user?.currentTree && (
        <Box
          className={classes.navBarButtonStyle}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <AddContentDialog
            trigger={props => (
              <Button
                className={'joyride_first_time_user_addcontent'}
                color="primary"
                size="large"
                permissionAction={ACTION_ALL_ACCESS}
                {...props}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Add Content
              </Button>
            )}
          />
        </Box>
      )}
    </>
  )
}

const MenuAssistantLink = () => {
  const exportMode = useIsAlphaFeatureUser()?.exportMode
  const showFeature = useIsAlphaFeatureUser()?.assistant && !exportMode
  const user = useSelector(selectUser)
  return (
    <>
      {!!user?.currentTree?.currentUserIsAdmin && showFeature && (
        <Assistant></Assistant>
      )}
    </>
  )
}

const MenuBookMarksLink = () => {
  const { mobileBreakpoint } = useContext(NavBarContext)
  return (
    <>
      <Bookmarks
        trigger={bookmarksTrigger => (
          <Tooltip title="Bookmarks">
            <MuiIconButton color="inherit" onClick={bookmarksTrigger.onClick}>
              <BookmarksIcon
                fontSize={mobileBreakpoint ? 'small' : 'medium'}
                sx={{
                  mr: { xs: -1 },
                }}
              />
            </MuiIconButton>
          </Tooltip>
        )}
      />
    </>
  )
}

const MenuNotificationsLink = ({ unreadNotifications }) => {
  const { mobileBreakpoint } = useContext(NavBarContext)
  return (
    <>
      <Notifications
        trigger={notificationsTrigger => (
          <Tooltip title="Notifications">
            <MuiIconButton
              color="inherit"
              onClick={notificationsTrigger.onClick}
            >
              <Badge color="primary" badgeContent={unreadNotifications}>
                <NotificationsIcon
                  fontSize={mobileBreakpoint ? 'small' : 'medium'}
                  sx={{
                    mr: { xs: -1 },
                  }}
                />
              </Badge>
            </MuiIconButton>
          </Tooltip>
        )}
      />
    </>
  )
}

const SideDropDownMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch()
  const { authorisedTreeSlug } = useContext(NavBarContext)
  const isBlogTree = useSelector(selectIsBlogTree)

  const handleLogOut = useCallback(async () => {
    dispatch(notificationTimerStop())
    await Auth.signOut()
    window.location.replace('/')
  }, [dispatch])

  const { archiveFocus } = useTreeSettings()
  const checkPermissions = usePermissions()
  const { hasPermission } = checkPermissions(ACTION_INVITE_USERS, {})

  const showFeature = useIsAlphaFeatureUser()?.assistant
  const userLinks = useMemo(() => {
    let links = []
    if (hasPermission && archiveFocus !== FOCUS_SPECIAL_INTEREST_ARCHIVE) {
      links.push({
        text: 'Invite',
        to: generateTreeLink(authorisedTreeSlug, 'user-admin'),
      })
      if (showFeature) {
        links.push({
          text: 'Goals Report',
          to: generateTreeLink(authorisedTreeSlug, 'goals'),
        })
      }
    }
    links.push({
      to: '/personal-settings',
      text: 'Personal Settings',
    })
    if (authorisedTreeSlug && !isBlogTree) {
      links = links.concat([
        {
          to: generateTreeLink(authorisedTreeSlug, 'my-articles'),
          text: 'My Articles',
        },
        {
          to: generateTreeLink(authorisedTreeSlug, 'my-articles'),
          text: '- Published',
        },
        {
          to: generateTreeLink(authorisedTreeSlug, 'my-articles/drafts'),
          text: '- Unpublished',
        },
        {
          to: generateTreeLink(authorisedTreeSlug, 'my-media'),
          text: 'My Media',
        },
      ])
    }

    if (archiveFocus !== FOCUS_SPECIAL_INTEREST_ARCHIVE) {
      links.push({
        to: generateTreeLink(authorisedTreeSlug, 'my-ask-family-requests'),
        text: 'My Ask Family Requests',
      })
    }

    links.push({
      onClick: () => window.FreshworksWidget('open'),
      text: 'Send Feedback',
    })
    links.push({
      onClick: handleLogOut,
      text: 'Log Out',
    })
    return links
  }, [
    authorisedTreeSlug,
    showFeature,
    isBlogTree,
    handleLogOut,
    hasPermission,
    archiveFocus,
  ])

  return (
    <Menu
      sx={{ padding: '0px' }}
      MenuListProps={{ sx: { py: 0 } }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {userLinks.map((link, idx) => (
        <MenuLink {...link} key={idx} onCloseMenu={handleClose} />
      ))}
    </Menu>
  )
}

const StyledPsychologyIcon = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 35, // Set width and height to create a circle
        height: 35,
        borderRadius: '50%',
        backgroundColor: theme.palette.secondaryGrey.main, // Set your desired background color here
      }}
    >
      <PsychologyIcon sx={{ color: 'white' }} />{' '}
      {/* Icon color can be adjusted here */}
    </Box>
  )
}

const GenePoolLink = () => {
  const dispatch = useDispatch()
  const sideBarVisible = useSelector(selectSideBarVisible)
  const { update } = useLocalStorage(STOP_SHOWING_SIDEBAR_KEY, {
    stopShowingSideBar: false,
  })
  const { archiveFocus } = useTreeSettings()
  const showAiWizard =
    useIsAlphaFeatureUser()?.aiWizard &&
    archiveFocus !== FOCUS_SPECIAL_INTEREST_ARCHIVE

  const handleClick = () => {
    update('stopShowingSideBar', false)
    dispatch(setSideBarVisible(!sideBarVisible))
  }

  if (!showAiWizard) {
    return null
  }

  return (
    <Tooltip title="Help Sidebar">
      <MuiIconButton color="inherit" onClick={handleClick}>
        {sideBarVisible && <StyledPsychologyIcon />}
        {!sideBarVisible && <PsychologyIcon />}
      </MuiIconButton>
    </Tooltip>
  )
}

const NavBar = ({
  unreadNotifications,
  authorisedTreeSlugOverride,
  treeTypeOverride,
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const authorisedTreeSlugFromRedux = useSelector(selectAuthorisedTreeSlug)
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector(selectUser)
  const history = useHistory()
  const dispatch = useDispatch()
  const treeTypeFromUser = getTreeType(user)

  const authorisedTreeSlug =
    authorisedTreeSlugOverride || authorisedTreeSlugFromRedux
  const treeType = treeTypeOverride || treeTypeFromUser

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const pathname = history.location.pathname
    return () => {
      dispatch(setLastLocation(pathname))
    }
  }, [dispatch, history.location.pathname])

  return (
    <>
      <NavBarContext.Provider
        value={{ mobileBreakpoint, user, authorisedTreeSlug, treeType }}
      >
        <AppBar
          leftSide={
            <>
              {mobileBreakpoint && <MenuMobileNavBar />}
              <MenuLogo />
              <MenuHomeLink forTreeType={USER_TREE} />
              {!['/create-tree', '/onboarding'].includes(
                history.location.pathname
              ) && (
                <>
                  <MenuBlogHomeLink forTreeType={BLOG_TREE} />
                  <MenuFamiliesLink forTreeType={USER_TREE} />
                  <MenuNavigatorLink forTreeType={USER_TREE} />
                  <MenuHelpLink />
                  <MenuCreateContentLink forTreeType={USER_TREE} />
                  <CreateYourTreeButton forTreeType={USER_TREE} />
                </>
              )}
            </>
          }
          rightSide={
            <>
              <MenuTreesLink />
              <MenuBlogLink />
              {user.currentTree ? (
                <>
                  <MenuAssistantLink />
                  <MenuBookMarksLink />
                  <MenuNotificationsLink
                    unreadNotifications={unreadNotifications}
                  />
                </>
              ) : null}
              <MuiIconButton color="inherit" onClick={handleClick}>
                <User user={user} />
                <KeyboardArrowDownIcon />
              </MuiIconButton>
              <GenePoolLink />
            </>
          }
        />
        <SideDropDownMenu anchorEl={anchorEl} handleClose={handleClose} />
      </NavBarContext.Provider>
    </>
  )
}

const UserRoot = styled('div')({
  alignItems: 'center',
  display: 'flex',
})

const User = ({ user }) => {
  if (!user) {
    return null
  }

  return (
    <UserRoot>
      <ProfilePicture user={user} size={30} sx={{ mr: 1 }} />
      <Box sx={{ display: { xs: 'none', md: 'block' } }}></Box>
    </UserRoot>
  )
}

const RouterLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
})

const Underlined = styled('span', {
  shouldForwardProp: prop => !['hover'].includes(prop),
})(({ theme, hover = false }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    backgroundColor: theme.palette.text.primary,
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 1,
    opacity: 0,
    transition: 'opacity 0.5s',
    ...(hover && {
      opacity: 1,
    }),
  },
}))

const MenuLink = React.forwardRef(
  ({ onClick, onCloseMenu, to, text, ...props }, ref) => {
    const [hover, setHover] = useState(false)

    const handleClick = () => {
      onClick && onClick()
      onCloseMenu()
    }

    const handleMouseEnter = () => setHover(true)
    const handleMouseLeave = () => setHover(false)

    if (onClick) {
      return (
        <MenuItem
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={ref}
          {...props}
        >
          <Underlined hover={hover}>{text}</Underlined>
        </MenuItem>
      )
    } else {
      return (
        <RouterLink to={to}>
          <MenuItem
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={ref}
            {...props}
          >
            <Underlined hover={hover}>{text}</Underlined>
          </MenuItem>
        </RouterLink>
      )
    }
  }
)

export default NavBar
