import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FACT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MAP,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_TREE,
  useTreeLink,
} from '../app/links'
import { styled } from '@mui/system'
import SiteWideIcon from './SiteWideIcon'
import { formatIndividualName } from './individualUtils'
import useAddIndividualControl from '../page/useAddIndividualControl'
import AddLinkedPageControl from '../page/AddLinkedPageControl'
import AddAlbumControl from '../page/AddAlbumControl'
import { PageContext } from '../page/pageContexts'
import AddMediaControl from '../page/AddMediaControl'
import { AddStoryButton, UploadDocumentButton } from './actionButtons'
import { useSelector } from 'react-redux'
import { selectIsBlogTree } from '../auth/authSlice'
import { useTheme } from '@mui/styles'
import AddMapControl from '../page/AddMapControl'
import { useIsPublic } from '../app'
import { DynamicPageTabContext } from 'src/modules/dynamicPage/dynamicPageContexts'
import { getDynamicNameVariants } from '../dynamicPage/dynamicPageHookUtils'

const EmptyFeed = ({ type, message = 'Nothing added yet...' }) => {
  let content

  const pageContextParams = useContext(PageContext)
  const dynamicPageTabContext = useContext(DynamicPageTabContext)

  const isPublic = useIsPublic()
  if (isPublic) {
    return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography color="textSecondary">{message}</Typography>
      </Box>
    )
  }

  switch (type) {
    case INSTANCE_TYPE_INDIVIDUAL:
      content = (
        <IndividualEmptyState
          {...pageContextParams}
          {...dynamicPageTabContext}
        />
      )
      break
    case INSTANCE_TYPE_ARTEFACT:
    case INSTANCE_TYPE_EVENT:
    case INSTANCE_TYPE_LOCATION:
      content = (
        <LinkedPageEmptyState
          type={type}
          {...pageContextParams}
          {...dynamicPageTabContext}
        />
      )
      break
    case INSTANCE_TYPE_PHOTO_ALBUM:
      content = (
        <AlbumsEmptyState {...pageContextParams} {...dynamicPageTabContext} />
      )
      break
    case INSTANCE_TYPE_MEDIA:
      content = content = (
        <MediaEmptyState {...pageContextParams} {...dynamicPageTabContext} />
      )
      break
    case INSTANCE_TYPE_MAP:
      content = content = (
        <MapEmptyState {...pageContextParams} {...dynamicPageTabContext} />
      )
      break
    case INSTANCE_TYPE_DOCUMENT:
    case INSTANCE_TYPE_ARTICLE:
      content = (
        <ContentEmptyState
          type={type}
          {...pageContextParams}
          {...dynamicPageTabContext}
        />
      )
      break
    case INSTANCE_TYPE_FACT:
      content = (
        <EmptyStateWithIcon
          instanceType={INSTANCE_TYPE_FACT}
          title={'No Facts found!'}
          bodyText={
            'Facts are key events in a person’s life. e.g Birth, Death, Marriage, etc.'
          }
          {...dynamicPageTabContext}
        />
      )
      break
    default:
      content = (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography color="textSecondary">{message}</Typography>
        </Box>
      )
  }

  return content
}

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50%',
  maxWidth: '80%',
  textAlign: 'center',
  padding: theme.spacing(4),
  margin: 'auto',
  gap: 4,
  marginTop: theme.spacing(8),
}))

const EmptyStateImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.lightGrey,
  borderRadius: '50%',
  maxWidth: '400px',
}))

const EmptyStateBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: 20,
}))

const EmptyStateBodyText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  gap: 4,
  maxWidth: '600px',
}))

export const getName = (pageObject, pageType) => {
  switch (pageType) {
    case INSTANCE_TYPE_INDIVIDUAL:
      return formatIndividualName(pageObject)
    case INSTANCE_TYPE_TREE:
      return `the ${pageObject?.name} archive`
    case INSTANCE_TYPE_FAMILY:
      return pageObject?.surname ? `the ${pageObject?.surname} Family` : ''
    default:
      return pageObject?.title
  }
}

export const EmptyStateWithIcon = ({
  instanceType,
  title,
  bodyText,
  action,
  actionText,
}) => {
  const theme = useTheme()

  const iconColor = theme.palette.primary.main

  return (
    <EmptyStateContainer>
      <EmptyStateBody>
        <EmptyStateImageContainer>
          <SiteWideIcon
            instanceType={instanceType}
            fontSize={'large'}
            sx={{ width: 200, height: 200, color: iconColor }}
          />
        </EmptyStateImageContainer>
        <EmptyStateBodyText>
          <Typography variant="h3">{title}</Typography>
          {/* <Typography variant="largeBody1">
            <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
          </Typography> */}
          {action !== undefined && actionText !== undefined && (
            <>
              <Typography variant="subtitle1Bold" sx={{ marginTop: '2rem' }}>
                TRY IT OUT
              </Typography>
              <Typography variant="largeBody1">
                <div dangerouslySetInnerHTML={{ __html: actionText }}></div>
              </Typography>
              <Box sx={{ marginTop: '1rem' }}>{action}</Box>
            </>
          )}
        </EmptyStateBodyText>
      </EmptyStateBody>
    </EmptyStateContainer>
  )
}

const IndividualEmptyState = ({ pageObject, pageType }) => {
  const target = pageObject?.id
  const presetTargets = [target, pageObject?.family].filter(
    item => item !== undefined
  )

  const { addIndividualControl } = useAddIndividualControl({
    target,
    presetTargets,
    pageType,
  })

  const name = getName(pageObject, pageType)

  return (
    <>
      <EmptyStateWithIcon
        instanceType={INSTANCE_TYPE_INDIVIDUAL}
        title={'Add Friends Associates or Neighbors!'}
        bodyText={`FANs are people associated with <Strong>${name}</Strong>.`}
        actionText={`Click the button below to connect an individual with <Strong>${name}</Strong>.`}
        action={addIndividualControl}
      />
    </>
  )
}

const LinkedPageEmptyState = ({
  pageObject,
  pageType,
  type,
  dynamicPageName,
}) => {
  const targets = pageObject?.id ? [pageObject?.id] : []

  const pageName = getName(pageObject, pageType)

  let homeActionText
  let bodyText
  let title
  let linkedActionText
  switch (type) {
    case INSTANCE_TYPE_EVENT:
      const { name, nameLc, indefiniteArticle } = getDynamicNameVariants(
        'Occasion',
        dynamicPageName
      )
      title = `Add ${indefiniteArticle} ${name} to the Archive!`
      bodyText = `Occasions are notable events. e.g Wedding, Graduation, Birthday, etc`
      homeActionText = `Click the button below to create an occasion record in your archive.`
      linkedActionText = `Click the button below to connect ${indefiniteArticle} ${nameLc} with <Strong>${pageName}</Strong>.`
      break
    case INSTANCE_TYPE_LOCATION:
      title = 'Add a Place to the Archive!'
      bodyText = `Places are significant locations. A Town, House, City, Building.`
      homeActionText = `Click the button below to create a place record in your archive.`
      linkedActionText = `Click the button below to connect a place with <Strong>${pageName}</Strong>.`
      break
    case INSTANCE_TYPE_ARTEFACT:
      title = 'Add an Artefact to the Archive!'
      bodyText = `Artifacts are objects that have a physical link to the past, with memories and stories attached to them. e.g Vase, Picture, Medal`
      homeActionText = `Click the button below to create an artifact record in your archive`
      linkedActionText = `Click the button below to connect an artefact with <Strong>${pageName}</Strong>.`
      break
    default:
      homeActionText = undefined
      bodyText = undefined
      title = undefined
      linkedActionText = undefined
  }

  return (
    <>
      <EmptyStateWithIcon
        instanceType={type}
        title={title}
        bodyText={bodyText}
        actionText={pageObject ? linkedActionText : homeActionText}
        action={<AddLinkedPageControl type={type} targets={targets} />}
      />
    </>
  )
}

const AlbumsEmptyState = ({ pageObject, pageType, type }) => {
  const targets = pageObject?.id ? [pageObject?.id] : []

  const name = getName(pageObject, pageType)

  const bodyText = `Albums are collections of related images, PDFs and YourTube videos.`
  const linkedActionText = `Click the button below to upload an album and connect with <Strong>${name}</Strong>.`
  const homeActionText = `Click the button below to upload an album.`

  return (
    <>
      <EmptyStateWithIcon
        instanceType={type}
        title={'Create Some Albums'}
        bodyText={bodyText}
        actionText={pageObject ? linkedActionText : homeActionText}
        action={
          <AddAlbumControl
            linkedPageId={pageObject?.id}
            presetTargets={targets}
          />
        }
      />
    </>
  )
}

const MediaEmptyState = ({ pageObject, pageType, type }) => {
  const name = getName(pageObject, pageType)

  const bodyText = `Media can be  photos, PDFs or YouTube vidoes.`
  const linkedActionText = `Click the button below to upload some media and connect with <Strong>${name}</Strong>.`
  const homeActionText = `Click the button below to upload some media`

  return (
    <>
      <EmptyStateWithIcon
        instanceType={type}
        title={'Upload Some Media'}
        bodyText={bodyText}
        actionText={pageObject ? linkedActionText : homeActionText}
        action={
          <AddMediaControl
            title={name}
            linkedPageId={pageObject?.id}
            target={pageObject?.id}
          />
        }
      />
    </>
  )
}

const MapEmptyState = ({ pageObject, pageType, type }) => {
  const name = getName(pageObject, pageType)

  const bodyText = `Maps are a detailed view of a location.  Add links and images and notes.`
  const linkedActionText = `Click the button below to create a map and connect with <Strong>${name}</Strong>.`
  const homeActionText = `Click the button below to create a map`

  return (
    <>
      <EmptyStateWithIcon
        instanceType={INSTANCE_TYPE_MAP}
        title={'Upload a Map'}
        bodyText={bodyText}
        actionText={pageObject ? linkedActionText : homeActionText}
        action={<AddMapControl />}
      />
    </>
  )
}

const ContentEmptyState = ({
  pageObject,
  pageType,
  type,
  dynamicPageName,
  dynamicPageId,
}) => {
  const writeArticleLink = useTreeLink('write-article')
  const writeDocumentLink = useTreeLink('edit-document')
  const presetTargets = [pageObject?.id]
  const isBlogTree = useSelector(selectIsBlogTree)

  const pageName = getName(pageObject, pageType)

  let linkedActionText
  let homeActionText
  let bodyText
  let title
  let action
  let articleOrDocumentName =
    type === INSTANCE_TYPE_ARTICLE ? 'Article' : 'Document'
  const { name, nameLc, namePlural, indefiniteArticle } =
    getDynamicNameVariants(articleOrDocumentName, dynamicPageName)

  switch (type) {
    case INSTANCE_TYPE_ARTICLE:
      if (isBlogTree) {
        title = 'Create a Blog Post!'
        bodyText = `Blog posts can contain text, images and even sub trees`
        linkedActionText = `Click the button below to create a blog post.`
        homeActionText = `Click the button below to create a blog post.`
      } else {
        title = `Create ${indefiniteArticle} ${name}!`
        bodyText = `${namePlural} are rich pages where you can add stories and embed trees and images. e.g biography, history.`
        linkedActionText = `Click the button below to create ${indefiniteArticle} ${nameLc} for <Strong>${pageName}</Strong>.`
        homeActionText = `Click the button below to create ${indefiniteArticle} ${nameLc}  in your archive.`
        action = (
          <AddStoryButton
            buttonText={`Add ${name}`}
            to={{
              pathname: writeArticleLink,
              state: {
                presetTargets,
                dynamicPageName,
                dynamicPageId,
              },
            }}
          />
        )
      }

      break
    case INSTANCE_TYPE_DOCUMENT:
      title = `Add ${indefiniteArticle} ${name} to the Archive!`
      bodyText = `${namePlural} are records of physical books, logs, letters, etc.`
      linkedActionText = `Click the button below to upload ${indefiniteArticle} ${nameLc} for <Strong>${pageName}</Strong>.`
      homeActionText = `Click the button below to upload ${indefiniteArticle} ${nameLc}into your archive`
      action = (
        <UploadDocumentButton
          buttonText={`Add ${name}`}
          to={{
            pathname: writeDocumentLink,
            state: {
              presetTargets,
              dynamicPageName,
              dynamicPageId,
            },
          }}
        />
      )
      break
    default:
      linkedActionText = undefined
      homeActionText = undefined
      bodyText = undefined
      title = undefined
      action = undefined
  }

  return (
    <>
      <EmptyStateWithIcon
        instanceType={type}
        title={title}
        bodyText={bodyText}
        actionText={pageObject ? linkedActionText : homeActionText}
        action={action}
      />
    </>
  )
}

export default EmptyFeed
