import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import clsx from 'clsx'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import { useActionDispatcher, useTreeSettings } from 'src/modules/app/hooks'
import {
  createLinkedPageItem,
  updateLinkedPageItem,
  deleteLinkedPageItem,
} from 'src/modules/page/pageSlice'
import {
  Button,
  DialogTitle,
  FormikTextField,
  Typography,
  ConfirmDialog,
} from 'src/modules/ui'
import TagForm from 'src/modules/writeArticle/TagForm'

import SelectLocation from './SelectLocation'
import { PAGE_DISPLAY_NAME_SINGULAR } from './LinkedPage'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_LOCATION,
} from 'src/modules/app/links'
import { ACTION_CREATE, ACTION_DELETE } from '../app/appConstants'
import { AddressForm } from 'src/modules/common/AddressForm'

import { useDynamicPageDefFinder } from '../dynamicPage/dynamicPageHooks'

const useStyles = makeStyles(theme => ({
  subTitle: {
    marginTop: theme.spacing(3),
  },
  dialog: {
    minWidth: 425,
  },
  actions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2, 2, 1.5),
  },
  actionsLinkedPage: {
    justifyContent: 'space-between',
  },
}))

export const CreateOrUpdateLinkedPageDialog = ({
  open = false,
  initialTitle = '',
  trigger,
  type,
  linkedPage,
  onCreate,
  presetTargets = [],
}) => {
  const { isOnePlaceStudyHome } = useTreeSettings()
  const dynamicPageDef = useDynamicPageDefFinder()

  const classes = useStyles()
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(open)
  const LINK_INSTRUCTIONS = {
    [INSTANCE_TYPE_EVENT]: 'TAG this occasion with:',
    [INSTANCE_TYPE_ARTEFACT]: 'TAG this artifact with:',
    [INSTANCE_TYPE_LOCATION]: 'TAG this place with:',
  }

  const allPresetTargets = new Set([
    ...presetTargets,
    ...(linkedPage?.links ? linkedPage.links.map(({ target }) => target) : []),
  ])
  const [targets, setTargets] = useState([...allPresetTargets])
  const presetLocations =
    linkedPage?.locations && !!linkedPage.locations.length
      ? linkedPage.locations.map(l => l.id)
      : []
  const [locations, setLocations] = useState(presetLocations)

  let title = type && PAGE_DISPLAY_NAME_SINGULAR[type]
  if (dynamicPageDef) {
    title = dynamicPageDef.pageName
  }

  const dispatchPageAction = useActionDispatcher(
    linkedPage ? updateLinkedPageItem : createLinkedPageItem
  )
  const dispatchDeleteLinkedPageItem = useActionDispatcher(deleteLinkedPageItem)

  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => {
    setTargets([...allPresetTargets])
    setModalOpen(false)
  }

  const handleChangeTags = tags => {
    setTargets(tags)
  }

  const handleSelectLocation = listOfLocationIds => {
    setLocations(listOfLocationIds)
  }

  const noTagsDialogRef = useRef()

  const handleSubmit = async (
    {
      title,
      description,
      date,
      dateOccurredGed,
      textAddress,
      address,
      lastKnownCustodian,
    },
    { setErrors },
    allowNoTags = false
  ) => {
    if (!allowNoTags && targets.length === 0) {
      noTagsDialogRef.current.showDialog(async () => {
        //call this function again but with allowNoTags=true
        handleSubmit(
          {
            title,
            description,
            date,
            dateOccurredGed,
            textAddress,
            address,
            lastKnownCustodian,
          },
          { setErrors },
          (allowNoTags = true)
        )
      })

      return
    }
    let linkedPageItem
    const data = {
      linkedPageId: linkedPage?.id,
      textAddress: textAddress,
      address: address,
      description: description,
      lastKnownCustodian: lastKnownCustodian,
      pageType: type,
      setTargets: targets,
      title,
      photo: linkedPage?.photo,
      dynamicPageId: dynamicPageDef?.id,
    }

    if (date) {
      data.dateOccurredEarliest = format(date, 'yyyy-MM-dd')
    }

    if (dateOccurredGed) {
      data.dateOccurredGed = dateOccurredGed
    }

    if (locations) {
      data.setLocations = locations
    }
    try {
      linkedPageItem = await dispatchPageAction(data, {
        successNotification: `${title} ${linkedPage ? 'updated' : 'created'}`,
      }).unwrap()
      if (onCreate) {
        onCreate(linkedPageItem)
      }
    } catch (error) {
      setErrors(error.data)
      return
    }
    handleCloseModal()
  }

  const handleDelete = async () => {
    try {
      await dispatchDeleteLinkedPageItem({
        pageType: type,
        linkedPageId: linkedPage?.id,
      }).unwrap()
    } catch (error) {}
    handleCloseModal()
    history.goBack()
  }

  const initialValues = linkedPage
    ? {
        title: linkedPage.title,
        description: linkedPage.description,
        address: linkedPage.address,
        ...(type === INSTANCE_TYPE_EVENT
          ? {
              date: new Date(linkedPage.dateOccurredEarliest),
              dateOccurredGed: linkedPage?.dateOccurredGed,
            }
          : {}),
        ...(type === INSTANCE_TYPE_LOCATION || type === INSTANCE_TYPE_EVENT
          ? {
              textAddress: linkedPage.textAddress,
            }
          : {}),
        ...(type === INSTANCE_TYPE_ARTEFACT
          ? { lastKnownCustodian: linkedPage.lastKnownCustodian }
          : {}),
      }
    : {
        title: initialTitle || '',
        date: new Date(),
        textAddress: '',
        lastKnownCustodian: '',
      }

  // const prependNameToAddress = (name, formattedPlace) => {
  //   if (formattedPlace) {
  //     let firstWord = formattedPlace.split(' ')[0]
  //     if (firstWord.at(-1) === ',') {
  //       firstWord = firstWord.substring(0, firstWord.length - 1)
  //     }

  //     if (firstWord !== name) {
  //       return `${name}${formattedPlace ? ', ' + formattedPlace : ''}`
  //     } else return `${formattedPlace}`
  //   } else {
  //     return name
  //   }
  // }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth={false}>
        {modalOpen && (
          <div className={classes.dialog}>
            <DialogTitle onClose={handleCloseModal}>
              {linkedPage ? 'Edit' : 'Add'} {title}
            </DialogTitle>
            <NoTagsConfirmationDialog
              ref={noTagsDialogRef}
              bodyText="You should relate this to at least one family or individual"
              onConfirm={() => {}} // remove an error about the default prop value
            />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                <>
                  <DialogContent sx={{ pt: 0 }}>
                    <FormikTextField
                      fullWidth
                      label="Title"
                      disabled={isSubmitting}
                      margin="normal"
                      name="title"
                    />
                    {/*<FormikTextField*/}
                    {/*  fullWidth*/}
                    {/*  label="Description"*/}
                    {/*  disabled={isSubmitting}*/}
                    {/*  margin="normal"*/}
                    {/*  name="description"*/}
                    {/*/>*/}
                    {type === INSTANCE_TYPE_EVENT && (
                      <FormikGedDatePicker
                        fullWidth
                        disabled={isSubmitting}
                        label="Date of Occasion"
                        margin="normal"
                        name="dateOccurredGed"
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {type === INSTANCE_TYPE_LOCATION ||
                    type === INSTANCE_TYPE_EVENT ||
                    type === INSTANCE_TYPE_ARTEFACT ? (
                      <>
                        {(type === INSTANCE_TYPE_LOCATION || // text_address is not on Artefact
                          type === INSTANCE_TYPE_EVENT) &&
                          values.textAddress && ( // text_address is being phased out in favour of an Address instance
                            <FormikTextField
                              fullWidth
                              disabled={isSubmitting}
                              label="Address description"
                              margin="normal"
                              name="textAddress"
                            />
                          )}
                        <AddressForm
                          address={values.address}
                          lookupLabel={
                            type === INSTANCE_TYPE_ARTEFACT
                              ? 'Last known address lookup'
                              : undefined
                          }
                          onValuesChanged={address => {
                            // this is called by AddressForm whenever any of its inputs change
                            // console.debug(
                            //   `CreateOrUpdateLinkedPageDialog.onValuesChanged() called with address:`,
                            //   address
                            // )

                            // DON'T call setFieldValue('address') because it causes this <Address> to re-render which causes onValuesChanged() to be called when the initial
                            // values are set which causes an infinite loop.
                            values.address = address
                          }}
                        />
                      </>
                    ) : null}
                    {type === INSTANCE_TYPE_ARTEFACT && (
                      <FormikTextField
                        fullWidth
                        disabled={isSubmitting}
                        label="Last known custodian"
                        margin="normal"
                        name="lastKnownCustodian"
                      />
                    )}
                    <Typography className={classes.subTitle}>
                      {LINK_INSTRUCTIONS[type]}
                    </Typography>
                    <TagForm
                      subjectType={type}
                      onChangeTags={handleChangeTags}
                      presetTargets={[...allPresetTargets]}
                    />
                    {type === INSTANCE_TYPE_EVENT && (
                      <SelectLocation
                        onSelectLocation={handleSelectLocation}
                        initialValues={linkedPage ? locations : presetTargets}
                        target={linkedPage}
                      />
                    )}
                  </DialogContent>
                  <DialogActions
                    className={clsx(classes.actions, {
                      [classes.actionsLinkedPage]: linkedPage,
                    })}
                  >
                    {linkedPage && (
                      <ConfirmDialog
                        onConfirm={handleDelete}
                        trigger={props => (
                          <Button
                            disabled={isOnePlaceStudyHome()}
                            permissionAction={ACTION_DELETE}
                            permissionParams={{
                              instanceType: type,
                              instance: linkedPage,
                            }}
                            {...props}
                            color="primary"
                          >
                            Delete
                          </Button>
                        )}
                      >
                        <Typography>
                          Are you sure you want to delete this {title}?
                        </Typography>
                      </ConfirmDialog>
                    )}
                    <Button
                      permissionAction={ACTION_CREATE}
                      permissionParams={{
                        instanceType: type,
                      }}
                      isLoading={isSubmitting}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      {linkedPage ? 'Save' : 'Create'}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Formik>
          </div>
        )}
      </Dialog>
    </>
  )
}

export default CreateOrUpdateLinkedPageDialog
