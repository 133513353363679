import indefinite from 'indefinite'
import pluralize from 'pluralize'

export const getDynamicNameVariants = (originalName, dynamicName) => {
  const name = dynamicName || originalName
  const nameLc = name.toLowerCase()
  const namePlural = pluralize(name)
  const nameLcPlural = namePlural.toLowerCase()
  const indefiniteArticle = indefinite(name, { articleOnly: true })

  return {
    name,
    nameLc,
    namePlural,
    nameLcPlural,
    indefiniteArticle,
  }
}
