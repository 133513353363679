import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { titleFromPageType } from 'src/modules/page/LinkedPage'
import {
  fetchPublicPageContent,
  fetchPublicPageItem,
  resetPublicPageState,
  selectPublicContent,
  selectPublicDocuments,
  selectPublicPageIndividuals,
  selectPublicPageItem,
} from './pageSlice'
import {
  useActionDispatcher,
  usePublicTreeSettings,
} from 'src/modules/app/hooks'
import { LoadingIndicator } from 'src/modules/ui'
import PublicPage, { routeComponentMap } from './PublicPage'
import { PublicContext } from '../contexts'
import HtmlHeader from 'src/modules/app/HtmlHeader'
import { useNotification } from 'src/modules/app'
import { usePrerender } from '../hooks'
import {
  getHistoryRoute,
  getDocumentsRoute,
  getPhotosRoute,
  getWelcomeRoute,
  getPeopleRoute,
  getEventsRoute,
  getArtefactsRoute,
  getLocationsRoute,
  getMapsRoute,
} from '../../page/Page'
import { convertUIGedDate } from '../../common/gedcomDateParser'
import { useIsAlphaFeatureUser } from '../../auth/hooks'

const PublicLinkedPage = ({ pageType, linkedPageId, publicTreeRoute }) => {
  const fetchedPublicPage = useSelector(selectPublicPageItem)
  const dispatchFetchPublicPageItem = useActionDispatcher(fetchPublicPageItem)
  const { treeSlug } = useContext(PublicContext)
  const { showError } = useNotification()
  const history = useHistory()
  const dispatch = useDispatch()
  const showFeature = useIsAlphaFeatureUser()?.documents

  const dispatchFetchPublicPageContent = useActionDispatcher(
    fetchPublicPageContent
  )
  const publicPageArticles = useSelector(selectPublicContent)

  const publicPageDocuments = useSelector(selectPublicDocuments)

  const publicPageIndividuals = useSelector(selectPublicPageIndividuals)

  const { isOnePlaceStudyHome } = usePublicTreeSettings()

  const makeDefaultDesc =
    publicPageArticles.count === 0 ||
    fetchedPublicPage?.pinnedArticle ||
    fetchedPublicPage?.address

  const routes = [
    getWelcomeRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      makeDefault: makeDefaultDesc,
      treeSlug: publicTreeRoute,
      localLabelOverride: 'Description',
      disabled: true,
    }),
    getHistoryRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      makeDefault: !makeDefaultDesc,
      treeSlug: publicTreeRoute,
      disabled: !publicPageArticles.count.length > 0,
    }),
    isOnePlaceStudyHome() &&
      getMapsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug: publicTreeRoute,
        disabled: true,
      }),
    showFeature &&
      getDocumentsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug: publicTreeRoute,
        disabled: !publicPageDocuments.count.length > 0,
      }),
    getPhotosRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      treeSlug: publicTreeRoute,
      disabled: true,
    }),
    getEventsRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      treeSlug: publicTreeRoute,
      disabled: true,
    }),
    getArtefactsRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      localLabelOverride: 'Artifacts',
      treeSlug: publicTreeRoute,
      disabled: true,
    }),
    getLocationsRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      treeSlug: publicTreeRoute,
      disabled: true,
    }),
    getPeopleRoute({
      routeComponentMap,
      pageType,
      linkedPageId,
      treeSlug: publicTreeRoute,
      disabled: !publicPageIndividuals.count.length > 0,
    }),
  ].filter(x => x)

  let decoratedRoutes = routes
  if (fetchedPublicPage?.pinnedArticle || fetchedPublicPage?.address) {
    decoratedRoutes[0].disabled = false
    decoratedRoutes[0].componentParams = {
      id: fetchedPublicPage?.pinnedArticle?.id,
    }
  }

  usePrerender(fetchedPublicPage !== undefined)

  useEffect(() => {
    dispatch(resetPublicPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatchFetchPublicPageContent({
          page: 0,
          pageType: 'article',
          target: linkedPageId,
          treeSlug,
        })
        await dispatchFetchPublicPageItem({
          treeSlug,
          pageType,
          linkedPageId,
        }).unwrap()
      } catch (err) {
        if (err.status === 404) {
          showError('The content you have requested does not exist')
          history.push(`/public/${treeSlug}/home`)
        }
      }
    }
    fetch()
  }, [
    dispatchFetchPublicPageItem,
    dispatchFetchPublicPageContent,
    pageType,
    linkedPageId,
    treeSlug,
    showError,
    history,
  ])

  const title = fetchedPublicPage.title
  const entityTypeTitle = titleFromPageType({ pageType })
  const photoUrl = fetchedPublicPage.photo?.fileThumbnail

  const headerProps = {
    title: title,
    type: 'website',
    image: photoUrl,
    url: window.location.href,
  }

  const handleSubtitle = subtitleStr => {
    let subtitle = subtitleStr || null
    if (subtitle) {
      subtitle = convertUIGedDate(subtitle)
    }
    return subtitle
  }

  console.log('DEBUG decoratedRoutes', publicTreeRoute, decoratedRoutes)

  return (
    <>
      <HtmlHeader {...headerProps} />

      {dispatchFetchPublicPageItem.status === 'loading' ||
      fetchedPublicPage.id !== linkedPageId ? (
        <LoadingIndicator />
      ) : (
        <PublicPage
          pageType={pageType}
          publicTreeRoute={publicTreeRoute}
          linkedPageId={linkedPageId}
          description={fetchedPublicPage.description}
          entityTypeTitle={entityTypeTitle}
          photoUrl={photoUrl}
          routes={decoratedRoutes}
          title={title}
          links={fetchedPublicPage.links}
          subtitle={handleSubtitle(fetchedPublicPage?.dateOccurredGed)}
          address={fetchedPublicPage.textAddress}
        />
      )}
    </>
  )
}

export default PublicLinkedPage
