import React from 'react'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_ARTEFACT } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import { DynamicPageTabContext } from 'src/modules/dynamicPage/dynamicPageContexts'
import { useDynamicPageTabHelpers } from 'src/modules/dynamicPage/dynamicPageHooks'
import {
  fetchLinkedPageArtefacts,
  selectLinkedPageArtefacts,
} from './pageSlice'

const Artefacts = ({ dynamicPageTypeTabIndex }) => {
  const { linkedPageId } = useParams()
  const params = useDynamicPageTabHelpers({ dynamicPageTypeTabIndex })

  return (
    <DynamicPageTabContext.Provider value={params}>
      <LinkedPageList
        action={fetchLinkedPageArtefacts}
        selector={selectLinkedPageArtefacts}
        target={linkedPageId}
        type={INSTANCE_TYPE_ARTEFACT}
      />
    </DynamicPageTabContext.Provider>
  )
}

export default Artefacts
