import React, { useEffect, useState } from 'react'
import { Container, LinearPseudoLoadingIndicator } from '../ui'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchUser, initialiseUser, selectUser } from '../auth/authSlice'
import { useActionDispatcher, useAppendQueryParams, useSetTree } from '../app'
import { Box, Typography, DialogActions } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SimpleDialog, Button } from 'src/modules/ui'
import { ACTION_ALL_ACCESS, FOCUS_ONE_PLACE_STUDY } from '../app/appConstants'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'
import { useArchiveFocus } from 'src/modules/app/hooks'

// This is mounted after a new user has signed up and verified their email
// shown when:
//  - Home.js redirects to /onboarding because user.currentTree is not set
//  - RedirectToDefaultTree.js redirects to /onboarding because user.lastViewedTree is not set
//    and user.trees[0] is not set - wouldn't get here if the user already had a demo tree
//  - user visits /onboarding (?)
function Onboarding() {
  const history = useHistory()
  const dispatchInitialiseUser = useActionDispatcher(initialiseUser)
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const [initialised, setInitialised] = useState(false)
  const [demoTreeAvailable, setDemoTreeAvailable] = useState(false)
  const [finishLoader, setFinishLoader] = useState(false)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const setTree = useSetTree()
  const appendQueryParams = useAppendQueryParams()
  const location = useLocation()
  const queryString = location.search
  const { focus } = useArchiveFocus()

  useEffect(() => {
    const initUser = async () => {
      if (initialised === false) {
        try {
          setInitialised(true)
          // POSTs to /account/user/initialise/ which creates free UserFeatureSets and a copy of the demo tree if configured
          // reducer in authSlice updates state.user with the response (which may now include demo tree)
          const result = await dispatchInitialiseUser()
          setFinishLoader(true)
          if (focus === FOCUS_ONE_PLACE_STUDY) {
            history.push(appendQueryParams('/create-tree'))
          } else {
            if (result?.payload?.lastViewedTree) {
              await setTree(result?.payload?.lastViewedTree.slug)
              setDemoTreeAvailable(true)
            } else {
              setDemoTreeAvailable(false)
              history.push(appendQueryParams('/create-tree'))
            }
          }
        } catch (err) {
          history.push(appendQueryParams('/create-tree'))
        }
      }
    }
    initUser()
  }, [
    queryString,
    appendQueryParams,
    dispatchInitialiseUser,
    dispatchFetchUser,
    history,
    initialised,
    dispatch,
    user,
    setTree,
    focus,
  ])

  return (
    <Container>
      <Box sx={{ paddingTop: '100px' }}>
        {demoTreeAvailable === true && (
          <SimpleDialog
            title="Signup complete!"
            showCloseButton={false}
            fullWidth={false}
            open={true}
            disableEscapeKeyDown={true}
            closeOnBackdropClick={false}
          >
            <Typography>
              You're all set! Would you like to explore the demo archive or
              create your own archive now?
            </Typography>
            <DialogActions>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                color="primary"
                variant="outlined"
                //disabled={!progressComplete}
                onClick={e => {
                  sendEvent(ga4Events.ONBOARDING_CLICKED_VIEW_DEMO_TREE)
                  history.push('/complete-profile')
                }}
              >
                Explore demo archive
              </Button>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                color="primary"
                //disabled={!canSubmit}
                onClick={e => {
                  sendEvent(ga4Events.ONBOARDING_CLICKED_CREATE_TREE)
                  history.push(appendQueryParams('/create-tree'))
                }}
              >
                Create your own archive
              </Button>
            </DialogActions>
          </SimpleDialog>
        )}
        <LinearPseudoLoadingIndicator
          title={'Installing demo archive...'}
          interval={150}
          complete={finishLoader}
        />
      </Box>
    </Container>
  )
}

export default Onboarding
