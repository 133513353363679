import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import BaseContentList from './BaseContentList'
import {
  fetchPageContent,
  selectPageContent,
  reorderPageContent,
} from 'src/modules/page/pageSlice'

import {
  fetchPageContentAlbums,
  selectPageContentAlbums,
  fetchPageContentDocuments,
  selectPageContentDocuments,
  setViewConfig,
} from './pageSlice'
import {
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from 'src/modules/app/links'

import { sortTypes } from '../common/constants'
import { ViewConfigSelectorContainer } from './ViewConfigSelectorContainer'
import { usePermissions } from '../auth/authHooks'
import { ACTION_MOVE_CONTENT } from '../app/appConstants'
import { getViewConfig } from '../common/viewConfigUtils'
import { AddStoryButton, UploadDocumentButton } from '../ui/actionButtons'
import { INSTANCE_TYPE_ARTICLE, useTreeLink } from '../app/links'
import Refresh from '../ui/Refresh'
import { getDynamicName } from 'src/modules/dynamicPage/dynamicPageHooks'
import { DynamicPageTabContext } from '../dynamicPage/dynamicPageContexts'

const PageContentList = ({ target, state, type, presetTargets }) => {
  const {
    sort,
    hierarchical,
    ancestralOnly,
    content,
    handleFetchMore,
    handleSetViewConfig,
    handleMoveContentDown,
    handleMoveContentUp,
    loading,
    next,
    results,
    dispatchFetchPageContent,
    isDraggable,
    fetchContent,
  } = usePageContentList({ target, state, type })

  const actions = content?.results?.length > 0 && (
    <>
      <Actions
        onSetViewConfig={handleSetViewConfig}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        type={type}
        presetTargets={presetTargets}
        target={target}
        fetchContent={fetchContent}
      />
    </>
  )

  return (
    <BaseContentList
      actions={actions}
      {...{
        content,
        dispatchFetchContent: dispatchFetchPageContent,
        handleFetchMore,
        handleMoveContentDown,
        handleMoveContentUp,
        isDraggable,
        loading,
        next,
        results,
        type,
      }}
    />
  )
}

const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

export const Actions = ({
  onSetViewConfig,
  sort,
  hierarchical,
  ancestralOnly,
  type,
  target,
  fetchContent,
  presetTargets,
}) => {
  const writeArticleLink = useTreeLink('write-article')
  const writeDocumentLink = useTreeLink('edit-document')
  const { dynamicPageId, dynamicPageName } =
    useContext(DynamicPageTabContext) || {}

  return (
    <ActionButtons>
      <div>
        <ViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={onSetViewConfig}
          target={target}
        />
      </div>
      <div>
        {type === INSTANCE_TYPE_DOCUMENT && (
          <UploadDocumentButton
            buttonText={`Add ${getDynamicName('Document', dynamicPageName)}`}
            to={{
              pathname: writeDocumentLink,
              state: { presetTargets, dynamicPageId, dynamicPageName },
            }}
          />
        )}
        {type === INSTANCE_TYPE_ARTICLE && (
          <AddStoryButton
            buttonText={`Add ${getDynamicName('Article', dynamicPageName)}`}
            to={{
              pathname: writeArticleLink,
              state: { presetTargets, dynamicPageId, dynamicPageName },
            }}
          />
        )}
        <Refresh onClick={() => fetchContent()} />
      </div>
    </ActionButtons>
  )
}

export const usePageContentList = ({ target, state, type }) => {
  const dispatch = useDispatch()
  const dispatchFetchPageContent = useActionDispatcher(
    {
      [INSTANCE_TYPE_PHOTO_ALBUM]: fetchPageContentAlbums,
      [INSTANCE_TYPE_DOCUMENT]: fetchPageContentDocuments,
    }[type] ?? fetchPageContent
  )
  const content = useSelector(
    {
      [INSTANCE_TYPE_PHOTO_ALBUM]: selectPageContentAlbums,
      [INSTANCE_TYPE_DOCUMENT]: selectPageContentDocuments,
    }[type] ?? selectPageContent
  )

  const { next, results } = content
  const [loading, setLoading] = useState()

  const checkPermissions = usePermissions()

  const fetchContent = useCallback(async () => {
    setLoading(true)
    await dispatchFetchPageContent({
      target,
      page: 0,
      state,
      type,
      block_type: 'TEXT',
      block_limit: 1,
    })
    setLoading(false)
  }, [dispatchFetchPageContent, state, target, type])

  useEffect(() => {
    if (!results.length) {
      setLoading(true)
      fetchContent()
    }
  }, [
    dispatchFetchPageContent,
    state,
    type,
    target,
    results.length,
    fetchContent,
  ])

  const handleFetchMore = () => {
    if (dispatchFetchPageContent.status === 'loading') {
      return
    }
    dispatchFetchPageContent({
      target,
      page: content.page + 1,
      state,
      type,
      block_type: 'TEXT',
      block_limit: 1,
    })
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setViewConfig({ type, viewConfig }))
    fetchContent()
  }

  const handleMoveContentDown = params => {
    params?.event?.stopPropagation()
    const { id } = params
    const contentIndex = results.findIndex(i => i.id === id)
    if (contentIndex === results.length - 1) {
      return
    }
    const belowId = results.findIndex(i => i.id === id) + 1
    const belowContent = results[belowId]

    dispatch(
      reorderPageContent({
        target: target,
        content: id,
        putBelowContent: belowContent?.id,
        contentIndex,
        swapIndex: belowId,
        type,
      })
    )
  }

  const handleMoveContentUp = params => {
    params?.event?.stopPropagation()
    const { id } = params
    const contentIndex = results.findIndex(i => i.id === id)
    if (contentIndex === 0) {
      return
    }
    const swapIndex = results.findIndex(i => i.id === id) - 1
    const twoAboveId = results.findIndex(i => i.id === id) - 2
    const twoAboveContent = results[twoAboveId]

    dispatch(
      reorderPageContent({
        target: target,
        content: id,
        putBelowContent: twoAboveContent?.id,
        contentIndex,
        swapIndex,
        type,
      })
    )
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(content)

  const { hasPermission } = checkPermissions(ACTION_MOVE_CONTENT)
  const isDraggable = hasPermission && sort === sortTypes.USER

  return {
    sort,
    hierarchical,
    ancestralOnly,
    content,
    handleFetchMore,
    handleSetViewConfig,
    handleMoveContentDown,
    handleMoveContentUp,
    loading,
    next,
    results,
    dispatchFetchPageContent,
    isDraggable,
    fetchContent,
  }
}

export default PageContentList
