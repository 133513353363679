import { useDynamicPublicPageLoadingHelpers } from './dynamicPublicPageHooks'
import { LoadingIndicator } from 'src/modules/ui'
import { createRoutesFromDynamicPageTypeDef } from 'src/modules/dynamicPage/dynamicPageHooks'
import { DynamicPageContext } from 'src/modules/dynamicPage/dynamicPageContexts'
import PublicPageTitle from '../page/PublicPageTitle'
import Tabs from 'src/modules/ui/Tabs'
import { INSTANCE_TYPE_EVENT } from 'src/modules/app/links'
import { routeComponentMap } from '../page/PublicPage'
const DynamicPage = () => {
  const {
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    publicTreeSlug,
  } = useDynamicPublicPageLoadingHelpers()

  if (isLoading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  const routes = createRoutesFromDynamicPageTypeDef({
    routeComponentMap,
    treeSlug: publicTreeSlug,
    linkedPageId,
    dynamicPageTypeDef: fetchedLinkedPage?.dynamicPageTypeDef,
  })

  const title = fetchedLinkedPage.title

  const photoUrl = fetchedLinkedPage.photo?.fileThumbnail

  return (
    <DynamicPageContext.Provider
      value={{
        dynamicPageId: fetchedLinkedPage?.dynamicPageTypeDef?.id,
        dynamicPageTypeDef: fetchedLinkedPage?.dynamicPageTypeDef,
      }}
    >
      <Tabs
        disableSearch
        routes={routes}
        fullWidthSubRootContainer={true}
        isPublic={true}
        title={
          <PublicPageTitle
            {...{
              pageType: INSTANCE_TYPE_EVENT,
              treeSlug,
              title,
              photoUrl,
            }}
          />
        }
      />
    </DynamicPageContext.Provider>
  )
}

export default DynamicPage
