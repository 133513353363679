import { useParams } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  fetchLinkedPageItem,
  selectLinkedPageItem,
  updateLinkedPageItem,
} from 'src/modules/page/pageSlice'
import { useActionDispatcher } from 'src/modules/app/hooks'
import { resetPageState } from 'src/modules/page/pageSlice'
import { INSTANCE_TYPE_EVENT } from '../app/links'
import {
  DynamicPageTabContext,
  DynamicPageContext,
} from 'src/modules/dynamicPage/dynamicPageContexts'
import { createRoute } from '../page/Page'
import { selectDynamicPageTypeDefs } from '../app/appSlice'
import { fetchAppConfig } from '../app/appSlice'
import { selectPublicPageItem } from '../public/page/pageSlice'

export const useDynamicPageLoadingHelpers = ({ linkedPageIdOverride }) => {
  let { linkedPageId, dynamicPagePath } = useParams()
  linkedPageId = linkedPageIdOverride || linkedPageId
  const dispatch = useDispatch()
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const dispatchUpdateLinkedPageItem = useActionDispatcher(updateLinkedPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchLinkedPageItem)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const handleUpdateLinkedPageitem = photo => {
    dispatchUpdateLinkedPageItem({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      photo,
    })
  }

  const title = fetchedLinkedPage?.title

  const photoUrl = fetchedLinkedPage?.photo?.fileThumbnail
  const headerProps = {
    title: title,
    type: 'website',
    url: window.location.href,
  }

  useEffect(() => {
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    dispatchFetchLinkedPageItem({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
    })
  }, [dispatchFetchLinkedPageItem, linkedPageId])

  const isLoading = dispatchFetchLinkedPageItem.status === 'loading'

  return {
    handleUpdateLinkedPageitem,
    title,
    headerProps,
    photoUrl,
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    dynamicPagePath,
  }
}

/*

*/
export const useDynamicPageTabHelpers = ({ dynamicPageTypeTabIndex = -1 }) => {
  const dynamicPageContext = useContext(DynamicPageContext)
  const [dynamicPageTab, setDynamicPageTab] = useState()

  useEffect(() => {
    if (dynamicPageContext && dynamicPageTypeTabIndex !== -1) {
      const tabs = dynamicPageContext?.dynamicPageTypeDef?.tabs
      if (tabs) {
        const tab = tabs[dynamicPageTypeTabIndex]
        setDynamicPageTab(tab)
      } else {
        setDynamicPageTab(undefined)
      }
    } else {
      setDynamicPageTab(undefined)
    }
  }, [dynamicPageContext, dynamicPageTypeTabIndex, setDynamicPageTab])

  return {
    dynamicPageId: dynamicPageTab?.dynamicPageTypeDefinition?.id,
    dynamicPageName: dynamicPageTab?.dynamicPageTypeDefinition?.pageName,
    dynamicPageType: dynamicPageTab?.dynamicPageTypeDefinition?.pageType,
    dynamicPageTypeDefinition: dynamicPageTab?.dynamicPageTypeDefinition,
  }
}

export const getDynamicName = (originalName, dynamicName) => {
  return dynamicName || originalName
}

export const useDynamicPageDefFinder = () => {
  const dynamicPageTabContext = useContext(DynamicPageTabContext)
  const dynamicPageContext = useContext(DynamicPageContext)

  const dynamicPageDef =
    dynamicPageTabContext?.dynamicPageTypeDefinition ||
    dynamicPageContext?.dynamicPageTypeDef

  return dynamicPageDef
}

export const useDynamicBackgroundFinder = () => {
  const fetchedAuthorisedLinkedPage = useSelector(selectLinkedPageItem)
  const fetchedPublicLinkedPage = useSelector(selectPublicPageItem)

  const fetchedLinkedPage =
    fetchedPublicLinkedPage || fetchedAuthorisedLinkedPage

  return {
    dynamicBackgroundKey: fetchedLinkedPage?.dynamicPageTypeDef?.backgroundKey,
    dynamicOverlayImagePath:
      fetchedLinkedPage?.dynamicPageTypeDef?.overlayImagePath,
  }
}

export const createRoutesFromDynamicPageTypeDef = ({
  dynamicPageTypeDef,
  treeSlug,
  linkedPageId,
  routeComponentMap,
}) => {
  let routes = []
  dynamicPageTypeDef?.tabs.forEach((tab, index) => {
    const createTabRoute = createRoute({
      exact: tab.exact,
      routeType: tab.componentKey,
      navKey: tab.componentKey,
      linkedPagePathOverride: dynamicPageTypeDef.pageTypePath,
      routePathOverride: tab.dynamicPageTypeDefinition?.pageTypePath,
    })

    const route = createTabRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
      localLabelOverride: tab.tabName,
      makeDefault: tab.makeDefault,
      routeComponentMap,
    })

    //add the tab index to the route so we can sish out the tab data down in the tree.
    route.componentParams = {
      dynamicPageTypeTabIndex: index,
    }

    routes.push(route)
  })

  return routes
}

export const useDynamicPageTypeDefLookups = () => {
  const dispatch = useDispatch()
  const dynamicPageTypeDefs = useSelector(selectDynamicPageTypeDefs)

  const fetchConfig = async () => {
    await dispatch(fetchAppConfig())
  }

  const findDynamicPageTypeDefByPath = async path => {
    if (dynamicPageTypeDefs.length === 0) {
      await fetchConfig()
    }
    return dynamicPageTypeDefs.find(
      dynamicPageTypeDef => dynamicPageTypeDef.pageTypePath === path
    )
  }

  const findDynamicPageTypeDefById = async id => {
    if (dynamicPageTypeDefs.length === 0) {
      await fetchConfig()
    }
    return dynamicPageTypeDefs.find(
      dynamicPageTypeDef => dynamicPageTypeDef.id === id
    )
  }

  return { findDynamicPageTypeDefByPath, findDynamicPageTypeDefById }
}

export const useDynamicMediaUrlHelpers = () => {
  const dynamicPageContext = useContext(DynamicPageContext)
  const linkedPageId = dynamicPageContext?.linkedPageId
  let url = undefined
  if (dynamicPageContext) {
    let dynamicPageHomePath = ''
    if (dynamicPageContext?.dynamicPageHomePath.indexOf(linkedPageId) === -1) {
      dynamicPageHomePath = `${dynamicPageContext?.dynamicPageHomePath}/`
    }
    const pageType = dynamicPageContext?.dynamicPageTypeDef?.pageTypePath
    url = `${dynamicPageHomePath}${pageType}/${linkedPageId}`
  }

  return {
    dynamicMediaUrl: url,
    dynamiclinkedPageId: linkedPageId,
  }
}
