import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import AccountAdmin from 'src/modules/accountAdmin/AccountAdmin'
import Gedcom from 'src/modules/accountAdmin/Gedcom'
import Article from 'src/modules/content/Article'
import { InformationRequest } from 'src/modules/informationRequest'
import { WriteArticle } from 'src/modules/writeArticle'
import { WriteDocumentArticle } from 'src/modules/writeArticle'

import {
  MyArticles,
  MyInformationRequests,
  MyPhotos,
} from 'src/modules/myContent'
import { AlbumPage } from 'src/modules/photo'
import { ResolveTree } from 'src/modules/trees/ResolveTree'
import {
  ArtefactsPage,
  LocationsPage,
  EventsPage,
  FamilyPage,
  IndividualsPage,
  Individuals,
} from 'src/modules/page'
import { MediaPage } from 'src/modules/photo'
import { TwelveMonthReport } from 'src/modules/goals'
import {
  SelectMyself,
  SelectIndividualMode,
} from 'src/modules/trees/SelectMyself'
import { EditTree } from 'src/modules/viewer/EditTree'
import { SiteSearch } from 'src/modules/search/SiteSearch'
import { LoadingIndicator } from 'src/modules/ui'
import { isLoginRoute } from '../auth/utils'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_TREE,
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_ARTICLE,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_FAMILY,
  PATH_SEGMENT_FANS,
  PATH_SEGMENT_INDIVIDUAL,
  PATH_SEGMENT_INFORMATION_REQUEST,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MAP,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_PEOPLE,
  PATH_SEGMENT_PHOTO_ALBUM,
} from './links'
import { selectIsBlogTree } from '../auth/authSlice'
import { OneShotJoyride } from './joyrides'
import BlogHome from '../home/BlogHome'
import { useSetTree } from './hooks'
import { notificationTimerStop } from './appSlice'
import { usePermissions } from '../auth/authHooks'
import { ACTION_CREATE, ACTION_EDIT, ACTION_INVITE_USERS } from './appConstants'
import HomeSwitch from '../home/HomeSwitch'
import MapPage from '../map/MapPage'
import UploadGedCom from '../trees/UploadGedCom'
import DynamicTabPage from '../dynamicPage/DynamicTabPage'
import CompleteProfile from 'src/modules/auth/CompleteProfile'

const TreeApp = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const setTree = useSetTree()
  const location = useLocation()
  const [lastFetchedSlug, setLastFetchedSlug] = useState(null)
  const checkPermissions = usePermissions()
  const {
    params: { slug },
  } = useRouteMatch()

  useEffect(() => {
    // Authenticated user trying to access login routes. Redirect
    if (isLoginRoute(location.pathname)) {
      history.push('/')
    }
    if (slug !== lastFetchedSlug && !isLoginRoute(location.pathname)) {
      setTree(slug)
      setLastFetchedSlug(slug)
    }
  }, [history, lastFetchedSlug, location, setTree, slug, dispatch])

  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  useEffect(() => {
    // stop the notifications timer when TreeApp dismounts
    console.debug(
      `TreeApp useEffect(): authorisedTreeSlug changed: '${authorisedTreeSlug}'` //, dispatch: ${dispatch}.`
    )

    return () => {
      console.debug(
        `TreeApp useEffect(): TreeApp dismounting, calling notificationTimerStop()...`
      )
      dispatch(notificationTimerStop())
    }
  }, [authorisedTreeSlug, dispatch])

  const isBlogTree = useSelector(selectIsBlogTree)

  if (!authorisedTreeSlug) {
    return <LoadingIndicator />
  }

  return (
    <OneShotJoyride>
      <Switch>
        <Route path={`/${slug}/home`} component={HomeSwitch} />
        <Route
          path={`/:treeSlug/write-article/:id?`}
          component={WriteArticle}
        />
        <Route
          path={`/:treeSlug/edit-document/:id?`}
          component={
            checkPermissions(ACTION_CREATE, {
              instanceType: INSTANCE_TYPE_ARTICLE,
            }).hasPermission
              ? WriteDocumentArticle
              : () => <Redirect to={`/${slug}/home`} />
          }
        />
        ,
        <Route path={`/${slug}/search`} component={SiteSearch} />
        <Route path={`/:treeSlug/blog`} component={BlogHome} />
        {!isBlogTree && [
          <Route
            exact
            path={`/${slug}/select-myself`}
            render={props => (
              <SelectMyself
                selectIndividualMode={SelectIndividualMode.newArchive}
                {...props}
              />
            )}
          />,
          <Route
            exact
            path={`/${slug}/change-myself`}
            render={props => (
              <SelectMyself
                selectIndividualMode={SelectIndividualMode.myself}
                {...props}
              />
            )}
          />,
          <Route
            exact
            path={`/${slug}/change-home-person`}
            render={props => (
              <SelectMyself
                selectIndividualMode={SelectIndividualMode.homePerson}
                {...props}
              />
            )}
          />,
          <Route
            exact
            path={`/${slug}/complete-ops-profile`}
            render={props => <CompleteProfile nextLink="upload-gedcom" />}
          />,
          <Route
            exact
            path={`/${slug}/complete-special-interest-profile`}
            render={props => <CompleteProfile />}
          />,
          <Route exact path={`/${slug}/edit`} component={EditTree} />,
          <Route
            path={`/${slug}/resolve-tree/:draftTreeSlug`}
            component={
              checkPermissions(ACTION_EDIT, {
                instanceType: INSTANCE_TYPE_TREE,
              }).hasPermission
                ? ResolveTree
                : () => <Redirect to={`/${slug}/home`} />
            }
          />,
          <Route
            path={`/${slug}/update-tree`}
            component={
              checkPermissions(ACTION_EDIT, {
                instanceType: INSTANCE_TYPE_TREE,
              }).hasPermission
                ? Gedcom
                : () => <Redirect to={`/${slug}/home`} />
            }
          />,
          <Route path={`/${slug}/my-articles`} component={MyArticles} />,
          <Route
            path={`/${slug}/my-ask-family-requests`}
            component={MyInformationRequests}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_ARTICLE}/:id`}
            component={Article}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_DOCUMENT}/:id`}
            component={Article}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_PHOTO_ALBUM}/:id`}
            component={AlbumPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_MAP}/:id`}
            component={MapPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_MEDIA}/:id`}
            component={MediaPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_INFORMATION_REQUEST}/:id`}
            component={InformationRequest}
          />,
          <Route path={`/${slug}/my-media`} component={MyPhotos} />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_INDIVIDUAL}/:linkedPageId`}
            component={IndividualsPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_EVENT}/:linkedPageId`}
            component={EventsPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_LOCATION}/:linkedPageId`}
            component={LocationsPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_ARTEFACT}/:linkedPageId`}
            component={ArtefactsPage}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_PEOPLE}/:linkedPageId`}
            component={Individuals}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_FANS}/:linkedPageId`}
            component={Individuals}
          />,
          <Route
            path={`/${slug}/${PATH_SEGMENT_FAMILY}/:linkedPageId`}
            component={FamilyPage}
          />,
          <Route
            path={`/${slug}/write-article/:id?`}
            component={
              checkPermissions(ACTION_CREATE, {
                instanceType: INSTANCE_TYPE_ARTICLE,
              }).hasPermission
                ? WriteArticle
                : () => <Redirect to={`/${slug}/home`} />
            }
          />,
          <Route path={`/${slug}/goals`} component={TwelveMonthReport} />,
        ]}
        {checkPermissions(ACTION_INVITE_USERS).hasPermission && (
          <Route path={`/${slug}/user-admin`} component={AccountAdmin} />
        )}
        ,
        <Route path={`/${slug}/upload-gedcom`} component={UploadGedCom} />
        ,
        <Route
          path={`/${slug}/:dynamicPagePath/:linkedPageId`}
          component={DynamicTabPage}
        />
        ,
        <Redirect to={isBlogTree ? `/${slug}/blog` : `/${slug}/home`} />
      </Switch>
    </OneShotJoyride>
  )
}

export default TreeApp
