import {
  useDynamicPageLoadingHelpers,
  createRoutesFromDynamicPageTypeDef,
} from './dynamicPageHooks'
import { LoadingIndicator } from 'src/modules/ui'
import { INSTANCE_TYPE_EVENT } from '../app/links'
import { LinkedPageDisplay } from '../page/LinkedPage'
import { DynamicPageContext } from './dynamicPageContexts'

const DynamicPage = ({
  linkedPageIdOverride,
  linkedPagePathOverride,
  dynamicPageHomePath,
}) => {
  const {
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    dynamicPagePath,
    handleUpdateLinkedPageitem,
  } = useDynamicPageLoadingHelpers({ linkedPageIdOverride })

  if (isLoading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  dynamicPageHomePath =
    dynamicPageHomePath || `${dynamicPagePath}/${linkedPageId}`

  const routes = createRoutesFromDynamicPageTypeDef({
    treeSlug,
    linkedPageId,
    dynamicPageTypeDef: fetchedLinkedPage?.dynamicPageTypeDef,
    linkedPagePathOverride,
  })

  return (
    <DynamicPageContext.Provider
      value={{
        dynamicPageId: fetchedLinkedPage?.dynamicPageTypeDef?.id,
        dynamicPageTypeDef: fetchedLinkedPage?.dynamicPageTypeDef,
        dynamicPageHomePath,
        linkedPageId,
      }}
    >
      <LinkedPageDisplay
        handleUpdateLinkedPageitem={handleUpdateLinkedPageitem}
        fetchedLinkedPage={fetchedLinkedPage}
        routes={routes}
        linkedPageId={linkedPageId}
        pageType={INSTANCE_TYPE_EVENT}
      />
    </DynamicPageContext.Provider>
  )
}

export default DynamicPage
