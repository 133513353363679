import { createContext } from 'react'

/*
 *
 * Context for the dynamic page you are looking at in the logged in app.  Trying to avoid prop drilling thru linked pages etc
 *
 * used to store the dynamicPageType meta data
 * .
 * */
export const DynamicPageContext = createContext()

/*
 *
 * Context for the dynamic page tab you are looking at in the logged in app.  Trying to avoid prop drilling thru linked pages etc
 *
 * used to store the dynamicPageType tab meta data
 * .
 * */
export const DynamicPageTabContext = createContext()
