import React, { useCallback, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { usePublicPageUrlSegmentsWithTab } from './hooks'
import {
  fetchPublicPageArtefacts,
  fetchPublicPageEvents,
  fetchPublicPageInstances,
  fetchPublicPageLocations,
  selectPublicPageArtefacts,
  selectPublicPageEvents,
  selectPublicPageInstances,
  selectPublicPageLocations,
  setPublicViewConfig,
} from './pageSlice'

import { useBuildMultipleMapConfiguration } from 'src/modules/map/mapHooks'
import { PublicContext } from '../contexts'
import BasePageList from 'src/modules/page/BasePageList'
import { generatePublicLinkForObject } from '../../app/links'
import PublicActionBar from './PublicActionBar'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../../common/viewConfigUtils'
import { DynamicPageTabContext } from 'src/modules/dynamicPage/dynamicPageContexts'
import { useDynamicPageTabHelpers } from 'src/modules/dynamicPage/dynamicPageHooks'
import { useDynamicPageDefFinder } from 'src/modules/dynamicPage/dynamicPageHooks'

export const PublicPageListBase = ({ target, pageType }) => {
  const dispatch = useDispatch()
  const viewConfigQueryParams = useViewConfigQueryParams()
  const { treeSlug } = useContext(PublicContext)
  const dynamicPageDef = useDynamicPageDefFinder()

  const ACTION_MAP = {
    artefact: fetchPublicPageArtefacts,
    event: fetchPublicPageEvents,
    location: fetchPublicPageLocations,
  }
  const SELECTOR_MAP = {
    artefact: selectPublicPageArtefacts,
    event: selectPublicPageEvents,
    location: selectPublicPageLocations,
  }
  const action = ACTION_MAP[pageType] || fetchPublicPageInstances
  const dispatchFetchPublicPageInstances = useActionDispatcher(action)
  const selector = SELECTOR_MAP[pageType] || selectPublicPageInstances
  const publicPages = useSelector(selector)

  const { next, results } = publicPages

  const makeArgs = useCallback(
    page => ({
      page,
      pageType,
      target,
      treeSlug,
      viewConfigQueryParams,
      dynamicPageDefId: dynamicPageDef?.id,
    }),
    [target, pageType, treeSlug, viewConfigQueryParams, dynamicPageDef]
  )

  useEffect(() => {
    const fetchPublicPages = async () => {
      await dispatchFetchPublicPageInstances(makeArgs(0))
    }
    fetchPublicPages()
  }, [dispatchFetchPublicPageInstances, makeArgs, pageType, target])

  const handleFetchMore = () => {
    if (dispatchFetchPublicPageInstances.status === 'loading') {
      return
    }
    dispatchFetchPublicPageInstances(makeArgs(publicPages.page + 1))
  }

  const generateCardLink = useCallback(
    (pageType, id, dynamicPageTypeDefinition) =>
      generatePublicLinkForObject(
        treeSlug,
        pageType,
        id,
        dynamicPageTypeDefinition
      ),
    [treeSlug]
  )

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    publicPages,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setPublicViewConfig({ type: pageType, viewConfig }))
    dispatchFetchPublicPageInstances(makeArgs(0))
  }

  const { mapBlockComponent } = useBuildMultipleMapConfiguration(publicPages)

  return (
    <>
      <PublicActionBar
        type={pageType}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        handleSetViewConfig={handleSetViewConfig}
      />
      {mapBlockComponent}
      <BasePageList
        {...{
          dispatchFetchPage: dispatchFetchPublicPageInstances,
          generateCardLink,
          next,
          handleFetchMore,
          pages: publicPages,
          results,
          type: pageType,
        }}
      />
    </>
  )
}

const PublicPageList = ({ dynamicPageTypeTabIndex }) => {
  const { linkedPageId, tabType } = usePublicPageUrlSegmentsWithTab()
  const params = useDynamicPageTabHelpers({ dynamicPageTypeTabIndex })

  return (
    <DynamicPageTabContext.Provider value={params}>
      <PublicPageListBase {...{ target: linkedPageId, pageType: tabType }} />
    </DynamicPageTabContext.Provider>
  )
}

export default PublicPageList
