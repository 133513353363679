import React from 'react'

import PageContentList from './PageContentList'
import { useLinkedPageTargets } from './hooks'
import { INSTANCE_TYPE_DOCUMENT } from '../app/links'
import { DynamicPageTabContext } from 'src/modules/dynamicPage/dynamicPageContexts'
import { useDynamicPageTabHelpers } from 'src/modules/dynamicPage/dynamicPageHooks'

const Documents = ({ dynamicPageTypeTabIndex }) => {
  const { target, presetTargets } = useLinkedPageTargets()
  const params = useDynamicPageTabHelpers({ dynamicPageTypeTabIndex })

  return (
    <DynamicPageTabContext.Provider value={params}>
      <PageContentList
        presetTargets={presetTargets}
        target={target}
        state="PUBLISHED"
        type={INSTANCE_TYPE_DOCUMENT}
      />
    </DynamicPageTabContext.Provider>
  )
}

export default Documents
