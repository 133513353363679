import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'

import pickBy from 'lodash/pickBy'

const SLICE_NAME = 'app'

export const fetchNotifications = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchNotifications`,
  ({ page, treeSlug }) => {
    const treeSlugValue = treeSlug || getTreeSlugFromStore()
    const limit = PAGE_SIZE
    const offset = page * limit
    const queryStringParameters = pickBy({
      offset,
      limit,
    })
    return api.get(`/notifications/${treeSlugValue}/`, {
      queryStringParameters,
    })
  }
)

export const markNotificationsSeen = createWrappedAsyncThunk(
  `${SLICE_NAME}/markNotificationsSeen`,
  ({ setSeenTargets, setDismissedTargets, treeSlug }) => {
    const treeSlugValue = treeSlug || getTreeSlugFromStore()

    if (setSeenTargets || setDismissedTargets) {
      return api.patch(`/notifications/${treeSlugValue}/mark_seen/`, {
        body: {
          setSeenTargets: setSeenTargets,
          setDismissedTargets: setDismissedTargets,
        },
      })
    }
  }
)

export const fetchBookmarks = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchBookmarks`,
  ({ page }) => {
    const limit = PAGE_SIZE
    const offset = page * limit
    const queryStringParameters = pickBy({
      offset,
      limit,
    })
    return api.get(`/history/${getTreeSlugFromStore()}/bookmarks`, {
      queryStringParameters,
    })
  }
)

export const addBookmark = createWrappedAsyncThunk(
  `${SLICE_NAME}/addBookmark`,
  ({ type, targetId }) => {
    return api.post(`/history/${getTreeSlugFromStore()}/bookmarks/`, {
      body: { instanceType: type, target: targetId },
    })
  }
)

export const deleteBookmarks = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteBookmarks`,
  ({ bookmarkIdsToDelete }) => {
    return api.patch(
      `/history/${getTreeSlugFromStore()}/bookmarks/delete_bookmarks/`,
      {
        body: { bookmarkIdsToDelete },
      }
    )
  }
)

export const fetchPricingOptions = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPricingOptions`,
  () => {
    return api.get('/payments/featuresets/')
  }
)

export const fetchCheckout = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchCheckout`,
  ({ priceId }) => {
    return api.get(`/payments/checkout/?price=${priceId}`)
  }
)

export const fetchCustomerPortal = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchCustomerPortal`,
  ({ priceId }) => {
    return api.get(`/payments/customer-portal/?price=${priceId}`)
  }
)

export const fetchArticleTemplates = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchArticleTemplates`,
  () => {
    return api.get(`/history/content/article_templates/`)
  }
)

export const fetchAppConfig = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAppConfig`,
  () => {
    return api.get(`/config/app-config/`)
  }
)

const initialState = {
  treeVisible: true,
  notificationTimerId: null,
  pricingOptions: [],
  notifications: {
    page: 0,
    count: 0,
    results: [],
  },
  bookmarks: {
    page: 0,
    count: 0,
    results: [],
  },
  articleTemplates: {
    state: null,
    results: [],
    lastRecievedAtMs: null,
  },
  lastLocation: '/',
  sideBarVisible: false,
  appConfig: { dynamicPageTypes: [] },
}

const PAGE_SIZE = 5

export const appSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSideBarVisible: (state, { payload }) => {
      state.sideBarVisible = payload
    },
    setTreeVisible: (state, { payload }) => {
      state.treeVisible = payload
    },
    markBookmarkDeleted: (state, { payload }) => {
      state.bookmarks.results = state.bookmarks.results.map(bookmark =>
        bookmark.id === payload ? { ...bookmark, deleted: true } : bookmark
      )
    },
    setLastLocation: (state, { payload }) => {
      state.lastLocation = payload
    },
    notificationTimerStart: (state, { payload }) => {
      if (state.notificationTimerId) {
        //console.debug(
        //  `appSlice.notificationTimerStart(): state.notificationTimerId already set (${state.notificationTimerId}) so clearing existing timer...`
        //)

        // it seems calling a reducer function from here just does nothing (no error) so replicate its code...
        //notificationTimerStop(state)
        clearInterval(state.notificationTimerId)
        state.notificationTimerId = null
        state.notificationTimerPollSeconds = null
      }

      var notificationsTimerPollSeconds = 60
      if (payload.overridePollTimeoutSeconds) {
        notificationsTimerPollSeconds = payload.overridePollTimeoutSeconds
      }
      const timerId = setInterval(() => {
        //console.debug(
        //  `appSlice.notificationTimer: fired, calling payload.getThenHandleNotifications()...`,
        //  payload
        //)
        payload.getThenHandleNotifications()
      }, notificationsTimerPollSeconds * 1000)
      state.notificationTimerId = timerId
    },
    notificationTimerStop: state => {
      if (state.notificationTimerId) {
        clearInterval(state.notificationTimerId)
        //console.debug(
        //  `appSlice.notificationTimerStop(): clearing state.notificationTimerId`,
        //  current(state)
        //)
        state.notificationTimerId = null
      }
    },
  },
  extraReducers: {
    [fetchPricingOptions.fulfilled]: (state, { payload }) => {
      state.pricingOptions = payload
    },
    ...handlePaginatedAction(fetchNotifications, state => state.notifications),
    [markNotificationsSeen.fulfilled]: (state, { payload }) => {
      state.notifications.results = payload
    },
    ...handlePaginatedAction(fetchBookmarks, state => state.bookmarks),
    [deleteBookmarks.fulfilled]: (state, { payload }) => {
      state.bookmarks.results = payload
    },
    [fetchArticleTemplates.pending]: (state, { payload }) => {
      state.articleTemplates.state = 'loading'
      state.articleTemplates.results = payload
    },
    [fetchArticleTemplates.fulfilled]: (state, { payload }) => {
      state.articleTemplates.state = 'loaded'
      state.articleTemplates.results = payload
      state.articleTemplates.lastRecievedAtMs = Date.now()
    },
    [fetchArticleTemplates.rejected]: state => {
      state.articleTemplates.state = 'failed'
    },
    [fetchAppConfig.fulfilled]: (state, { payload }) => {
      state.appConfig = payload
    },
  },
})

export const {
  setSideBarVisible,
  setTreeVisible,
  markBookmarkDeleted,
  setLastLocation,
  notificationTimerStart,
  notificationTimerStop,
} = appSlice.actions

export const selectTreeVisible = state => state.app.treeVisible
export const selectNotifications = state => state.app.notifications
export const selectBookmarks = state => state.app.bookmarks
export const selectNotificationsUnreadCount = state =>
  state.app.notifications.results.filter(
    notification => notification.seen === false
  ).length
export const selectUnseenNotificationIds = state =>
  state.app.notifications.results
    .filter(notification => notification.seen === false)
    .map(notification => notification.id)
export const selectLastLocation = state => state.app.lastLocation
export const pricingOptions = state => state.app.pricingOptions
export const selectArticleTemplates = state => state.app.articleTemplates

export const selectSideBarVisible = state => state.app.sideBarVisible

export const selectDynamicPageTypeDefs = state =>
  state[SLICE_NAME].appConfig.dynamicPageTypes

export default appSlice.reducer
