/*
 */
import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk } from 'src/api'
import { setVisibility } from 'src/modules/visibility/visibilitySlice'

import {
  addFamilies,
  addStubNode,
  initiateNodeDirectory,
  isUnknownIndividual,
  updateIndividualPhotos,
  updateNodeDirectory,
  updatePhotos,
} from './api/nodeDirectory'
import { createDirectoryById } from './api/graphOps'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { fetchUser } from 'src/modules/auth/authSlice'
import { fetchGoalsShortly } from 'src/modules/goals/goalsSlice'
import pickBy from 'lodash/pickBy'

const initialState = {
  currentPageIndividual: undefined,
  currentPageFamily: undefined,
  families: [],
  nodeDirectory: {},
  nodeDirectoryLoaded: false,
  rawIndividuals: [],
  partialRawIndividualMap: {},
  partialRawIndividualPhotoMap: {},
  partialCallMap: {},
  stubNodes: {},
  nodeFullDirectoryLoaded: false,
  fetchIndividualsRunners: {},
}

const SLICE_NAME = 'viewer'

const PUBLIC_VISIBILTY = 'PUBLIC'

const getRequestStatus = (state, name, target) => {
  const status = state.fetchIndividualsRunners[`${name}-${target}`]

  if (status === undefined) {
    return false
  } else {
    return status
  }
}

/*
DEPRECATED FOR NOW USING THE PAGINATED VERSION
*/
export const fetchIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividuals`,
  async ({ treeSlug }) => {
    const result = await api.get(`/tree/${treeSlug}/allindividualsandphotos/`)
    return result
  }
)

export const fetchAllIndividualsPaginated = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAllIndividualsPaginated`,
  async ({ treeSlug }) => {
    const limit = 1000
    let offset = 0
    let individuals = []
    let hasMore = true
    const MAX_ITERATIONS = 10 // Safeguard against infinite loops
    const MAX_RECORDS = 6000 // Maximum number of records to fetch
    let iterations = 0

    // Initial request to get the total count
    const initialResponse = await api.get(
      `/tree/${treeSlug}/paginated_all_individuals_and_photos/`,
      {
        queryStringParameters: { limit: 1, offset: 0 },
      }
    )

    const totalCount = initialResponse.count

    if (totalCount > MAX_RECORDS) {
      console.warn(
        `DEBUG Total count (${totalCount}) exceeds maximum allowed records (${MAX_RECORDS}). Aborting fetch.`
      )
      return { complete: false, individuals: [] }
    }

    console.log(`DEBUG Total records to fetch: ${totalCount}`)

    while (
      hasMore &&
      iterations < MAX_ITERATIONS &&
      individuals.length < MAX_RECORDS
    ) {
      const queryStringParameters = pickBy({
        offset,
        limit,
        ordering: 'surname',
      })

      const response = await api.get(
        `/tree/${treeSlug}/paginated_all_individuals_and_photos/`,
        {
          queryStringParameters,
        }
      )

      individuals = individuals.concat(response.results)
      offset += limit
      hasMore = response.results.length === limit
      iterations++

      console.log(
        `DEBUG Fetched ${individuals.length} of ${totalCount} records. Iteration: ${iterations}`
      )
    }

    let complete = true

    if (iterations >= MAX_ITERATIONS) {
      console.warn(
        `DEBUG Reached maximum number of iterations (${MAX_ITERATIONS}). Stopping fetch.`
      )
      complete = false
    }

    if (individuals.length >= MAX_RECORDS) {
      console.warn(
        `DEBUG Reached maximum number of records (${MAX_RECORDS}). Stopping fetch.`
      )
      complete = false
    }

    console.log(`DEBUG Total records fetched: ${individuals.length}`)

    return { complete, individuals }
  }
)

export const createSubTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/createSubTree`,
  ({ title, nodes, caption }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/subtrees/`, {
      body: { title, nodes, caption },
    })
  }
)

export const fetchIndividualsForLineage = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsForLineage`,
  async (
    { treeSlug, target, forceLoad = false, familyId },
    { getState, dispatch }
  ) => {
    const state = getState()
    const callKey = target + '-' + familyId

    if (
      !target ||
      !treeSlug ||
      !familyId ||
      getRequestStatus(
        state.viewer,
        'fetchIndividualsForLineageRunning',
        callKey
      )
    ) {
      return { target: undefined }
    }

    if (state.viewer.partialCallMap[callKey]) {
      return { target: callKey }
    }

    if (
      (state.viewer.partialCallMap[callKey] && !forceLoad) ||
      state.viewer.nodeFullDirectoryLoaded
    ) {
      return { target: callKey }
    }

    dispatch(
      manageRequestStatus({
        name: 'fetchIndividualsForLineageRunning',
        running: true,
      })
    )
    const result = await api.get(
      `/tree/${treeSlug}/individualsforlineage/${familyId}/${target}`
    )
    dispatch(
      manageRequestStatus({
        name: 'fetchIndividualsForLineageRunning',
        running: false,
      })
    )

    return result
  }
)

export const fetchIndividualsForTarget = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsForTarget`,
  async (
    {
      treeSlug,
      target,
      forceLoad = false,
      immediateFamily = false,
      dynamicPageDefId,
    },
    { getState, dispatch }
  ) => {
    const state = getState()
    target = target || state.auth?.user?.homeIndividual?.id

    const queryStringParameters = pickBy({
      immediateFamily,
      dynamicPageDefId,
    })

    if (
      !target ||
      !treeSlug ||
      getRequestStatus(state.viewer, 'fetchIndividualsForTargetRunning', target)
    ) {
      return { target: undefined }
    }

    if (
      (state.viewer.partialCallMap[target] && !forceLoad) ||
      (state.viewer.nodeFullDirectoryLoaded && !forceLoad)
    ) {
      return { target: target }
    }

    dispatch(
      manageRequestStatus({
        name: 'fetchIndividualsForTargetRunning',
        running: true,
        target: target,
      })
    )

    const result = await api.get(
      `/tree/${treeSlug}/individualsfortarget/${target}`,
      { queryStringParameters }
    )

    dispatch(
      manageRequestStatus({
        name: 'fetchIndividualsForTargetRunning',
        running: false,
        target: target,
      })
    )

    return result
  }
)

export const PYRAMID = 'pyramid'
export const fetchIndividualsForPyramid = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsForPyramid`,
  async ({ treeSlug, target }, { getState, dispatch }) => {
    const state = getState()

    target = target || state.auth?.user?.homeIndividual?.id

    if (!target || !treeSlug) {
      return { target: undefined }
    }

    if (state.viewer.partialCallMap[PYRAMID] || !target) {
      return { target: PYRAMID }
    }

    const result = await api.get(
      `/tree/${treeSlug}/individualsforpyramid/${target}`
    )

    if (state.viewer.nodeFullDirectoryLoaded === false) {
      dispatch(fetchAllIndividualsPaginated({ treeSlug }))
      dispatch(fetchFamilies({ treeSlug }))
    }

    return result
  }
)

export const fetchIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividual`,
  ({ individualId, dynamicPageDefId }) => {
    return api.get(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/`,
      { queryStringParameters: { dynamicPageDefId } }
    )
  }
)

export const fetchFamily = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchFamily`,
  ({ familyId }) => {
    return api.get(`/history/${getTreeSlugFromStore()}/families/${familyId}`)
  }
)

export const fetchIndividualsPhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsPhotos`,
  () => api.get(`/tree/${getTreeSlugFromStore()}/individuals/photos/`)
)

export const fetchImageAccessCookie = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchImageAccessCookie`,
  ({ treeSlug }) =>
    api
      .get(`/tree/${treeSlug}/image-access-cookie/`)
      .then(api.get(`/tree/${treeSlug}/binary-access-cookie/`))
)

export const fetchFamilies = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchFamilies`,
  ({ treeSlug }) => api.get(`/history/${treeSlug}/families/`)
)

export const updateIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateIndividual`,
  ({ individualId, ...data }, { dispatch }) => {
    fetchGoalsShortly(dispatch)
    return api.patch(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/`,
      {
        body: data,
      }
    )
  }
)

export const deleteIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteIndividual`,
  ({ individualId, ...data }) => {
    return api.del(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/`
    )
  }
)

export const createIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/createIndividual`,
  ({ ...individualAttributes }) => {
    return api.post(`/tree/${getTreeSlugFromStore()}/individuals/`, {
      body: individualAttributes,
    })
  }
)

const addRelation = ({
  getState,
  action,
  individualId,
  individualAttributes,
  relationAttributeName = 'relationId',
  ...data
}) => {
  if (individualAttributes.id) {
    data[relationAttributeName] = individualAttributes.id
  } else {
    data.createIndividual = individualAttributes
  }
  return api.patch(
    `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/${action}/`,
    {
      body: data,
    }
  )
}

export const addFather = createWrappedAsyncThunk(
  `${SLICE_NAME}/addFather`,
  ({ individualId, individualAttributes }) => {
    return addRelation({
      action: 'add-father',
      individualId,
      individualAttributes,
    })
  }
)

export const addMother = createWrappedAsyncThunk(
  `${SLICE_NAME}/addMother`,
  ({ individualId, individualAttributes }) => {
    return addRelation({
      action: 'add-mother',
      individualId,
      individualAttributes,
    })
  }
)

export const addPartner = createWrappedAsyncThunk(
  `${SLICE_NAME}/addPartner`,
  ({
    individualId,
    individualAttributes,
    replacingUnknownSpouseId,
    unknownSpousesChildIds,
  }) => {
    return addRelation({
      action: 'add-partner',
      individualId,
      individualAttributes,
    })
  }
)
export const addChild = createWrappedAsyncThunk(
  `${SLICE_NAME}/addChild`,
  ({ individualId, otherParentId, individualAttributes }) => {
    //console.debug(`viewerSlice.addChild(): otherParentId: '${otherParentId}'`)
    const req = {
      action: 'add-child',
      individualId,
      individualAttributes,
      relationAttributeName: 'childId',
    }
    if (otherParentId && !isUnknownIndividual(otherParentId)) {
      req.otherParentId = otherParentId
    }
    return addRelation(req)
  }
)
export const addSelf = createWrappedAsyncThunk(
  `${SLICE_NAME}/addSelf`,
  async ({ individualAttributes }, { dispatch }) => {
    const response = await api.post(
      `/tree/${getTreeSlugFromStore()}/individuals/add-self/`,
      {
        body: individualAttributes,
      }
    )
    dispatch(fetchUser())
    return response
  }
)

export const addIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/addIndividual`,
  async ({ individualAttributes }, { dispatch }) => {
    const response = await api.post(
      `/tree/${getTreeSlugFromStore()}/individuals/add-individual/`,
      {
        body: individualAttributes,
      }
    )
    return response
  }
)

export const removeFather = createWrappedAsyncThunk(
  `${SLICE_NAME}/removeFather`,
  ({ individualId }) => {
    return api.patch(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/remove-father/`
    )
  }
)
export const removeMother = createWrappedAsyncThunk(
  `${SLICE_NAME}/removeMother`,
  ({ individualId }) => {
    return api.patch(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/remove-mother/`
    )
  }
)
export const removeParents = createWrappedAsyncThunk(
  `${SLICE_NAME}/removeParents`,
  ({ individualId }) => {
    return api.patch(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/remove-parents/`
    )
  }
)
export const removePartner = createWrappedAsyncThunk(
  `${SLICE_NAME}/removePartner`,
  ({ individualId, relationId }) => {
    return api.patch(
      `/tree/${getTreeSlugFromStore()}/individuals/${individualId}/remove-partner/`,
      {
        body: { relationId },
      }
    )
  }
)

export const viewerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetViewer: state => initialState,
    stubNodeDisplayed: (state, { payload }) => {
      addStubNode(state, payload)
    },
    updateNodeDirectoryFromContent: (state, { payload }) => {
      /* used to update the node directory from content e.g article */
      addFamilies(state, payload?.contentFamilies)
      updateNodeDirectory(state, payload?.contentIndividuals)
      updatePhotos(state, payload?.contentIndividualsPhotos)
    },
    manageRequestStatus: (state, { payload }) => {
      state.fetchIndividualsRunners[`${payload.name}-${payload.target}`] =
        payload.running
    },
  },
  extraReducers: {
    [fetchFamilies.fulfilled]: (state, { payload }) => {
      state.families = payload
    },
    [fetchIndividuals.pending]: (state, { payload }) => {
      state.nodeFullDirectoryLoaded = false
    },
    [fetchIndividuals.fulfilled]: (state, { payload }) => {
      updateNodeDirectory(state, payload)
      updatePhotos(state, payload?.photos)
      state.nodeFullDirectoryLoaded = true
    },
    [fetchAllIndividualsPaginated.fulfilled]: (state, { payload }) => {
      if (payload.complete) {
        updateNodeDirectory(state, payload.individuals)
        updatePhotos(state, payload.individuals?.photos)
        state.nodeFullDirectoryLoaded = true
      }
    },
    [fetchIndividualsForLineage.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      addFamilies(state, payload?.families)
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividualsForTarget.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividualsForPyramid.fulfilled]: (state, { payload }) => {
      addFamilies(state, payload?.families)
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      if (payload?.families?.length) {
        state.partialCallMap[payload?.target] = payload?.target
      }
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividualsPhotos.fulfilled]: (state, { payload }) => {
      updateIndividualPhotos(state, payload)
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividual.pending]: (state, { payload }) => {
      state.currentPageIndividual = undefined
    },
    [fetchIndividual.fulfilled]: (state, { payload }) => {
      state.currentPageIndividual = payload
      if (state.nodeDirectoryLoaded) {
        updateIndividuals(state, [payload])
      }
    },
    [fetchFamily.pending]: (state, { payload }) => {
      state.currentPageFamily = undefined
    },
    [fetchFamily.fulfilled]: (state, { payload }) => {
      state.currentPageFamily = payload
    },
    [updateIndividual.fulfilled]: (state, { payload, meta }) => {
      updateIndividuals(state, payload)
    },
    [createIndividual.fulfilled]: (state, { payload }) => {
      const newRawIndividuals = state.rawIndividuals.concat([payload])
      initiateNodeDirectory(state, newRawIndividuals)
    },
    [addSelf.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, [payload])
    },
    [addIndividual.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, [payload])
    },
    [addFather.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [addMother.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [addPartner.fulfilled]: (state, { payload, meta }) => {
      // console.debug(
      //   `viewerSlice.js addPartner.fulfilled(): called with payload`,
      //   payload
      // )
      const { individualId, replacingUnknownSpouseId, unknownSpousesChildIds } =
        meta.arg

      // console.debug(
      //   `viewerSlice.js addPartner.fulfilled(): called with individualId: ${individualId}, replacingUnknownSpouseId: ${replacingUnknownSpouseId}, payload:`,
      //   payload
      // )
      if (
        replacingUnknownSpouseId &&
        isUnknownIndividual(replacingUnknownSpouseId)
      ) {
        //if we're changing an 'unknown' auto-made-up spouse into a real individual, the unknown will no longer be
        //created. The children will be reassigned in response to the dispatchAddChild() call below, but that response will be
        //processed too late for the next render which will find the already-existing children still reference the unknown parent's
        //temporary id and get confused because the unknown parent will not have been created.

        // individualId is the existing (not unknown) spouse the unknown spouse was connected to and new spouse will be connected to

        //the response from dispatchAddPartner will include both partners, individualId is the existing partner,
        //find the other one which will be the new one
        const newIndividualExternalId = payload.filter(
          ind => ind.id !== individualId
        )[0].id

        // console.debug(
        //   `viewerSlice.js addPartner.fulfilled(): newIndividualExternalId '${newIndividualExternalId}'`
        // )

        // the children of unknownSpouse currently reference unknownSpouse as a parent in the nodeDirectory and have undefined
        // parent in rawIndividuals. updateIndividuals() will see the null parent and create a new unknown parent to fill in.
        // This will be tidied up in the response to the call to addChild, triggered next by AddIndividualDialog.handleAddIndividual()
        // but for now we need to manually set these references so that a new unknown parent is not created.
        for (const childId of unknownSpousesChildIds) {
          const childInNodeDirectory = state.nodeDirectory[childId]
          const childInRawIndividals = findRawIndividual(state, childId)

          const copyOfChildFromRawIndividals = { ...childInRawIndividals }
          let changedRawIndividual = false
          // console.debug(
          //   `viewerSlice.js addPartner.fulfilled(): got '${replacingUnknownSpouseId}'s child '${childId}' from nodeDirectory:`,
          //   current(childInNodeDirectory)
          // )
          if (childInNodeDirectory.bioFather === replacingUnknownSpouseId) {
            copyOfChildFromRawIndividals.bioFather = newIndividualExternalId
            changedRawIndividual = true
          }
          if (childInNodeDirectory.bioMother === replacingUnknownSpouseId) {
            copyOfChildFromRawIndividals.bioMother = newIndividualExternalId
            changedRawIndividual = true
          }

          if (changedRawIndividual) {
            payload = [...payload, copyOfChildFromRawIndividals]
          }

          // console.debug(
          //   `viewerSlice.js addPartner.fulfilled(): cleared parental references to '${replacingUnknownSpouseId}' in child in nodeDirectory '${childId}' - child.bioFather: '${childInNodeDirectory.bioFather}', child.bioMother: '${childInNodeDirectory.bioMother}', :`,
          //   current(childInNodeDirectory)
          // )
        }
      }

      updateIndividuals(state, payload, replacingUnknownSpouseId)
    },
    [addChild.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [removeFather.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [removeMother.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [removeParents.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [removePartner.fulfilled]: (state, { payload }) => {
      updateIndividuals(state, payload)
    },
    [setVisibility.fulfilled]: (state, { meta }) => {
      // If an individual has changed visibility, update
      const { instanceId: id, visibility } = meta.arg
      const individual = state.nodeDirectory[id]
      if (individual) {
        individual.visibility = visibility
        updateIndividuals(state, [individual])
      }

      // If a family has changed visibility, update
      const family = state.families.find(({ id: familyId }) => familyId === id)
      if (family) {
        family.visibility = visibility
      }
    },
  },
})

const updateFamilies = (state, updatedIndividuals) => {
  updatedIndividuals.forEach(individual => {
    const family = state.families.find(({ id }) => id === individual.family)
    if (!family) {
      //adding PUBLIC_VISIBILTY here as can't figure out a way to get at the auth slice here atm :(
      const newFamily = {
        id: individual.family,
        visibility: PUBLIC_VISIBILTY,
        surname: individual.surname,
        earliestBirthYear: individual.yearOfBirth,
      }
      state.families.push(newFamily)
    }
  })
}

const findRawIndividual = (state, individualId) => {
  return state.rawIndividuals.filter(ind => ind.id === individualId)[0]
}

const updateIndividuals = (state, updatedIndividuals) => {
  const updatedIndividualsById = createDirectoryById(updatedIndividuals)
  // Update existing
  const newRawIndividuals = state.rawIndividuals.map(individual => {
    if (updatedIndividualsById.has(individual.id)) {
      const newIndividual = updatedIndividualsById.get(individual.id)
      updatedIndividualsById.delete(individual.id)
      return newIndividual
    } else {
      return individual
    }
  })
  updateFamilies(state, updatedIndividuals)
  // Remaining must be new
  newRawIndividuals.push(...Array.from(updatedIndividualsById.values()))
  initiateNodeDirectory(state, newRawIndividuals)
  const photos = updatedIndividuals.map(i => {
    return { id: i.id, photo: i.photo }
  })
  updatePhotos(state, photos, true)
}

export const {
  resetViewer,
  stubNodeDisplayed,
  updateNodeDirectoryFromContent,
  manageRequestStatus,
} = viewerSlice.actions

export const selectFamilies = state => state[SLICE_NAME].families
export const selectNodes = state =>
  Object.values(state[SLICE_NAME].nodeDirectory)
export const selectLivingIndividuals = state =>
  state[SLICE_NAME].livingIndividuals
export const selectAllIndividuals = state => state[SLICE_NAME].allIndividuals
export const selectFamilyByFamilyId = searchID => state =>
  state[SLICE_NAME].families.find(({ id }) => id === searchID)
export const selectIndividualById = individualId => state =>
  state[SLICE_NAME].nodeDirectory[individualId]
export const selectIndividualsById = individualIDs => state =>
  Object.entries(state[SLICE_NAME].nodeDirectory)
    .filter(([id]) => individualIDs.includes(id))
    .map(([, individual]) => individual)
export const selectNodeDirectory = state => state[SLICE_NAME].nodeDirectory
export const selectIsNodeDirectoryIsLoaded = state =>
  state[SLICE_NAME].nodeDirectoryLoaded
export const selectIsFullNodeDirectoryIsLoaded = state =>
  state[SLICE_NAME].nodeFullDirectoryLoaded
export const selectIndividualForPage = state =>
  state[SLICE_NAME].currentPageIndividual
export const selectFamilyForPage = state => state[SLICE_NAME].currentPageFamily
export const selectPartialCall = partialCallKey => state =>
  state[SLICE_NAME].partialCallMap[partialCallKey] !== undefined
export const selectFamilyPyramidLoaded = state =>
  state[SLICE_NAME].partialCallMap[PYRAMID]

export default viewerSlice.reducer
